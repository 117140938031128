import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useAction } from "../../../../hooks/useAction";
import {
  addPx,
  analyticsEventTypes,
  parseCssStringToObject,
} from "../../../../helper";
import useTextLayout from "../../../../hooks/useTextLayout";
import { useConfetti } from "../../../../hooks/useConfetti";
import Loader from "../Loader";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import { useFormContext } from "react-hook-form";
import { useFormSumbmit } from "../../../../hooks/useFormSubmit";
import ImageBlock from "../../../ImageBlock/ImageBlock";
import { useAnalytics } from "../../../../hooks/useAnalytics";

ButtonBlock.propTypes = {
  section: PropTypes.object,
};

export default function ButtonBlock({
  popupData,
  setPopupState,
  popupState,
  section,
  handleClose,
  index,
  productIndex,
  productData,
}) {
  const { isMobile } = useApp();
  const { getCouponCode, addCart, checkout } = useFormSumbmit({
    popupData,
    setPopupState,
    index,
    productIndex,
    productData,
  });
  const {
    trigger,
    reset,
    formState: { errors },
  } = useFormContext();
  const { actionHandler } = useAction();
  const { addAnalyticsEvent } = useAnalytics();

  const [isLoading, setIsLoading] = useState(false);

  const disabled = false;

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);
  const activeStep = useMemo(() => popupState?.activeStep, [popupState]);

  const popupSettings = popupData?.schema[activeStep]?.popupSettings;
  const { type: actionType, parameters: actionParameters } =
    section?.setting?.action || {};

  const { type: btnType } = section.setting.btn_type || {};
  const btnIcon = section.setting?.btn_type?.icon?.image || null;
  const btnIconWidth =
    section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"]?.fontSize ||
    "16px";

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
    borderRadius: addPx(
      section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"]
        ?.borderRadius
    ),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    opacity: disabled ? 0.5 : 1,
    ...(!disabled && { cursor: "pointer" }),
  };

  const btnTextIconBlockStyle = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };

  const Text = useTextLayout(
    section?.text,
    section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    popupSettings
  );

  const confetti = useConfetti(popupData);

  if (!section?.text) return <></>;

  const handleOnClick = async (e) => {
    setIsLoading(true);

    if (disabled) return;

    switch (actionType) {
      case "go_to_step":
        const { openStep } = actionParameters;
        confetti.fire();
        setPopupState((prev) => ({
          ...prev,
          [`${popupType}_${popupId}`]: {
            ...prev[`${popupType}_${popupId}`],
            activeStep: openStep,
            openPopup: true,
          },
        }));
        break;
      case "open_link":
        const { link } = actionParameters;
        const { new_tab, close_after, is_conversation } =
          section?.setting?.action ?? {};

        if (link) {
          if (is_conversation) {
            await addAnalyticsEvent({
              eventType: analyticsEventTypes.INTERACTION,
              popupType: popupData?.popUpType,
              popupId: popupData?._id,
              ...(popupData?.campaign_Id && {
                campaignId: popupData?.campaign_Id,
              }),
            });
          }

          if (new_tab) {
            window.open(link, "_blank");
          } else {
            window.location.href = link;
          }
        }
        if (close_after) {
          handleClose(e);
        }
        break;
      case "form_submit":
        const { event } = actionParameters;
        const isValid = await trigger();
        if (isValid) {
          switch (event) {
            case "get_coupon_code":
              await getCouponCode();
              break;
            case "product_add_cart":
              await addCart();
              break;
            case "product_check_out":
              await checkout();
              break;
            default:
              break;
          }
          reset();
        }
        
        break;
      case "close_popup":
        handleClose(e);
        reset();
        break;
      default:
        break;
    }

    setIsLoading(false);
  };

  return (
    <>
      {btnType === "icon" ? (
        btnIcon && (
          <div
            style={{ width: "100%", height: "100%" }}
            onClick={(e) => actionHandler(() => handleOnClick(e))}
          >
            <ImageBlock src={{ file: btnIcon }} />
          </div>
        )
      ) : (
        <button
          style={blockCSS}
          onClick={(e) => actionHandler(() => handleOnClick(e))}
          disabled={disabled}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {btnType === "text" && Text}
              {btnType === "text_with_icon" && (
                <div style={btnTextIconBlockStyle}>
                  {section?.text}
                  {btnIcon && (
                    <img
                      src={btnIcon}
                      alt="btn Icon"
                      style={{
                        width: btnIconWidth,
                        height: btnIconWidth,
                      }}
                      draggable={false}
                    />
                  )}
                </div>
              )}
            </>
          )}
          <style>{`
          button:hover {
            opacity: 0.95;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          button:disabled {
            cursor: not-allowed;
          }
        `}</style>
        </button>
      )}
    </>
  );
}
