import React from "react";
// mui
import { Typography } from "@mui/material";

export default function TitleBlock({ section, product }) {
  const customCSS = section?.setting?.customCSS || {};

  return (
    <Typography
      textAlign="center"
      component="div"
      variant="h1"
      sx={
        customCSS
          ? { ...customCSS, margin: 0, textAlign: "start" }
          : { margin: 0, textAlign: "start" }
      }
    >
      {product?.title}
    </Typography>
  );
}
