import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material";
import ButtonBlock from "../Preview/ButtonBlock";
import { POPUP_SECTIONS, TEMPLATE_TYPE } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import TitleBlock from "../Preview/TitleBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import CoverImageBlock from "../WelcomeVerticalPopup/CoverImageBlock";
import UserProfile from "../../assets/user-profile.png";
import MicrosoftImg from "../../assets/microsoftImg.png";
import ParagraphBlock from "../Preview/ParagraphBlock";
import ProductTitlleBlock from "../Preview/ProductTitle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MicrosoftBtnIcon from "../../assets/microsoft-btn-icon.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { getBackgroundStyle } from "../../helper";

const CardContentCustom = styled(CardContent)(() => ({
  position: "sticky",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  padding: "0px 15px 0px 15px",
  paddingBottom: "0px !important",
}));

const TEMPLATE_IMAGES = {
  [TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP]: UserProfile,
  [TEMPLATE_TYPE.MICROSOFT_TEAMS]: MicrosoftImg,
  [TEMPLATE_TYPE.WHATSAPP_CHAT]: UserProfile,
};

const TEMPLATE_BTN_IMAGES = {
  [TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP]: CalendarMonthIcon,
  [TEMPLATE_TYPE.MICROSOFT_TEAMS]: MicrosoftBtnIcon,
  [TEMPLATE_TYPE.WHATSAPP_CHAT]: WhatsAppIcon,
};

const ChatBoxPopupCard = ({ popupData, activeStep, onSubmitChat }) => {
  const { isMobile, appState } = useApp();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    if (appState?.is_preview_mode) {
      setIsDetailsOpen(true);
    }
  }, [appState]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
      height: "100%",
    }),
  };

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0, // Ensure background is behind the content
  };

  const blockType = (type) =>
    popupData?.schema[activeStep]?.sections?.find(
      (item) => item.type === type
    );

  return (
    <Card
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={containerStyle}>
        {activeStep === 0 && (
          <>
            <div
              style={{
                width: "100%",
                height: "35%",
                backgroundColor:
                  popupData?.schema[activeStep]?.popupSettings?.customCSS
                    ?.headerBackgroundColor,
              }}
            >
              <CardContentCustom>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    height: "100%",
                  }}
                >
                  {POPUP_SECTIONS.COVER_IMAGE && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CoverImageBlock
                        section={blockType(POPUP_SECTIONS.COVER_IMAGE)}
                        altImage={TEMPLATE_IMAGES[popupData?.popUpType]}
                        style={{ width: "40px" }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                      paddingRight: "25px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <TitleBlock section={blockType(POPUP_SECTIONS.TITLE)} />
                    </div>
                    <div style={{ width: "100%" }}>
                      <SubTitleBlock
                        section={blockType(POPUP_SECTIONS.SUBTITLE)}
                      />
                    </div>
                  </div>
                </div>
              </CardContentCustom>
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <CardContentCustom>
                <div
                  style={{
                    color: "#a1a1a1",
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px 0px",
                  }}
                >
                  10.30
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginBottom: "25px",
                  }}
                >
                  {POPUP_SECTIONS.COVER_IMAGE && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CoverImageBlock
                        section={blockType(POPUP_SECTIONS.COVER_IMAGE)}
                        altImage={TEMPLATE_IMAGES[popupData?.popUpType]}
                        style={{ width: "40px" }}
                      />
                    </div>
                  )}
                  <ParagraphBlock
                    section={blockType(POPUP_SECTIONS.PARAGRAPH)}
                  />
                </div>
                <ProductTitlleBlock
                  section={blockType(POPUP_SECTIONS.PRODUCT_TITLE)}
                />
                <ButtonBlock
                  section={blockType(POPUP_SECTIONS.BUTTON)}
                  onClick={onSubmitChat}
                  Icon={TEMPLATE_BTN_IMAGES[popupData?.popUpType]}
                />
              </CardContentCustom>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default ChatBoxPopupCard;
