import React, { useState } from "react";
import { api } from "../utils/interceptor";
import { useApp } from "../context/AppState";

export const usePopup = () => {
  const { appState, dispatch } = useApp();

  const [isLoading, setLoading] = useState(false);

  const fetchPopupByStoreName = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/api/public/popup`, {
        storeName: appState?.shop?.shop
      });
      if (response?.data?.code === 200) {
        dispatch({
          type: "POPUP_INIT",
          payload: {
            popupValue: response?.data?.data
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchRecommendedProduct = async (productId, productHandle) => {
    const baseRoute = window?.Shopify?.shop || window.location.hostname;

    try {
      const result = await api.get(`/api/public/product-recommendation?product_id=${productId}&baseRoute=${baseRoute}&productHandle=${productHandle}`);
      return result?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }

  const fetchProductByHandleOrId = async (productHandle) => {
    const baseRoute = window?.Shopify?.shop || window.location.hostname;

    try {
      const result = await api.get(`/api/public/shopify-product?baseRoute=${baseRoute}&productHandle=${productHandle}`);
      return result?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    isLoading,
    fetchPopupByStoreName,
    fetchRecommendedProduct,
    fetchProductByHandleOrId
  };
};