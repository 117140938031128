import React, { memo } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  DEFAULT_BLOCK_CSS,
  POPUP_SECTIONS,
} from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import getSymbolFromCurrency from "currency-symbol-map";
import { parseCssStringToObject } from "../../../../helper";

const PriceBlock = ({ productData, section, index, productIndex }) => {
  const { isMobile, appState } = useApp();
  const { watch } = useFormContext();

  // Watch selected variant and quantity from React Hook Form
  const selectedVariantId = watch(
    `${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`
  );
  const selectedQuantity = watch(
    `${POPUP_SECTIONS.QUANTITY_PICKER}-${productIndex}`
  );

  // Find the selected variant from product data
  const selectedVariant = productData?.variants?.find(
    (variant) => variant.id === Number(selectedVariantId)
  );

  // Calculate price: variant price * quantity
  const price = (selectedVariant?.price || 0) * (selectedQuantity || 1);

  const currencySymbol = appState?.is_preview_mode
    ? getSymbolFromCurrency(appState?.shop?.currency)
    : getSymbolFromCurrency(window?.Shopify?.currency?.active);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  return (
    <div style={blockCSS} className="gettuwku">
      <span>
        {currencySymbol} {price.toFixed(2)}
      </span>
      {selectedVariant?.compare_at_price && (
        <span
          style={{
            fontWeight: "bold",
            textDecoration: "line-through",
            marginLeft: "10px",
          }}
        >
          {currencySymbol}
          {selectedVariant?.compare_at_price}
        </span>
      )}
    </div>
  );
};

PriceBlock.propTypes = {
  productData: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  section: PropTypes.object,
};

PriceBlock.defaultProps = {
  section: {},
};

export default memo(PriceBlock);
