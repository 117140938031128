import React from "react";

// PropTypes
import PropTypes from "prop-types";

// mui
import { Stack, Typography } from "@mui/material";
import { parseCssStringToObject } from "../../helper";

ParagraphBlock.propTypes = {
  section: PropTypes.object,
};

export default function ParagraphBlock({ section }) {
  const { customCSS } = section?.setting;
  const gradient = section?.setting?.overrideCss || '';

  const css = parseCssStringToObject(gradient);

  return (
    <Typography variant="body1" color="text.secondary" sx={{ ...customCSS, ...css }}>
      <Stack>
        {section?.text || ""}
      </Stack>
    </Typography>
  );
}
