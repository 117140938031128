import React, { useEffect, useMemo, useState } from "react";
import TitleBlock from "../Preview/TitleBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import ButtonBlock from "../Preview/ButtonBlock";
import ParagraphBlock from "../Preview/ParagraphBlock";
import { POPUP_SECTIONS } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import BundleDiscountProduct from "./BundleDiscountProducts";
import CoverImageBlock from "../WelcomeVerticalPopup/CoverImageBlock";
import PriceBlock from "../PriceBlock";

import BundleImg from "../../assets/bundle_discount.png";
import { getBackgroundStyle } from "../../helper";


const BundleDiscountCard = ({
  popupData,
  activeStep,
  onClick,
  handleSelectVariant,
  totalPrice,
  selectedVariant
}) => {
  const { isMobile } = useApp();

  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1,
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    gap: "10px"
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  };

  const blockType = (type) => popupData?.schema?.[activeStep]?.sections?.find(
    (item) => item.type === type
  );


  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: 'auto',
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>

        {POPUP_SECTIONS.COVER_IMAGE && !isMobile &&
          <CoverImageBlock section={blockType(POPUP_SECTIONS.COVER_IMAGE)} altImage={BundleImg} />
        }
        <div style={cardContent}>
          <div style={{ width: isMobile ? `100%` : `50%`, display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }} >

            <TitleBlock
              section={blockType(POPUP_SECTIONS.TITLE)}
            />

            <SubTitleBlock section={blockType(POPUP_SECTIONS.SUBTITLE)} />

            <PriceBlock
              productCosts={totalPrice}
              discountType={
                blockType(POPUP_SECTIONS.OFFER_PRODUCT)?.discount_type ===
                "percentage"
                  ? "Percentage"
                  : null
              }
              discountvalue={
                blockType(POPUP_SECTIONS.OFFER_PRODUCT)?.discount_value
              }
              isdiscount={
                blockType(POPUP_SECTIONS.OFFER_PRODUCT)?.discount_value > 0
              }
              freeProduct={totalPrice.freeProduct}
              priceStyle={{
                fontWeight: "700",
                fontSize: "14px",
              }}
              defaultDiscountStyle={{
                fontSize: "10px",
                color: "#5B5B5B",
              }}
            />

            <ParagraphBlock section={blockType(POPUP_SECTIONS.PARAGRAPH)} />
          </div>

          <BundleDiscountProduct
            section={blockType(POPUP_SECTIONS.OFFER_PRODUCT)}
            handleSelectVariant={handleSelectVariant}
            selectedVariant={selectedVariant}
          />

          <ButtonBlock section={blockType(POPUP_SECTIONS.BUTTON)} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default BundleDiscountCard;
