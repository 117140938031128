import React, { memo, useMemo } from "react";
import PopUpModel from "../../components/PopUpModel";
import { useConfetti } from "../../hooks/useConfetti";
import ChatBoxPopupCard from "../../components/ChatBoxPopup/chatBoxPopupCard";

const RenderChatBoxPopup = ({ popupData, popupState, setPopupState }) => {

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);

  const onSubmitChat = () => {
    setPopupState((prev) => {
      return {
        ...prev,
        [`${popupType}_${popupId}`]: { openPopup: false, activeStep: 1 },
      };
    });
    // confetti.fire();
  };

  return (
    <>
      {popupState.openPopup && (
        <PopUpModel
          open={popupState.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          halfOutsideBottom={false}
        >
          <ChatBoxPopupCard
            popupData={popupData}
            activeStep={popupState.activeStep}
            onSubmitChat={onSubmitChat}
            setPopupState={setPopupState}
          />
        </PopUpModel>
      )}
    </>
  );
};

export default memo(RenderChatBoxPopup);
