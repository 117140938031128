import React, { useState, useEffect, useRef } from 'react';

const ProductDescription = ({ popupData }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current) {
        setIsOverflowing(
          descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, [popupData]);

  const handleViewMore = () => {
    setIsExpanded((prev) => !prev);
    if (!isExpanded && descriptionRef.current) {
      descriptionRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div
        ref={descriptionRef}
        style={{
          maxHeight: isExpanded ? '200px' : '180px', // Adjust the expanded height as needed
          overflowY: isExpanded ? 'auto' : 'hidden',
          transition: 'max-height 0.3s ease',
        }}
        className="description"
        dangerouslySetInnerHTML={{ __html: popupData?.product?.body_html ?? "" }}
      ></div>
      {isOverflowing && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleViewMore();
          }}
          style={{ color: '#007bff', cursor: 'pointer', marginTop: '5px', display: 'block', fontSize: '12px' }}
        >
          {isExpanded ? 'View Less' : 'View More'}
        </a>
      )}
    </div>
  );
};

export default ProductDescription;
