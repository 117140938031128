import React, { memo, useState, useEffect, useMemo } from "react";
import { useConfetti } from "../../hooks/useConfetti";
import { analyticsEventTypes } from "../../helper";
import { useAnalytics } from "../../hooks/useAnalytics";
import MostSellOutPreviewCard from "../../components/SocialPostPopup/MostSellOutPreviewCard";
import PopUpModel, {
  getPopupStylesFromPopupSettings,
} from "../../components/PopUpModel";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppState";
import { Box } from "@mui/material";
import MostSellOutProductDetails from "../../components/MostSellOutPopup/MostSellOutProductDetails";

const RenderMostSellOutPopup = ({ popupData, popupState, setPopupState }) => {
  const navigate = useNavigate();
  const { isMobile } = useApp();
  const { addAnalyticsEvent } = useAnalytics();
    const popupType = useMemo(() => popupData?.popUpType, [popupData]);
    const popupId = useMemo(() => popupData?._id, [popupData]);
    
  const [mostSellOutProduct, setMostSellOutProduct] = useState();
  const socialPopupTimeOut = useMemo(() => {
    const socialPopupSection = popupData?.schema[
      popupState.activeStep
    ].sections?.find((section) => section.type === "TRIGGER");

    return (socialPopupSection?.value ?? 0) * 1000 || 5000;
  }, [popupData, popupState]);

  const [openMostSellout, setOpenMostSellout] = useState(false);
  const confetti = useConfetti(popupData);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenMostSellout(!openMostSellout);
      setMostSellOutProduct(
        popupData?.product
          ? popupData?.product
          : popupData?.schema[0]?.sections[1]?.test_orders[0]
      );
    }, socialPopupTimeOut);

    return () => clearTimeout(timeout);
  }, [socialPopupTimeOut, openMostSellout]);

  const handleClose = () => {
    setOpenMostSellout(false);
  };

  const handelOnclick = async () => {
    setOpenMostSellout(false);

    await addAnalyticsEvent({
      eventType: analyticsEventTypes.OPEN,
      popupType: popupData?.popUpType,
      popupId: popupData?._id,
      ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
    });

    if (
      popupData?.schema[popupState.activeStep]?.popupSettings?.dynamicalOptions
        ?.navigateOnProduct
    ) {
      const currentStepData =
        popupData?.schema?.[popupState.activeStep]?.sections;

      if (Array.isArray(currentStepData)) {
        const testOrdersData = currentStepData.find(
          (item) => item.type === "TEST_ORDERS"
        );
        if (testOrdersData?.test_orders) {
          const product = testOrdersData.test_orders.find(
            (order) => order.handle
          );

          if (product || popupData?.product) {
            await addAnalyticsEvent({
              eventType: analyticsEventTypes.INTERACTION,
              popupType: popupData?.popUpType,
              popupId: popupData?._id,
              ...(popupData?.campaign_Id && {
                campaignId: popupData?.campaign_Id,
              }),
            });
            navigate(
              `/products/${
                popupData?.product
                  ? popupData?.product?.handle
                  : product?.handle
              }`
            );
            return;
          }
        }
      }
    } else {
      confetti.fire();
      setPopupState((prev) => ({
        ...prev,
        [`${popupType}_${popupId}`]: { activeStep: 1, openPopup: true },
      }));
    }
  };

  useEffect(() => {
    if (mostSellOutProduct?.id)
      localStorage.setItem("hash", mostSellOutProduct?.id);
  }, [mostSellOutProduct]);

  return (
    <>
      {popupData && popupState?.openPopup && popupState?.activeStep === 1 && (
        <PopUpModel
          open={popupState?.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          iconChange={false}
          halfOutsideBottom={true}
        >
          <MostSellOutProductDetails
            popupValue={popupData}
            mostSellOutProduct={mostSellOutProduct}
            setMostSellOutPopupDetails={setPopupState}
            activeStep={popupState?.activeStep}
          />
        </PopUpModel>
      )}
      {openMostSellout && popupState?.activeStep === 0 ? (
        <Box
          sx={getPopupStylesFromPopupSettings(
            popupData.schema[popupState.activeStep]?.popupSettings,
            isMobile
          )}
        >
          <MostSellOutPreviewCard
            popupValues={popupData}
            activeStep={popupState?.activeStep}
            handleClose={handleClose}
            product={mostSellOutProduct}
            handelOnclick={handelOnclick}
          />
        </Box>
      ) : null}
    </>
  );
};

export default memo(RenderMostSellOutPopup);
