import React from "react";
import TitleBlock from "../Preview/TitleBlock";
import { POPUP_SECTIONS } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import MultiBoardProducts from "./MultiBoardProducts";
import { getBackgroundStyle } from "../../helper";

const MultiBoardCard = ({
  popupData,
  activeStep,
  popupState,
  setPopupState,
}) => {
  const { isMobile } = useApp()

  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "20px"
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  };


  const renderSections = popupData?.schema[activeStep]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;

        case POPUP_SECTIONS.OFFER_PRODUCT:
          return <MultiBoardProducts key={index} popupData={popupData} section={section} activeStep={activeStep} setPopupState={setPopupState} popupState={popupState} />;

        default:
          return null;
      }
    }
  );

  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: 'auto',
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        <div style={cardContent}>
          {renderSections}
        </div>
      </div>
    </div>
  );
};

export default MultiBoardCard;
