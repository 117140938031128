import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useApp } from "../../context/AppState";

// mui
export default function PriceBlock({
    savingLabel,
    productCosts,
    isdiscount,
    discountType,
    discountvalue,
    priceStyle = {}, // default price style
    discountStyle = {}, // default discount style
}) {

    const { isMobile } = useApp();

    const symbol =
        getSymbolFromCurrency(window?.Shopify?.currency?.active);

    const defaultPriceStyle = {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexWrap: "wrap",
        fontSize: isMobile ? "14px" : "18px", 
        color: "#da4f49", 
    };

    const defaultDiscountStyle = {
        border: "1px solid #da4f49",
        borderRadius: '3px',
        padding: '0px 10px',
        fontSize: '12px',
        marginTop: '2px',
    };

    return (
        <div style={{ ...defaultPriceStyle, ...priceStyle }}>
            {!productCosts?.freeProduct && (
                <div>
                    {symbol}
                    {isdiscount
                        ? productCosts?.subtotal
                        : productCosts?.withoutDiscountPrice}
                </div>
            )}
            {productCosts?.freeProduct && <div>Free</div>}
            {productCosts?.withoutDiscountPrice !== productCosts?.subtotal &&

                isdiscount && (
                    <div style={{ textDecoration: "line-through", color: "#777777" }}>
                        {symbol}
                        {productCosts?.withoutDiscountPrice}
                    </div>
                )}

            {productCosts?.freeProduct && (
                <div style={{ ...defaultDiscountStyle, ...discountStyle }}>
                    {discountType === "Percentage"
                        ? `SAVE ${discountvalue}%`
                        : `SAVE ${symbol} ${productCosts?.withoutDiscountPrice}`}
                </div>
            )}

            {productCosts?.withoutDiscountPrice !== productCosts?.subtotal &&
                savingLabel &&
                isdiscount &&
                !productCosts?.freeProduct && (
                    <div style={{ ...defaultDiscountStyle, ...discountStyle }}>
                        {discountType === "Percentage"
                            ? `SAVE ${discountvalue}%`
                            : `SAVE ${symbol} ${productCosts?.discountedPrice}`}
                    </div>
                )}
        </div>
    );
}
