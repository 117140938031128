import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseBtn from "./CloseBtn";
import aiImg from "../../assets/aiIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  analyticsEventTypes,
  getAnimationStyle,
  getAttentionAnimationStyle,
  getBoxShadowStyle,
  getOuterBorderStyle,
  getOverlayStyle,
  getPopupPositionStyles,
} from "../../helper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAction } from "../../hooks/useAction";
import { useApp } from "../../context/AppState";
import { defaultPopupStates } from "../../pages/popup/DefaultPopupStates";
import { POPUP_CLOSE_BEHAVIOR } from "../../constant/constant";

export const getPopupStylesFromPopupSettings = (
  popupSettings,
  isMobile,
  popupData
) => {
  const borderValue = popupSettings?.dynamicalOptions?.border || 0;
  const { animationDelay, animationSpeed, animationRepeat } =
    popupSettings?.dynamicalOptions || {};
  const { is_embedded } = popupData?.generalSettings?.condition?.embed || {};

  return {
    position: popupSettings?.dynamicalOptions?.position
      ? popupSettings?.dynamicalOptions?.position
      : "fixed",
    backgroundColor:
      popupSettings?.customCSS?.background ||
      popupSettings?.customCSS?.backgroundColor ||
      "white",
    borderRadius: popupSettings?.customCSS?.borderRadius
      ? isNaN(popupSettings?.customCSS?.borderRadius)
        ? popupSettings?.customCSS?.borderRadius
        : `${popupSettings?.customCSS?.borderRadius}px`
      : "0px",
    ...getOuterBorderStyle(popupSettings?.dynamicalOptions),
    ...getBoxShadowStyle(popupSettings?.dynamicalOptions),
    outline: "none",
    ...(!is_embedded && {
      animation: `${getAnimationStyle(
        popupSettings?.dynamicalOptions?.animation,
        popupSettings?.dynamicalOptions?.popupPosition
      )} 0.5s ease-out, ${getAttentionAnimationStyle(
        popupSettings?.dynamicalOptions?.attentionAnimation,
        popupSettings?.dynamicalOptions?.popupPosition
      )} ${animationSpeed}s ease-out ${animationDelay}s ${animationRepeat}`,
    }),
    maxWidth: isMobile
      ? window.innerWidth < popupSettings?.customCSS?.mobileWidth
        ? window.innerWidth
        : popupSettings?.customCSS?.mobileWidth ||
          popupSettings?.customCSS?.width
      : popupSettings?.customCSS?.width,
    maxHeight: isMobile
      ? window.innerHeight < popupSettings?.customCSS?.mobileHeight
        ? window.innerHeight
        : popupSettings?.customCSS?.mobileHeight ||
          popupSettings?.customCSS?.height
      : popupSettings?.customCSS?.height,
    width: `calc(100% - ${borderValue * 2}px)`,
    height: `calc(100% - ${borderValue * 2}px)`,
    padding: popupSettings?.dynamicalOptions?.padding,
    ...(!is_embedded &&
      getPopupPositionStyles(popupSettings?.dynamicalOptions?.popupPosition)),
  };
};

const PopUpModel = ({
  open,
  children,
  activeStep,
  setActiveState,
  setEmail,
  setText,
  setBirthday,
  iconChange,
  halfOutsideBottom,
  removeClose = false,
  popupData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addAnalyticsEvent } = useAnalytics();
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);
  const popupSettings = popupData?.schema[activeStep]?.popupSettings || {};
  const { animationDelay, animationSpeed, animationRepeat } =
    popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions || {};
  const { is_embedded } = popupData?.generalSettings?.condition?.embed || {};
  const { actionHandler } = useAction();
  const { isMobile } = useApp();
  const [animationKey, setAnimationKey] = useState(0);

  const shouldClosePopup = (type) => {
    return !!POPUP_CLOSE_BEHAVIOR.CLOSE[type];
  };

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [animationDelay, animationSpeed, animationRepeat]);

  const handleClose = useCallback(
    async (e, reason) => {
      e.preventDefault();

      if (reason === "backdropClick") {
        return;
      }
      // Close the modal and reset state

      const defaultState = defaultPopupStates[popupType];

      setActiveState((prev) => ({
        ...prev,
        [`${popupType}_${popupId}`]: {
          ...defaultState,
          openPopup: shouldClosePopup(popupType)
            ? false
            : defaultState.openPopup,
        },
      }));

      if (setEmail && typeof setEmail === "function") setEmail("");

      if (typeof setText === "function") {
        setText("");
      }
      if (setBirthday)
        setBirthday({
          day: null,
          month: null,
          year: null,
        });

      // Navigate back to the current pathname without the hash
      if (location?.hash !== "") {
        navigate(`${location?.pathname}`);
      }

      if (popupData?.popUpType && popupData?.popupId) {
        await addAnalyticsEvent({
          eventType: analyticsEventTypes.CLOSE,
          popupType: popupData?.popUpType,
          popupId: popupData?.popupId,
          ...(popupData?.campaignId && { campaignId: popupData?.campaignId }),
        });
      }
    },
    [setActiveState, setEmail, setText, location, navigate, popupType, popupId]
  );

  const rootStyles = {
    zIndex: 9999,
    position: "fixed",
    display: "flex",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    paddingTop: popupSettings?.dynamicalOptions?.sideTop ?? "20px",
    paddingBottom: popupSettings?.dynamicalOptions?.sideBottom ?? "20px",
    paddingLeft: popupSettings?.dynamicalOptions?.sideLeft ?? "20px",
    paddingRight: popupSettings?.dynamicalOptions?.sideRight ?? "20px",
  };

  const relativeStyles = {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "relative",
  };

  const iconBoxStyle = {
    position: "absolute",
    left: iconChange ? "3px" : "",
    right: halfOutsideBottom ? "-12px" : "3px",
    top: halfOutsideBottom ? "-12px" : "3px",
    cursor: "pointer",
    zIndex: 999,
  };

  const iconStyle = {
    color: popupSettings?.customCSS?.backgroundColor?.includes("#8080")
      ? "black"
      : "#808080",
    height: "35px",
    width: "30px",
    margin: "5px",
  };

  if (is_embedded) {
    return (
      <div
        style={{
          ...(popupSettings?.dynamicalOptions?.isHideBgOverlay
            ? {}
            : getOverlayStyle(popupSettings?.dynamicalOptions?.overlay_style)),
        }}
      >
        <div style={{ ...rootStyles, position: "relative" }}>
          <div style={{ ...relativeStyles }}>
            <Box
              sx={{
                ...getPopupStylesFromPopupSettings(
                  popupSettings,
                  isMobile,
                  popupData
                ),
                position: "relative",
                maxHeight: "fit-content",
              }}
            >
              {popupData?.generalSettings?.hasAiIcon && (
                <Box
                  style={{
                    position: "absolute",
                    zIndex: "99999",
                    top: "-12px",
                    left: "-15px",
                  }}
                >
                  <img
                    src={aiImg}
                    alt="ai-icon"
                    style={{
                      width: "38px",
                      height: "38px",
                    }}
                  />
                </Box>
              )}
              {children}
            </Box>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      key={animationKey}
      open={open}
      onClose={() => actionHandler(handleClose)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={null}
      sx={{
        ...(popupSettings?.dynamicalOptions?.isHideBgOverlay
          ? {}
          : getOverlayStyle(popupSettings?.dynamicalOptions?.overlay_style)),
      }}
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
    >
      <div style={rootStyles}>
        <div style={relativeStyles}>
          <Box
            sx={getPopupStylesFromPopupSettings(
              popupSettings,
              isMobile,
              popupData
            )}
          >
            {popupData?.generalSettings?.hasAiIcon && (
              <Box
                style={{
                  position: "absolute",
                  zIndex: "99999",
                  top: "-12px",
                  left: "-15px",
                }}
              >
                <img
                  src={aiImg}
                  alt="ai-icon"
                  style={{
                    width: "38px",
                    height: "38px",
                  }}
                />
              </Box>
            )}
            {!removeClose && (
              <Box sx={iconBoxStyle}>
                <Box onClick={(e) => actionHandler(() => handleClose(e))}>
                  {halfOutsideBottom ? (
                    <CloseBtn />
                  ) : (
                    <CloseIcon sx={iconStyle} />
                  )}
                </Box>
              </Box>
            )}
            {children}
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default PopUpModel;
