import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useAction } from "../../hooks/useAction";
import { useApp } from "../../context/AppState";

ParagraphTextFieldBlock.propTypes = {
  section: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isEmptyField: PropTypes.bool,
};

export default function ParagraphTextFieldBlock({
  section,
  onChange,
  isEmptyField,
  text,
}) {
  const { actionHandler } = useAction();
  const { isSmallMobile } = useApp();
  const customCSS = {
    ...section?.setting?.customCSS,
    backgroundColor: "transparent !important",
  };

  return (
    <TextField
      sx={{
        marginTop: "8px",
        borderRadius: "15px",
        backgroundColor: "transparent",
        "& .MuiOutlinedInput-root": {
          borderRadius: "15px",
        },
        "& .MuiInputBase-root": {
          fontSize: isSmallMobile ? '12px' : '16px',
        },
        // Default hover/focus styles without errors
        ...(!Boolean(isEmptyField) && {
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#252f39", // Hover border color
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#252f39", // Focus border color
            },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)", // Original border color
          },
        }),
        // Error state styles
        ...(Boolean(isEmptyField) && {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red", // Red border color if there's an error
          },
        }),
      }}
      InputLabelProps={{
        sx: {
          fontSize: isSmallMobile ? '10px' : '13px',
        },
      }}
      InputProps={{
        sx: {
          fontSize: isSmallMobile ? '10px' : '13px',
        },
      }}
      fullWidth
      id="outlined-multiline"
      name="paragraph"
      placeholder="Write your words..."
      value={text}
      multiline
      rows={4}
      style={customCSS}
      onChange={(e) => actionHandler(() => onChange(e))}
      required
      error={Boolean(isEmptyField)}
    //   helperText={isEmptyField ? "This field cannot be empty" : ""}
    />
  );
}
