import React, { memo } from "react";

// propTypes
import PropTypes from "prop-types";

import { parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";

const SectionBlock = ({ section }) => {

    const { isMobile } = useApp();
    
    const blockCSS = {
        ...DEFAULT_BLOCK_CSS,
        ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"] ,
        ...parseCssStringToObject(section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]),
    }

    return (
        <div style={blockCSS} />
    );
}

SectionBlock.propTypes = {
    section: PropTypes.object,
};


export default memo(SectionBlock);
