import React from "react";

// PropTypes
import PropTypes from "prop-types";
import styles from "../WelcomeVerticalPopup/style.module.css";


// mui
import { Button, CircularProgress } from "@mui/material";
import { parseCssStringToObject } from "../../helper";
import { useAction } from "../../hooks/useAction";

ButtonBlock.propTypes = {
  section: PropTypes.object,
};

export default function ButtonBlock({ section, onClick, Icon, disabled, isLoading }) {
  const customCSS = section?.setting?.customCSS || {};

  const { actionHandler } = useAction();
  const gradient = section?.setting?.overrideCss || '';
  const css = parseCssStringToObject(gradient);

  const combinedStyle = {
    ...customCSS,
    ...css,
    cursor: "pointer",
    '&:hover': {
      ...(gradient ? gradient : customCSS),
      opacity: 0.95,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      ...(isLoading && { padding: "10px" }),
    },
    ...(isLoading && { padding: "10px" }),
    color: `${customCSS?.color || "#fff"} !important`,
  };

  if(!section?.text) return <></>;
  
  return (
    <Button
      sx={combinedStyle}
      onClick={() => actionHandler(onClick, section)}
      fullWidth
      disabled={disabled}
    >
      {isLoading ? <CircularProgress color='inherit' size={20} /> : <>
        {Icon && (
          typeof Icon === 'string' ? ( 
            <img
              src={Icon}
              alt="Button Icon"
              style={{ width: '20px', height: '20px' }}
            />
          ) : (
            <Icon
              sx={{
                fontSize: '20px',
                color: customCSS?.color || "#fff",
              }}
            />
          )
        )}
        {section?.text || "subscribe"}
      </>
      }
    </Button>
  );
}