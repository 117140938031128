import React, { useState, useMemo, memo, useEffect } from "react";
import PropTypes from "prop-types";
import { parseCssStringToObject } from "../../../../helper";
import {
  DEFAULT_BLOCK_CSS,
  POPUP_SECTIONS,
} from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import { useFormContext } from "react-hook-form";

const ImageSliderBlock = ({ section, productIndex, productData }) => {
  const { isMobile } = useApp();
  const { watch } = useFormContext();

  const blockCSS = useMemo(
    () => ({
      ...DEFAULT_BLOCK_CSS,
      ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
      ...parseCssStringToObject(
        section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
      ),
    }),
    [isMobile, section]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);

  const selectedVariantId = watch(
    `${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`
  );

  useEffect(() => {
    const selectedVariant = productData?.variants?.find(
      (variant) => String(variant.id) === String(selectedVariantId)
    );

    const selectedVariantImageIndex = productData?.images?.findIndex(
      (image) => image?.id === selectedVariant?.image_id
    );

    setCurrentIndex(
      selectedVariantImageIndex >= 0 ? selectedVariantImageIndex : 0
    );
  }, [selectedVariantId, productData?.images, productData?.variants]);

  // Default styles for the main image slider
  const sliderStyle = {
    width: "100%", // Take full width of the parent container
    height: "100%", // Take full height of the parent container
    overflow: "hidden",
    position: "relative",
  };

  const sliderWrapperStyle = useMemo(
    () => ({
      width: "100%", // Each image takes full width of the slider
      height: "100%", // Ensuring the productData?.images stretch to fill the height of the container
      display: "flex",
      transform: `translateX(-${currentIndex * 100}%)`, // Adjust for horizontal swipe
      transition: "transform 0.5s ease-in-out",
    }),
    [currentIndex]
  );

  const slideStyle = {
    minWidth: "100%", // Each image takes full width of the slider
    height: "100%", // Ensuring the productData?.images stretch to fill the height of the container
  };
  const buttonBaseStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255, 255, 255, 0.7)", // Light background
    border: "none",
    borderRadius: "100%",
    width: "10%",
    height: "10%",
    margin: "2%",
    padding: "2%",
    cursor: "pointer",
  };

  const prevButtonStyle = {
    ...buttonBaseStyle,
    left: "0%",
  };

  const nextButtonStyle = {
    ...buttonBaseStyle,
    right: "0%",
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? productData?.images?.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === productData?.images?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX); // Start X for horizontal swipe
  };

  const handleTouchMove = (e) => {
    const touchX = e.touches[0].clientX;
    const difference = startX - touchX;

    // Trigger a gesture if swipe is significant (left or right)
    if (Math.abs(difference) > 50) {
      if (difference > 0) goToNext();
      else goToPrevious();
      setStartX(0); // Reset startX to prevent multiple triggers
    }
  };

  const handleTouchEnd = () => {
    setStartX(0); // Ensure touch tracking is reset
  };

  return (
    <div style={{ ...blockCSS }}>
      {/* Main horizontal slider */}
      <div
        style={sliderStyle}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div style={sliderWrapperStyle}>
          {productData?.images?.map((image, index) => (
            <div
              key={index}
              style={{
                ...slideStyle,
                backgroundImage: `url(${image?.src})`,
                backgroundSize: "contain", 
                backgroundPosition: "center", 
                backgroundRepeat: "no-repeat", 
              }}
            />
          ))}
        </div>

        {productData?.images?.length > 1 && (
          <>
            {/* Previous Button with SVG */}
            <button style={prevButtonStyle} onClick={goToPrevious}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z"
                  fill="#000000"
                />
              </svg>
            </button>

            {/* Next Button with SVG */}
            <button style={nextButtonStyle} onClick={goToNext}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                  fill="#000000"
                />
              </svg>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

ImageSliderBlock.propTypes = {
  productData: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
};

export default memo(ImageSliderBlock);
