import { useEffect, useState } from "react";

const useDeviceCondition = (popup) => {
  const [deviceStatus, setDeviceStatus] = useState({ isDesktop: false, isMobile: false });

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const { is_desktop, is_mobile } = popup?.generalSettings?.condition?.device || {
      is_desktop: true, is_mobile: true
    };

    setDeviceStatus({
      isDesktop: is_desktop && screenWidth > 768,
      isMobile: is_mobile && screenWidth <= 768,
    });
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [popup?.generalSettings?.condition?.device]);

  return {
    isDesktopActive: deviceStatus.isDesktop,
    isMobileActive: deviceStatus.isMobile,
  };
};

export default useDeviceCondition;
