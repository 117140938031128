import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAction } from "../../hooks/useAction";

export default function RatingDisplay({ section  }) {
  
  const emojis = ["😟", "🙁", "😐", "😊", "😍"];
  
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const {actionHandler} = useAction();
  const handleEmojiClick = (index) => {
    setSelectedEmoji(index);
  };

  return (
    <Typography
      sx={{
        display: "flex",
        gap: "15px",
        marginTop: "15px",
        marginBottom: "7px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px",
        // ...customCSS,
      }}
    >
      {emojis.map((emoji, index) => (
        <Stack
          key={index}
          sx={{
            cursor: "pointer",
            padding: "2px",
            borderRadius: "50%",
            border: selectedEmoji === index ? "2px solid #3E2A6F" : "none",
          }}
          onClick={() => actionHandler( () => handleEmojiClick(index))}
        >
          {emoji}
        </Stack>
      ))}
    </Typography>
  );
}
