import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider as Form } from "react-hook-form";
import { useApp } from "../../context/AppState";

export function FormProvider({
  children,
  onSubmit,
  methods,
  popupData,
  popupState,
}) {
  const { isMobile } = useApp();
  const activeStep = useMemo(() => popupState?.activeStep, [popupState]);

  const formRef = useRef(null);
  const [maxDimensions, setMaxDimensions] = useState({ width: 0, height: 0 });

  const embeddingData = useMemo(
    () => popupData?.generalSettings?.condition?.embed,
    [popupData]
  );

  const width =
    popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
      isMobile ? "mobile" : "desktop"
    ]?.width ?? 0;
  const height =
    popupData?.schema[activeStep]?.popupSettings?.customCSS?.[
      isMobile ? "mobile" : "desktop"
    ]?.height ?? 0;

  // console.log("debug height and width : ", {
  //   width,
  //   height,
  //   activeStep,
  // });

  useEffect(() => {
    if (embeddingData?.is_embedded && formRef.current) {
      if (height > 0 || width > 0) {
        return setMaxDimensions({
          width,
          height,
        });
      }
      // Get all child elements and compute their dimensions
      const getAllDescendantDimensions = (element) => {
        const descendants = element.querySelectorAll("*"); // Get all nested elements
        return Array.from(descendants).map((descendant) => {
          const { width, height } = descendant.getBoundingClientRect();
          return { width, height };
        });
      };

      // Get dimensions of all nested children
      const childDimensions = getAllDescendantDimensions(formRef.current);

      // Calculate maximum width and height
      let maxWidth = 0;
      let maxHeight = 0;

      if (childDimensions) {
        maxWidth = Math.max(...childDimensions?.map((dim) => dim.width));
        maxHeight = Math.max(...childDimensions?.map((dim) => dim.height));
      }

      // Set the maximum dimensions
      setMaxDimensions({
        width: maxWidth,
        height: maxHeight,
      });
    }
  }, [popupData, width, height, activeStep, isMobile, embeddingData, children]);

  return (
    <Form {...methods}>
      <form
        onSubmit={onSubmit}
        ref={formRef}
        style={{
          width: "100%",
          height: maxDimensions.height,
          position: "relative",
        }}
      >
        {children}
      </form>
    </Form>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  popupData: PropTypes.object,
};
