import React, { useMemo, useState } from "react";
import {
  analyticsEventTypes,
  parseCssStringToObject,
  pickRandomValueFromArray,
} from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import { useAction } from "../../../../hooks/useAction";
import { useFormContext } from "react-hook-form";
import { useFormSumbmit } from "../../../../hooks/useFormSubmit";
import { useAnalytics } from "../../../../hooks/useAnalytics";

const ImageBlock = ({
  section,
  setPopupState,
  handleClose,
  popupData,
  index,
  productIndex,
  productData,
}) => {
  const { getCouponCode, addCart, checkout } = useFormSumbmit({
    popupData,
    setPopupState,
    index,
    productIndex,
    productData,
  });
  const { isMobile } = useApp();
  const { actionHandler } = useAction();
  const {
    trigger,
    reset,
    formState: { errors },
  } = useFormContext();
  const { addAnalyticsEvent } = useAnalytics();

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);
  const fileURL = useMemo(() => {
    const fileSrc = pickRandomValueFromArray(section?.src?.images);

    if (fileSrc instanceof File) {
      return URL.createObjectURL(fileSrc);
    } else {
      return fileSrc;
    }
  }, [section]);
  const disabled = false;
  const {
    type: actionType,
    parameters: actionParameters,
    isAction,
  } = section?.setting?.action || {};
  const handleOnClick = async (e) => {
    if (disabled) return;

    switch (actionType) {
      case "go_to_step":
        const { openStep } = actionParameters;
        setPopupState((prev) => ({
          ...prev,
          [`${popupType}_${popupId}`]: {
            ...prev[`${popupType}_${popupId}`],
            activeStep: openStep,
            openPopup: true,
          },
        }));
        break;
      case "open_link":
        const { link } = actionParameters;
        const { new_tab, close_after, is_conversation } =
          section?.setting?.action ?? {};

        if (link) {
          if (is_conversation) {
            await addAnalyticsEvent({
              eventType: analyticsEventTypes.INTERACTION,
              popupType: popupData?.popUpType,
              popupId: popupData?._id,
              ...(popupData?.campaign_Id && {
                campaignId: popupData?.campaign_Id,
              }),
            });
          }

          if (new_tab) {
            window.open(link, "_blank");
          } else {
            window.location.href = link;
          }
        }
        if (close_after) {
          handleClose(e);
        }
        break;
      case "form_submit":
        const { event } = actionParameters;
        const isValid = await trigger();
        if (isValid) {
          switch (event) {
            case "get_coupon_code":
              await getCouponCode();
              break;
            case "product_add_cart":
              await addCart();
              break;
            case "product_check_out":
              await checkout();
              break;
            default:
              break;
          }
          reset();
        }
        break;
      case "close_popup":
        handleClose(e);
        reset();
        break;
      default:
        break;
    }
  };

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  return (
    <img
      onClick={
        isAction ? (e) => actionHandler(() => handleOnClick(e)) : undefined
      }
      src={fileURL}
      alt="Image"
      style={blockCSS}
      draggable={false}
      fetchPriority="high"
    />
  );
};

export default ImageBlock;
