import React, { useLayoutEffect, useState } from "react";

import TitleBlock from "../Preview/TitleBlock";
import TimerPopUp from "./Timer";
import ButtonBlock from "../Preview/ButtonBlock";
import { useApp } from "../../context/AppState";

import { POPUP_SECTIONS } from "../../constant/constant";
import { getBackgroundStyle } from "../../helper";

const backgroundStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 0,
};

const TimerPopUp1 = ({ popupValues, step, onClick }) => {
  const { isMobile } = useApp()
  const [endTime, setEndTime] = useState();
  useLayoutEffect(() => {
    setEndTime(popupValues?.generalSettings?.event_endtime);
  }, []);

  const renderSections = popupValues?.schema[step]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.TIMER:
          return (
            endTime && (
              <TimerPopUp section={section} date={endTime} key={index} />
            )
          );
        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock section={section} onClick={onClick} key={index} />
          );
        default:
          return null;
      }
    }
  );

  const cardContainer = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "center",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: 'column',
      height: "auto",
    }),
    position: "relative",
    zIndex: 1,
  };

  return (
    <>
      <div
        style={{
          ...popupValues?.schema?.[step]?.popupSettings?.customCSS,
          zIndex: 9999,
        }}
      >
        <div
          style={{
            ...getBackgroundStyle(popupValues?.schema[step]?.popupSettings?.customCSS),
            ...backgroundStyle,
          }}
        />

        <div style={cardContainer} >
          {renderSections}
        </div>
      </div>
    </>

  );
};

export default TimerPopUp1;
