import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAction } from "../../hooks/useAction";

const VariantSelectBlock = ({ section, selectedVariant, product, handleChange }) => {

    const { actionHandler } = useAction();

    useEffect(() => {
        if (!selectedVariant?.id) handleChange(product, product?.variants?.[0])
    }, [selectedVariant, product])

    if (product?.variants?.length <= 1) return <></>;

    return (
        <FormControl size='small' sx={{ width: "100%" }}>
            <InputLabel >{section?.text}</InputLabel>
            <Select
                value={selectedVariant}
                label={section?.text}
                onChange={(e) => actionHandler(() => handleChange(product, e.target.value))}
            >
                {product?.variants?.map((variant, index) => (
                    <MenuItem key={index} value={variant} >
                        {variant?.title?.split("/")?.[0] || variant?.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default VariantSelectBlock;
