import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";

const ErrorTooltip = ({ id, content, position }) => {

  console.log("debug ErrorTooltip : ", { id, content, position });
  const tooltipStyle = {
    backgroundColor: "#d41019",
    color: "#fff",
    padding: "8px 12px",
    borderRadius: "6px",
    fontSize: "0.875rem",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    whiteSpace: "nowrap",
    zIndex: 1000,
    position: "absolute",
  };


  return (
    <Tooltip
      anchorSelect={`#${id}`}
      style={tooltipStyle}
      content={content}
      place={position}
    />
  );
};


ErrorTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

ErrorTooltip.defaultProps = {
  position: "right",
};

export default ErrorTooltip;
