import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import {
  calculateEventTimeDifference,
  getAiBtnImgPosition,
  getBlock,
  parseCssStringToObject,
} from "../../helper";
import { POPUP_SECTIONS, TEMPLATE_TYPE } from "../../constant/constant";

import aiImg from "../../assets/aiIcon.png";
import { useApp } from "../../context/AppState";
import { getPopupStylesFromPopupSettings } from "../../components/PopUpModel";

const RenderButtonComponent = ({
  popupState,
  setPopupState,
  popupData,
  onClickHandler,
}) => {
  const { isMobile, appState } = useApp();
  const [showButton, setShowButton] = useState(false);
  const buttonBlock = useMemo(
    () => getBlock(popupData, popupState?.activeStep, POPUP_SECTIONS.BUTTON),
    [popupData, popupState, POPUP_SECTIONS]
  );

  const rootButtonStyle = {
    ...buttonBlock?.setting?.customCSS,
    ...parseCssStringToObject(buttonBlock?.setting?.overrideCss),
    "&:hover": {
      backgroundColor: buttonBlock?.setting?.customCSS?.backgroundColor,
    },
    cursor: "pointer",
  };

  const btnStyle = {
    ...rootButtonStyle,
    ...popupData?.schema[popupState?.activeStep]?.popupSettings?.btnPositionCss,
  };

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);

  const popupId = useMemo(() => popupData?._id, [popupData]);

  const aiImgPosition = useMemo(
    () => getAiBtnImgPosition(popupData, popupState?.activeStep),
    [popupData, popupState]
  );

  const buttonAiImage = useMemo(() => {
    if (![TEMPLATE_TYPE.WELCOME_VERTICAL_POPUP].includes(popupType))
      return <></>;
    return (
      <div style={aiImgPosition}>
        <img
          src={aiImg}
          alt="ai-icon"
          style={{
            width: "30px",
            height: "30px",
          }}
        />
      </div>
    );
  }, [aiImgPosition, popupType]);

  const checkEventTime = useCallback(() => {
    const {
      generalSettings: { event_starttime, event_endtime },
    } = popupData;

    if (
      event_endtime &&
      event_starttime &&
      !calculateEventTimeDifference(event_starttime, event_endtime) &&
      !appState.is_preview_mode
    ) {
      setPopupState((prevState) => ({
        ...prevState,
        [`${popupType}_${popupId}`]: {
          ...prevState[`${popupType}_${popupId}`],
          openPopup: false,
        },
      }));
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [popupData, appState.is_preview_mode, popupType, popupId]);

  useEffect(() => {
    checkEventTime();
    const intervalId = setInterval(checkEventTime, 5000);
    return () => clearInterval(intervalId);
  }, []);

  if (!showButton) return null;

  switch (popupType) {
    case TEMPLATE_TYPE.WELCOME_POPUP:
    case TEMPLATE_TYPE.TEMP_WELCOME_VERTICAL_POPUP:
    case TEMPLATE_TYPE.WELCOME_VERTICAL_POPUP:
    case TEMPLATE_TYPE.FEEDBACK_REVIEW_POPUP:
    case TEMPLATE_TYPE.BUNDLE_DISCOUNT_POPUP:
    case TEMPLATE_TYPE.VOLUME_DISCOUNT_POPUP:
    case TEMPLATE_TYPE.MULTI_BOARD_POPUP:
    case TEMPLATE_TYPE.HALLOWEEN_DISCOUNT:
    case TEMPLATE_TYPE.HALLOWEEN_OFFER_POPUP:
    case TEMPLATE_TYPE.BLACK_FRIDAY_DISCOUNT:
    case TEMPLATE_TYPE.BLACK_FRIDAY_OFFER:
    case TEMPLATE_TYPE.CHRISTMAS_OFFER:
    case TEMPLATE_TYPE.YEAR_END_SALE:
    case TEMPLATE_TYPE.DIWALI_OFFER:
      return (
        <div
          style={{
            ...popupData?.schema[popupState?.activeStep]?.popupSettings
              ?.parentCss,
            zIndex: 99999
          }}
        >
          <Button sx={btnStyle} onClick={onClickHandler}>
            {buttonAiImage}
            {buttonBlock?.text}
          </Button>
        </div>
      );
    case TEMPLATE_TYPE.SPIN_WHEEL:
      return (
        <div
          style={{
            ...popupData?.schema[popupState?.activeStep]?.popupSettings
              ?.parentCss,
            zIndex: 99999
          }}
        >
          <div onClick={onClickHandler} style={btnStyle}>
            <img
              src="https://res.cloudinary.com/dvkvkpm8i/image/upload/v1716546708/Spinning-Wheel-Vector-PNG-Cutout_fzg3ur.png"
              alt="sd"
              style={{ width: "80px" }}
            />
          </div>
        </div>
      );
    case TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP:
    case TEMPLATE_TYPE.MICROSOFT_TEAMS:
    case TEMPLATE_TYPE.WHATSAPP_CHAT:
      return (
        <Box
          sx={{
            ...getPopupStylesFromPopupSettings(
              popupData.schema[popupState.activeStep]?.popupSettings,
              isMobile
            ),
            height: "auto",
            width: "auto",
            zIndex: 99999,
          }}
        >
          <Button sx={rootButtonStyle} onClick={onClickHandler}>
            {buttonBlock?.src?.image ? (
              <img
                src={buttonBlock?.src?.image}
                style={{ ...buttonBlock?.src?.imgCSS }}
              />
            ) : (
              <CalendarMonthIcon sx={{ color: "#fff" }} />
            )}
          </Button>
        </Box>
      );
    default:
      return null;
  }
};

export default RenderButtonComponent;
