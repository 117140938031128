import React, { memo, useState, useEffect, useMemo } from "react";
import { useConfetti } from "../../hooks/useConfetti";
import { pickRandomValueFromArray } from "../../helper";
import { useNavigate } from "react-router-dom";
import SocialProofPreviewCard from "../../components/SocialPostPopup/SocialProofPreviewCard";
import PopUpModel, {
  getPopupStylesFromPopupSettings,
} from "../../components/PopUpModel";
import { useApp } from "../../context/AppState";
import { Box } from "@mui/material";
import ProductDetails from "../../components/SocialPostPopup/ProductDetails";

const RenderSocialProofPopup = ({ popupData, popupState, setPopupState }) => {
  const navigate = useNavigate();
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);

  const socialPopupTimeOut = useMemo(() => {
    const socialPopupSection = popupData?.schema[
      popupState.activeStep
    ].sections?.find((section) => section.type === "TRIGGER");

    return (socialPopupSection?.value ?? 0) * 1000 || 5000;
  }, [popupData, popupState]);

  const [openSocial, setOpenSocial] = useState(false);
  const [radomTestProduct, setRandomTestProduct] = useState();
  const { isMobile } = useApp();
  const confetti = useConfetti(popupData);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSocial(!openSocial);
      const RandomProduct = pickRandomValueFromArray(
        popupData?.schema[0]?.sections[1]?.test_orders
      );
      setRandomTestProduct(RandomProduct);
    }, socialPopupTimeOut);

    return () => clearTimeout(timeout);
  }, [socialPopupTimeOut, openSocial]);

  const handleClose = () => {
    setOpenSocial(false);
  };

  const handelOnclick = () => {
    setOpenSocial(false);

    if (
      popupData?.schema[popupState.activeStep]?.popupSettings?.dynamicalOptions
        ?.navigateOnProduct
    ) {
      const currentStepData =
        popupData?.schema?.[popupState.activeStep]?.sections;

      if (Array.isArray(currentStepData)) {
        const testOrdersData = currentStepData.find(
          (item) => item.type === "TEST_ORDERS"
        );
        if (testOrdersData?.test_orders) {
          const product = testOrdersData.test_orders.find(
            (order) => order.handle
          );
          if (product) {
            navigate(`/products/${product?.handle}`);
            return;
          }
        }
      }
    } else {
      confetti.fire();
      setPopupState((prev) => ({
        ...prev,
        [`${popupType}_${popupId}`]: { activeStep: 1, openPopup: true },
      }));
    }
  };

  useEffect(() => {
    if (radomTestProduct?.id)
      localStorage.setItem("hash", radomTestProduct?.id);
  }, [radomTestProduct]);

  return (
    <>
      {popupData && popupState?.openPopup && popupState?.activeStep === 1 && (
        <PopUpModel
          open={popupState?.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          iconChange={true}
        >
          <ProductDetails
            popupData={popupData}
            activeStep={popupState?.activeStep}
            radomTestProduct={radomTestProduct}
          />
        </PopUpModel>
      )}
      {openSocial && popupState?.activeStep === 0 ? (
        <Box
          sx={getPopupStylesFromPopupSettings(
            popupData.schema[popupState.activeStep]?.popupSettings,
            isMobile
          )}
        >
          <SocialProofPreviewCard
            popupData={popupData}
            activeStep={popupState.activeStep}
            handleClose={handleClose}
            product={radomTestProduct}
            handelOnclick={handelOnclick}
          />
        </Box>
      ) : null}
    </>
  );
};

export default memo(RenderSocialProofPopup);
