import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PriceBlock from '../PriceBlock';

const getVariantPrices = (variant) => {
    const { price } = variant;

    const subtotal = variant?.price;

    return {
        subtotal: parseFloat(subtotal)?.toFixed(2) ?? 0,
        withoutDiscountPrice: parseFloat(price)?.toFixed(2) ?? 0, // Correct key to match new structure
        freeProduct: false, // You can customize this logic if necessary
    };
};


const BundleDiscountProduct = ({ section, handleSelectVariant }) => {
    const [selectedVariants, setSelectedVariants] = useState({});

    const handleVariantChange = (productId, variantId, productIndex) => {
        setSelectedVariants((prev) => ({
            ...prev,
            [productId]: variantId,
        }));
        handleSelectVariant(productId, variantId, productIndex);
    };

    return (
        <div style={{ maxHeight: "264px", fontFamily: "sans-serif", width: "100%", overflow: "auto" }}>
            {section.offerProduct.map((item, index) => {
                const productId = item?.product?.id;
                const selectedVariantId = selectedVariants[productId] || item?.product?.variants?.[0]?.id;
                const price = getVariantPrices(
                    item?.product?.variants?.find((variant) => variant.id === selectedVariantId) || item?.product?.variants?.[0]
                );

                return (
                    <div key={productId} style={{ position: 'relative', padding: '5px 8px', borderRadius: '7.63px', border: '0.61px #7D7D7D solid', display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', gap: '11px', margin: '4px 0', width: '100%', boxSizing: 'border-box' }}>
                        <div style={{ background: '#DADADA', borderRadius: '6.11px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img style={{ width: '62px', height: '68px' }} src={item?.product?.image?.src ?? item?.product?.images?.[0]?.src} alt="Product" />
                        </div>
                        <div style={{ display: 'inline-flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'inline-flex', alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', gap: '31px' }}>
                                    <div style={{ color: 'black', fontSize: '12.21px', fontWeight: '500' }}>{item?.product?.title}</div>
                                </div>
                                <div style={{ display: 'inline-flex', alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', gap: '67px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <PriceBlock
                                            productCosts={price}
                                            discountType={null}
                                            priceStyle={{
                                                fontWeight: '500',
                                                fontSize: "14px",
                                            }}
                                            defaultDiscountStyle={{
                                                textDecoration: "line-through",
                                                fontSize: "10px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ alignSelf: 'stretch', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '4px' }}>
                                    {item?.product?.variants?.length > 1 && (
                                        <FormControl size='small' sx={{ maxWidth: "150px", mt: 1, width: "100%" }}>
                                            <InputLabel id={`variant-select-label-${productId}`}>Variants</InputLabel>
                                            <Select
                                                labelId={`variant-select-label-${productId}`}
                                                id={`variant-select-${productId}`}
                                                value={String(selectedVariantId)}
                                                label="Select Variant"
                                                onChange={(e) => handleVariantChange(productId, e.target.value, index)}
                                                sx={{ fontSize: "12px" }}
                                            >
                                                {item?.product?.variants.map((variant, index) => {
                                                    return (
                                                        <MenuItem key={index} value={String(variant.id)} sx={{ padding: "1", fontSize: "12px" }}>
                                                            {variant?.title?.split("/")?.[0] || variant?.title}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>
                            </div>
                        </div>
                        {index !== section.offerProduct.length - 1 && (
                            <AddCircleIcon sx={{ position: "absolute", zIndex: '999', bottom: "-17px", right: "15px", fill: "black" }} />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default BundleDiscountProduct;
