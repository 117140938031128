import { TEMPLATE_TYPE } from "../../constant/constant";

export const defaultPopupStates = {
  [TEMPLATE_TYPE.WELCOME_POPUP]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.SPIN_WHEEL]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.UPSELL_CHECKOUT]: {
    activeStep: 0,
    openPopup: false,
    isAiRecommend: false,
    aiRecommendedProduct: null,
  },
  [TEMPLATE_TYPE.FLASH_CHECKOUT]: { activeStep: 0, openPopup: false },
  [TEMPLATE_TYPE.SOCIAL_PROOF]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.MOST_SELLOUT_POPUP]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.BUNDLE_DISCOUNT_POPUP]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.VOLUME_DISCOUNT_POPUP]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.MULTI_BOARD_POPUP]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.FEEDBACK_REVIEW_POPUP]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.MICROSOFT_TEAMS]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.WHATSAPP_CHAT]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.TWO_STEP_POPUP]: { activeStep: 1, openPopup: false },
  [TEMPLATE_TYPE.CUSTOM_POPUP]: { activeStep: 0, openPopup: false },

  // vertical discount popups
  [TEMPLATE_TYPE.WELCOME_VERTICAL_POPUP]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.TEMP_WELCOME_VERTICAL_POPUP]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.HALLOWEEN_DISCOUNT]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.BLACK_FRIDAY_DISCOUNT]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.HALLOWEEN_OFFER_POPUP]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.BLACK_FRIDAY_OFFER]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.CHRISTMAS_OFFER]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.YEAR_END_SALE]: { activeStep: 2, openPopup: false },
  [TEMPLATE_TYPE.DIWALI_OFFER]: { activeStep: 2, openPopup: false },

  // countdown popups
  [TEMPLATE_TYPE.COUNTDOWN_TIMER]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.HALLOWEEN_LIMITED_SALE]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.BLACK_FRIDAY_LIMITED_TIME]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.NEW_YEAR_LIMITED_TIME]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.CHRISTMAS_LIMITED_TIME]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.DIWALI_LIMITED_TIME]: { activeStep: 0, openPopup: true },
};

// on website live preview, we are using a steps based on activeStep Value. ( it's initial value)
export const defaultDraggablePopupStates = {
  [TEMPLATE_TYPE.WELCOME_POPUP]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.SPIN_WHEEL]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.UPSELL_CHECKOUT]: {
    activeStep: 0,
    openPopup: false,
    isAiRecommend: false,
    aiRecommendedProduct: null,
  },
  [TEMPLATE_TYPE.FLASH_CHECKOUT]: { activeStep: 0, openPopup: false },
  [TEMPLATE_TYPE.SOCIAL_PROOF]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.MOST_SELLOUT_POPUP]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.BUNDLE_DISCOUNT_POPUP]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.VOLUME_DISCOUNT_POPUP]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.MULTI_BOARD_POPUP]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.FEEDBACK_REVIEW_POPUP]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.MICROSOFT_TEAMS]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.WHATSAPP_CHAT]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.TWO_STEP_POPUP]: { activeStep: 1, openPopup: true },
  [TEMPLATE_TYPE.CUSTOM_POPUP]: { activeStep: 0, openPopup: false },

  // vertical discount popups
  [TEMPLATE_TYPE.WELCOME_VERTICAL_POPUP]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.TEMP_WELCOME_VERTICAL_POPUP]: {
    activeStep: 2,
    openPopup: true,
  },
  [TEMPLATE_TYPE.HALLOWEEN_DISCOUNT]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.BLACK_FRIDAY_DISCOUNT]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.HALLOWEEN_OFFER_POPUP]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.BLACK_FRIDAY_OFFER]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.CHRISTMAS_OFFER]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.YEAR_END_SALE]: { activeStep: 2, openPopup: true },
  [TEMPLATE_TYPE.DIWALI_OFFER]: { activeStep: 2, openPopup: true },

  // countdown popups
  [TEMPLATE_TYPE.COUNTDOWN_TIMER]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.HALLOWEEN_LIMITED_SALE]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.BLACK_FRIDAY_LIMITED_TIME]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.NEW_YEAR_LIMITED_TIME]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.CHRISTMAS_LIMITED_TIME]: { activeStep: 0, openPopup: true },
  [TEMPLATE_TYPE.DIWALI_LIMITED_TIME]: { activeStep: 0, openPopup: true },
};
