import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import PopUpModel from "../../components/PopUpModel";
import CountDownPreview from "../../components/CountDownTimerPopup/CountDownPreview";
import { isEmail } from "../../utils/validation";
import {
  analyticsEventTypes,
  calculateEventTimeDifference,
} from "../../helper";
import { useSubscribers } from "../../hooks/useSubscribers";
import { useApp } from "../../context/AppState";
import { useConfetti } from "../../hooks/useConfetti";
import { useAnalytics } from "../../hooks/useAnalytics";
import TimerPopUp1 from "../../components/CountDownTimerPopup/TimerPopUp1";
import useActionHandler from "../../hooks/useActionHandler";
import useInput from "../../hooks/useInput";

const RenderCountDownPopup = ({ popupData, popupState, setPopupState }) => {
  const [triggerCountDown, setTriggerCountDown] = useState(false);
  const { createSubscribers } = useSubscribers(popupData);
  const { appState } = useApp();
  const confetti = useConfetti(popupData);
  const { addAnalyticsEvent } = useAnalytics();

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);

  const { email, setEmail, emailError, setEmailError, handleOnEmailChange } =
    useInput();

  const { isEmailExist, setIsEmailExist } = useActionHandler(
    popupData,
    setPopupState,
    { email },
    popupState
  );

  const eventTiming = useMemo(() => {
    const {
      generalSettings: { event_starttime, event_endtime },
    } = popupData;

    return { event_starttime, event_endtime };
  }, [popupData]);

  const onGetOffer = useCallback(async () => {
    setPopupState((prev) => {
      return {
        ...prev,
        [`${popupType}_${popupId}`]: {
          ...prev[`${popupType}_${popupId}`],
          activeStep: 1,
        },
      };
    });
    await addAnalyticsEvent({
      eventType: analyticsEventTypes.OPEN,
      popupType: popupData?.popUpType,
      popupId: popupData?._id,
      ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
    });
    confetti.fire();
  }, [popupData, popupType, popupId]);

  const onGetCouponCode = useCallback(async () => {
    if (isEmail(email)) {
      const _r = await createSubscribers(email, appState?.shop?.shop);
      if (_r?.data?.email !== undefined) {
        await addAnalyticsEvent({
          eventType: analyticsEventTypes.INTERACTION,
          popupType: popupData?.popUpType,
          popupId: popupData?._id,
          ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
        });
        setIsEmailExist(false);
        localStorage.setItem(
          "CouponCode",
          JSON.stringify({
            email,
            coupon: popupData?.generalSettings?.coupon_code,
          })
        );
        setPopupState((prev) => {
          return {
            ...prev,
            [`${popupType}_${popupId}`]: {
              ...prev[`${popupType}_${popupId}`],
              activeStep: 2,
            },
          };
        });
        setEmailError(false);
        confetti.fire();
      } else {
        setIsEmailExist(true);
      }
    } else {
      setEmailError(true);
    }
  }, [email, popupData, isEmailExist, popupType, popupId]);

  const trigggerCountDownPopup = () => {
    const { event_starttime, event_endtime } = eventTiming;
    if (
      calculateEventTimeDifference(event_starttime, event_endtime) ||
      appState.is_preview_mode
    ) {
      !triggerCountDown && setTriggerCountDown(true);
    } else {
      setTriggerCountDown(false);
    }
  };

  const checkEventTime = useCallback(() => {
    trigggerCountDownPopup();
  }, [triggerCountDown, eventTiming, setTriggerCountDown]);

  useEffect(() => {
    trigggerCountDownPopup();
  }, []);

  const intervalId = setInterval(checkEventTime, 5000);
  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  return (
    <>
      {popupState?.openPopup
        ? (popupState?.activeStep === 2 || popupState?.activeStep === 1) && (
            <PopUpModel
              open={popupState?.openPopup}
              activeStep={popupState?.activeStep}
              setActiveState={setPopupState}
              popupData={popupData}
              setEmail={setEmail}
            >
              <CountDownPreview
                popupValues={popupData}
                activeStep={popupState?.activeStep}
                setActiveState={setPopupState}
                onGetOffer={onGetOffer}
                onGetCouponCode={onGetCouponCode}
                handleOnEmailChange={handleOnEmailChange}
                email={email}
                emailError={emailError}
                isEmailExist={isEmailExist}
              />
            </PopUpModel>
          )
        : null}
      {popupState.activeStep === 0 && triggerCountDown ? (
        <TimerPopUp1
          popupValues={popupData}
          step={popupState?.activeStep}
          onClick={onGetOffer}
        />
      ) : null}
    </>
  );
};

export default memo(RenderCountDownPopup);
