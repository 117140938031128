import React, { useMemo } from "react";


import ButtonBlock from "../Preview/ButtonBlock";
import InputBlock from "../Preview/InputBlock";
import { POPUP_SECTIONS } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import TitleBlock from "../Preview/TitleBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import RatingDisplay from "../Preview/RatingDisplay";
import ParagraphTextFieldBlock from "../Preview/TextFieldInputBlock";
import CoverImageBlock from "../WelcomeVerticalPopup/CoverImageBlock";
import SuccessMark from "../../assets/success-mark.png";
import { getBackgroundStyle } from "../../helper";

const FeedbackReviewCard = ({
  popupData,
  activeStep,
  onClick,
  handleOnEmailChange,
  handleOnTextChange,
  email,
  text,
  emailError,
  isEmailExist,
  isEmptyField,
  setPopupState,
}) => {
  const { isMobile } = useApp();
    const popupType = useMemo(() => popupData?.popUpType, [popupData]);
    const popupId = useMemo(() => popupData?._id, [popupData]);

  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "0px 20px"
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0
  };
  
  const blockType = (type) =>
    popupData?.schema[activeStep]?.sections?.find(
      (item) => item.type === type
    );

  const renderSections = popupData?.schema[activeStep]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;

        case POPUP_SECTIONS.COVER_IMAGE:
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CoverImageBlock section={section} key={index} altImage={SuccessMark} width={"auto"} />
            </div>
          );

        default:
          return null;
      }
    }
  );

  const handleSkip = () => {
    setPopupState((prev) => {
      return {
        ...prev,
        [`${popupType}_${popupId}`]: { openPopup: false, activeStep: 2 },
      };
    });
  }

  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: "auto"
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        <div style={cardContent} >
          {activeStep === 0 ? (
            <>
              <TitleBlock section={blockType(POPUP_SECTIONS.TITLE)} />

              <SubTitleBlock section={blockType(POPUP_SECTIONS.SUBTITLE)} />

              <RatingDisplay
                section={blockType(POPUP_SECTIONS.COVER_IMAGE)}
              />

              <ParagraphTextFieldBlock
                section={blockType(POPUP_SECTIONS.PARAGRAPH)}
                isEmptyField={isEmptyField}
                onChange={handleOnTextChange}
                text={text}
              />

              <InputBlock
                section={blockType(POPUP_SECTIONS.INPUT)}
                onChange={handleOnEmailChange}
                email={email}
                emailError={emailError}
                isEmailExist={isEmailExist}
                isEmptyField={isEmptyField}
              />
              <ButtonBlock
                section={blockType(POPUP_SECTIONS.BUTTON)}
                onClick={onClick}
                disabled={email === ""}
                emailError={emailError}
              />

              <ButtonBlock
                section={blockType(POPUP_SECTIONS.BUTTON1)}
                onClick={handleSkip}
              />
            </>
          ) : (
            <>
              {renderSections}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackReviewCard;
