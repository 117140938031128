import React, { useLayoutEffect, useState, useMemo } from "react";

//component
import TimerPopUp from "./Timer";
import TitleBlock from "../Preview/TitleBlock";
import InputBlock from "../Preview/InputBlock";
import ButtonBlock from "../Preview/ButtonBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";

//constant
import { POPUP_SECTIONS, TEMPLATE_TYPE } from "../../constant/constant";
import useCountdownTimer from "../../hooks/useCountdownTimer";
import { Box } from "@mui/material";

const TimerPopUp2 = ({
  popupValues,
  step,
  onClick,
  handleOnEmailChange,
  email,
  emailError,
  isEmailExist
}) => {


  const timeLeft = useCountdownTimer(popupValues?.generalSettings?.event_endtime);
  const [endTimeOff, setEndTimeOff] = useState(false);

  useLayoutEffect(() => {
    if (timeLeft && typeof timeLeft === "object" && Object.keys(timeLeft).length === 0) {
      setEndTimeOff(true);
    }
  }, [timeLeft]);

  const renderSections = useMemo(
    () => {
      return popupValues?.schema[step]?.sections?.map(
        (section, index) => {
          switch (section?.type) {
            case POPUP_SECTIONS.TITLE:
              return <TitleBlock section={section} key={index} />;
            case POPUP_SECTIONS.TIMER:
              return (
                popupValues?.generalSettings?.event_endtime && (
                  <TimerPopUp section={section} date={popupValues?.generalSettings?.event_endtime} key={index} />
                )
              );
            case POPUP_SECTIONS.SUBTITLE:
              return !endTimeOff && <SubTitleBlock section={section} key={index} />;
            case POPUP_SECTIONS.INSTRUCTION:
              return <SubTitleBlock section={section} key={index} />;
            case POPUP_SECTIONS.INPUT:
              return (
                !endTimeOff ? <InputBlock
                  section={section}
                  onChange={handleOnEmailChange}
                  email={email}
                  emailError={emailError}
                  key={index}
                  isEmailExist={isEmailExist}
                  color={
                    popupValues?.popUpType === TEMPLATE_TYPE.HALLOWEEN_LIMITED_SALE ? '#ffbc6b' :
                    popupValues?.popUpType === TEMPLATE_TYPE.NEW_YEAR_LIMITED_TIME ? '#fff' :
                    popupValues?.popUpType === TEMPLATE_TYPE.CHRISTMAS_LIMITED_TIME ? '#fff' :
                    popupValues?.popUpType === TEMPLATE_TYPE.DIWALI_LIMITED_TIME ? '#fff' :
                    ""
                   }
                /> : ""
              );
            case POPUP_SECTIONS.BUTTON:
              return (
                !endTimeOff ? <ButtonBlock
                  section={section}
                  onClick={onClick}
                  disabled={email === ""}
                  key={index}
                /> : ""
              );
            default:
              return null;
          }
        }
      );
    },
    [popupValues, step, endTimeOff, handleOnEmailChange, email, emailError, isEmailExist, onClick]
  )

  return (
    <Box
    sx={{
      display:"flex",
      flexDirection:"column",
      gap:"5px"
    }}
    >{renderSections}</Box>
  );
};

export default TimerPopUp2;
