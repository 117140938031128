import React, { useState, useMemo } from "react";
import { api } from "../utils/interceptor";
import { useApp } from '../context/AppState'
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getBrowser, getOS } from '../helper';
import { useAnalytics } from './useAnalytics';


export const useSubscribers = (popup) => {
  const [subscriber, setSubscriber] = useState({});
  const { appState } = useApp();
  const localUserId = localStorage.getItem("user-id");
  const pageViews = JSON.parse(localStorage.getItem("user-pageViews") || "[]");
  const { getLocationInfo } = useAnalytics();

  const location = useLocation();

  const currentPageId = useMemo(() => {
    const isPageViewExists = pageViews?.find(
      (pageView) => pageView?.pathname === location?.pathname
    );
    return isPageViewExists?.pageViewID || `pageView-${uuidv4()}`;
  }, [location, localStorage]);


  const createSubscribers = async (values, storeName, birthday, review , rating) => {
    try {
      if (typeof storeName !== "undefined") {
        const locationData = await getLocationInfo();
        const response = await api.post(
          `/api/public/subscribers/${appState?.shop?.shop}`,
          {
            email:values,
            review: review,
            rating: rating, 
            birthDate: birthday,
            location: locationData,
            popup: popup,
            pageViewId: currentPageId, 
            userId: localUserId, 
            pathname: location?.pathname
          }
        );
        if (response?.status) {
          setSubscriber(response?.data);
        }
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createSubscribers,
    subscriber,
  };
};
