import React, { useEffect, useMemo, useState } from "react";
import { useApp } from "../../context/AppState";
import ProductDetails from "../ProductDetail";
import PopUpModel from "../PopUpModel";
import useActionHandler from "../../hooks/useActionHandler";
import { getBlock } from "../../helper";
import { POPUP_SECTIONS } from "../../constant/constant";
import { usePopup } from "../../hooks/usePopup";

const UpsellProductDetails = ({ popupData, popupState, setPopupState }) => {
  const { appState } = useApp();
  const { fetchProductByHandleOrId } = usePopup();
  const [quantity, setQuantity] = useState(1);
  const [addingProduct, setAddingProduct] = useState(false);
  const [upSellData, setUpSellData] = useState(null);

  const upSellProduct = popupState?.aiRecommendedProduct ?? popupData?.product;

  const setInitialUpsellData = async (productHandle) => {
    const productData = await fetchProductByHandleOrId(productHandle);
    setUpSellData({
      product: productData,
      quantity: getBlock(
        popupData,
        popupState?.activeStep,
        POPUP_SECTIONS.QUANTITY
      )?.value,
    });
  };

  useEffect(() => {
    if (popupData && appState?.is_preview_mode) {
      setUpSellData({
        product: popupData?.product,
        quantity: getBlock(
          popupData,
          popupState?.activeStep,
          POPUP_SECTIONS.QUANTITY
        )?.value,
      });
    } else if (popupData && !appState?.is_preview_mode) {
      setInitialUpsellData(popupData?.product?.handle);
    }
  }, [popupData, appState?.is_preview_mode]);

  return (
    <>
      <PopUpModel
        open={popupState?.openPopup}
        activeStep={popupState?.activeStep}
        setActiveState={setPopupState}
        popupData={popupData}
        iconChange={false}
        halfOutsideBottom={true}
      >
        <ProductDetails
          popupData={popupData}
          setPopupState={setPopupState}
          activeStep={popupState?.activeStep}
          quantity={quantity}
          setQuantity={setQuantity}
          addingProduct={addingProduct}
          setAddingProduct={setAddingProduct}
          productData={upSellData}
          setPopupData={setUpSellData}
          product={upSellProduct}
          productImages={popupState?.aiRecommendedProduct ?? popupData?.product}
        />
      </PopUpModel>
    </>
  );
};

export default UpsellProductDetails;
