import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useAction } from "../../../../hooks/useAction";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { addPx, getRandomId, parseCssStringToObject } from "../../../../helper";
import { useApp } from "../../../../context/AppState";
import ErrorTooltip from "../ErrorToolTip";

InputBlock.propTypes = {
  section: PropTypes.object.isRequired,
};

export default function InputBlock({ section }) {
  const { control } = useFormContext();
  const { isMobile } = useApp();
  const { actionHandler } = useAction();
  const [tooltipPosition, setTooltipPosition] = useState("right");

  const randomId = useMemo(() => getRandomId(), [section]);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    padding: "10px",
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    borderRadius: addPx(
      section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"]
        ?.borderRadius
    ),
    ...parseCssStringToObject(
      section?.setting?.overrideCSS?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const handleTooltipPosition = () => {
    const inputElement = document.getElementById(randomId);
    if (inputElement) {
      const rect = inputElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (rect.right + 200 < viewportWidth) {
        setTooltipPosition("right");
      } else if (rect.left - 200 > 0) {
        setTooltipPosition("left");
      } else if (rect.top - 50 > 0) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  };

  return (
    <Controller
      name={section?.inputName ?? "text"}
      control={control}
      rules={{
        ...(section?.rules?.required && {
          required: `${section?.inputName ?? "text"} is required`,
        }),
        ...(section?.rules?.pattern?.value && {
          pattern: {
            value: new RegExp(section?.rules?.pattern?.value),
            message: section?.rules?.pattern?.message ?? "Invalid format",
          },
        }),
      }}
      render={({ field, fieldState }) => (
        <>
          {section?.inputType === "textarea" ? (
            <textarea
              id={randomId}
              placeholder={section?.placeholder}
              {...field}
              style={{
                ...blockCSS,
                ...(fieldState?.error && {
                  borderColor: "#d41019",
                }),
              }}
              onChange={(e) => {
                actionHandler(() => {
                  handleTooltipPosition();
                  field.onChange(e);
                });
              }}
              onFocus={handleTooltipPosition}
            />
          ) : (
            <input
              id={randomId}
              type={section?.inputType ?? "text"}
              placeholder={section?.placeholder ?? "Enter Your Text"}
              {...field}
              style={{
                ...blockCSS,
                ...(fieldState?.error && {
                  borderColor: "#d41019",
                }),
              }}
              onChange={(e) => {
                actionHandler(() => {
                  handleTooltipPosition();
                  field.onChange(e);
                });
              }}
              onFocus={handleTooltipPosition}
            />
          )}
          {/* {fieldState?.error && (
            <ErrorTooltip
              id={randomId}
              content={fieldState?.error?.message}
              position={tooltipPosition}
            />
          )} */}
        </>
      )}
    />
  );
}
