import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useAction } from "../../hooks/useAction";
import { useApp } from "../../context/AppState";

InputBlock.propTypes = {
  section: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.bool, // Assuming emailError is a string
  isEmailExist: PropTypes.bool,
};

export default function InputBlock({
  section,
  onChange,
  email,
  emailError,
  isEmailExist,
  isEmptyField,
  color,
}) {

  const { actionHandler } = useAction();
  const { isSmallMobile } = useApp();
  const customCSS = {
    ...section?.setting?.customCSS,
  };
  return (
    <TextField
    sx={{
        ...section?.setting,
        borderRadius: "8px",
        marginTop: "12px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
        "& .MuiInputBase-root": {
          fontSize: isSmallMobile ? '12px' : '16px',
          height: "40px",
        },
        ...(!Boolean(emailError || isEmailExist || isEmptyField) && {
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#252f39", // Hover border color
          },

          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#252f39", // Focus border color, same as hover
            },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)", // Original border color when not focused or hovered
          },
        }),
        ...(Boolean(emailError || isEmailExist || isEmptyField) && {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red", // Red border color if there's an error
          },
        }),
      }}
      InputLabelProps={{
        sx: {
          fontSize: isSmallMobile ? '10px' : '13px',
        },
      }}
      InputProps={{
        sx: {
          fontSize: isSmallMobile ? '10px' : '13px',
          color:color ? color : "",
        },
      }}
      fullWidth
      id="outlined-select-currency"
      type="email"
      name="email"
      placeholder="Email"
      value={email}
      style={customCSS}
      onChange={(e) => actionHandler(() => onChange(e))}
      required
      error={Boolean(emailError || isEmailExist)}
      helperText={
        isEmailExist
          ? "Email is already exists"
          : emailError
          ? "Enter valid email"
          : ""
      }
    />
  );
}
