import React, { useMemo } from "react";
import { isValidUrl, pickRandomValueFromArray } from "../../helper";

const CoverImageBlock = ({ section, altImage, style }) => {
  const customCSS = section?.setting?.customCSS || {};

  const fileURL = useMemo(() => {
    const fileSrc =
      section?.src?.images?.length >= 1
        ? pickRandomValueFromArray(section?.src?.images)
        : section?.src?.image;

    if (fileSrc instanceof File) {
      return URL.createObjectURL(fileSrc);
    } else {
      return fileSrc;
    }
  }, [section]);

  const inlineStyle = useMemo(
    () => ({
      ...customCSS,
      ...style,
      width: "100%",
      Height: "100%",
      opacity:
        section?.backgroundOpacity !== null ? section.backgroundOpacity : 1,
      ...(isValidUrl(fileURL)
        ? {
            backgroundColor: "unset",
            backgroundImage: `url(${fileURL})`,
          }
        : {
            backgroundImage: "unset",
            backgroundColor: fileURL,
          }),
      backgroundPosition: section?.objectPosition,
      backgroundSize: section?.objectFit,
      backgroundRepeat: "no-repeat",
    }),
    [customCSS, style, section, fileURL]
  );

  return <>{fileURL && <div style={inlineStyle} alt="Preview Image" />}</>;
};

export default CoverImageBlock;
