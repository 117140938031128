import React from "react";
import TitleBlock from "../Preview/TitleBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import ButtonBlock from "../Preview/ButtonBlock";
import CouponCodeBlock from "../Preview/CouponCode";

import { POPUP_SECTIONS } from "../../constant/constant";
import NoCouponCode from "../WelcomeVerticalPopup/NoCouponCode";
import { Box } from "@mui/material";

const TimerPopUp3 = ({ popupValues, step, email }) => {
  
  const renderSections = popupValues?.schema[step]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.INSTRUCTION:
          return <SubTitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.COUPON_CODE:
          if (!popupValues?.generalSettings?.discount_code || popupValues?.generalSettings?.discount_code === "undefined") {
            return (
                <NoCouponCode key="no-coupon" email={email} />
            )
          }else{
            return (
              <CouponCodeBlock
              section={section}
              generalSettings={popupValues?.generalSettings}
              key={index}
              />
            );
          }
        case POPUP_SECTIONS.COUPON_CODE:
          return <SubTitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.BUTTON:
          return <ButtonBlock section={section} key={index} />;
        default:
          return null;
      }
    }
  );
  
  return (
    <Box
    sx={{
      display:"flex",
      flexDirection:"column",
      gap:"20px"
    }}
    >
      {renderSections}
    </Box>
  );
};

export default TimerPopUp3;
