import React, { useEffect, useMemo, useState } from "react";
import { POPUP_CONFIG } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import { usePopup } from "../../hooks/usePopup";
import { useAnalytics } from "../../hooks/useAnalytics";
import { analyticsEventTypes } from "../../helper";
import { defaultDraggablePopupStates, defaultPopupStates } from "./DefaultPopupStates";
import RenderInitialPopups from "./RenderInitialPopups";
import useTriggerCondition from "../../hooks/useTriggerCondition";

function Popup() {
  const { appState } = useApp();
  const { fetchPopupByStoreName } = usePopup();
  const { addAnalyticsEvent } = useAnalytics();

  const [isTriggered, setIsTriggered] = useState(false);
  const [globalPopupStates, setGlobalPopupStates] = useState({});

  const activePopups = useMemo(
    () => appState?.popupValue?.filter((popup) => popup?.is_active === true),
    [appState?.popupValue]
  );

  useEffect(() => {
    if (activePopups?.length && !appState?.is_preview_mode) {
      const initialStates = activePopups.reduce((acc, popup) => {
        const { is_embedded, step } = popup?.generalSettings?.condition?.embed || {};
        const { isDraggable } = popup?.generalSettings || {};
        if (isDraggable) {
          acc[`${popup?.popUpType}_${popup?._id}`] = {
            ...defaultDraggablePopupStates?.[popup?.popUpType],
            activeStep: is_embedded ? step ?? 0 : defaultDraggablePopupStates?.[popup?.popUpType]?.activeStep ?? 0,
            openPopup: defaultDraggablePopupStates?.[popup?.popUpType]?.openPopup ?? true,
          }
        } else {
          acc[`${popup?.popUpType}_${popup?._id}`] = {
            ...defaultPopupStates?.[popup?.popUpType],
            activeStep: is_embedded ? step ?? 0 : defaultPopupStates?.[popup?.popUpType]?.activeStep ?? 0,
            openPopup: defaultPopupStates?.[popup?.popUpType]?.openPopup ?? true,
          }
        }
        return acc;
      }, {});
      setGlobalPopupStates(initialStates);
    }
  }, [activePopups, appState?.is_preview_mode]);

  const loadAllPopupModel = async (popupType) => {
    const popupData = activePopups.find(
      (popup) => popup.popUpType === popupType && popup.is_active
    );

    if (popupData) {
      setIsTriggered(true);

      const { is_embedded, step } = popupData?.generalSettings?.condition?.embed || {};

      setGlobalPopupStates((prevStates) => ({
        ...prevStates,
        [`${popupType}_${popupData._id}`]: {
          openPopup: true,
          activeStep: is_embedded ? step : popupData?.generalSettings?.condition?.trigger?.step ?? 0,
        },
      }));

      await addAnalyticsEvent({
        eventType: analyticsEventTypes.OPEN,
        popupType: popupData.popUpType,
        popupId: popupData._id,
        ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
      });
    }
  };

  const getPopup = async () => {
    await fetchPopupByStoreName();
  };

  useEffect(() => {
    if (!appState?.is_preview_mode) {
      getPopup();
    }
  }, [appState?.is_preview_mode]);

  useEffect(() => {
    const { is_preview_mode, activeStep, popupValue } = appState || {};

    if (is_preview_mode && popupValue?.[0]?.popUpType) {
      const popUpType = popupValue[0].popUpType;
      const popUpId = popupValue[0]._id;
      const { isDraggable } = popupValue[0]?.generalSettings || {};

      if (POPUP_CONFIG[popUpType]) {
        if (isDraggable) {

          setGlobalPopupStates((prev) => ({
            ...prev,
            [`${popUpType}_${popUpId}`]: { activeStep, openPopup: true },
          }));
        } else {
          const openPopup = POPUP_CONFIG[popUpType].includes(activeStep);

          setGlobalPopupStates((prev) => ({
            ...prev,
            [`${popUpType}_${popUpId}`]: { activeStep, openPopup },
          }));
        }
      }
    }
  }, [appState]);



  const popupsData = useMemo(() => {
    if (!Object.keys(globalPopupStates)?.length) return [];

    return activePopups?.map((popup) => {
      const popupId = popup?._id;
      const popupType = popup?.popUpType;
      const popupState = globalPopupStates[`${popupType}_${popupId}`];
      const { isDraggable } = popup?.generalSettings || {};
      const { is_embedded, step } = popup?.generalSettings?.condition?.embed || {};

      return {
        popupData: popup,
        openPopup: popupState?.openPopup || false,
        loadPopup: () => {
            loadAllPopupModel(popupType);
        },
        closePopup: () => {
          if (popupState?.openPopup) {
            if (isDraggable) {
              setGlobalPopupStates((prev) => ({
                ...prev,
                [`${popupType}_${popupId}`]: {
                  ...defaultDraggablePopupStates?.[popupType],
                  activeStep: is_embedded ? step ?? 0 : defaultDraggablePopupStates?.[popupType]?.activeStep ?? 0,
                  openPopup: defaultDraggablePopupStates?.[popupType]?.openPopup ?? true,
                },
              }));

            } else {
              setGlobalPopupStates((prev) => ({
                ...prev,
                [`${popupType}_${popupId}`]: {
                  ...defaultPopupStates?.[popupType],
                  activeStep: is_embedded ? step ?? 0 : defaultPopupStates?.[popupType]?.activeStep ?? 0,
                  openPopup: defaultPopupStates?.[popupType]?.openPopup ?? true,
                },
              }));
            }
          }
        },
      };
    });
  }, [activePopups, globalPopupStates]);

  useTriggerCondition(popupsData, isTriggered, globalPopupStates);

  if (!Object.keys(globalPopupStates)?.length) return null;

  return (
    <>
      {activePopups.map((popupData, index) => {
        const popupId = popupData?._id;
        const popupType = popupData?.popUpType;
        const popupState = globalPopupStates[`${popupType}_${popupId}`];

        return (
          <RenderInitialPopups
            key={index}
            popupState={popupState}
            popupData={popupData}
            setPopupState={setGlobalPopupStates}
            loadAllPopupModel={loadAllPopupModel}
          />
        );
      })}
    </>
  );
}

export default Popup;
