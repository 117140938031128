import React, { useCallback, useState } from "react";
import TimerPopUp1 from "./TimerPopUp1";
import TimerPopUp2 from "./TimerPopUp2";
import TimerPopUp3 from "./TimerPopUp3";
import { useApp } from "../../context/AppState";
import { getBackgroundStyle } from "../../helper";

const CountDownPreview = ({
  popupValues,
  activeStep,
  setActiveState,
  onGetCouponCode,
  handleOnEmailChange,
  email,
  emailError,
  isEmailExist,
}) => {


  const { isMobile } = useApp();

  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1,
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "20px"
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0, 
  };

  const renderComponent = useCallback(
    (step) => {
      console.log("debug step: ", step);
      switch (step) {
        case 1:
          return (
            <TimerPopUp2
              popupValues={popupValues}
              step={activeStep}
              onClick={onGetCouponCode}
              handleOnEmailChange={handleOnEmailChange}
              email={email}
              emailError={emailError}
              isEmailExist={isEmailExist}
            />
          );
        case 2:
          return <TimerPopUp3 popupValues={popupValues} step={activeStep} email={email} />;
      }
    },
    [activeStep, popupValues, setActiveState, email, emailError, isEmailExist]
  );
  return (
    <div
      style={{
        ...popupValues?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: 'auto',
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupValues?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer} className="classContainer" >
        <div style={cardContent} className="classContant" >{renderComponent(activeStep)}</div>
      </div>
    </div>
  );
};

export default CountDownPreview;
