import React from "react";

// PropTypes
import PropTypes from "prop-types";

// mui
import { Stack, Typography } from "@mui/material";
import { parseCssStringToObject } from "../../helper";

const ProductTitlleBlock = ({section}) => {
  const { customCSS } = section?.setting;
  const gradient = section?.setting?.overrideCss || '';

  const css = parseCssStringToObject(gradient);

  return (
    <Typography variant="body1" color="text.secondary" sx={{ ...customCSS, ...css }} >
      <Stack>
        {section?.text || ""}
      </Stack>
    </Typography>
  );
}

export default ProductTitlleBlock
