import { useMemo } from "react";
import { TEMPLATE_TYPE } from "../../constant/constant";
import RenderFeedbackReviewPopup from "./RenderFeedbackReviewPopup";
import RenderVolumePopup from "./RenderVolumePopup";
import RenderWelcomePopUp from "./RenderWelcomePopUp";
import RenderWelcomeVerticalPopup from "./RenderWelcomeVerticalPopup";
import RenderBundleDiscount from "./RenderBundleDiscount";
import RenderChatBoxPopup from "./RenderChatBoxPopup";
import RenderCountDownPopup from "./RenderCountDownPopup";
import RenderSocialProofPopup from "./RenderSocialProofPopup";
import RenderWheelSpinPopUp from "./RenderWheelSpinPopUp";
import RenderMostSellOutPopup from "./RenderMostSellOutPopup";
import UpsellProductDetails from "../../components/NewUpsellPopup";
import RenderMultiBoardPopup from "./RenderMultiBoardPopup";

const RenderPopupComponent = ({ popupState, setPopupState, popupData }) => {
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);

  switch (popupType) {
    case TEMPLATE_TYPE.WELCOME_POPUP:
      return (
        <RenderWelcomePopUp
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.WELCOME_VERTICAL_POPUP:
    case TEMPLATE_TYPE.HALLOWEEN_DISCOUNT:
    case TEMPLATE_TYPE.HALLOWEEN_OFFER_POPUP:
    case TEMPLATE_TYPE.BLACK_FRIDAY_DISCOUNT:
    case TEMPLATE_TYPE.BLACK_FRIDAY_OFFER:
    case TEMPLATE_TYPE.CHRISTMAS_OFFER:
    case TEMPLATE_TYPE.YEAR_END_SALE:
    case TEMPLATE_TYPE.DIWALI_OFFER:
      return (
        <RenderWelcomeVerticalPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.FEEDBACK_REVIEW_POPUP:
      return (
        <RenderFeedbackReviewPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.BUNDLE_DISCOUNT_POPUP:
      return (
        <RenderBundleDiscount
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.VOLUME_DISCOUNT_POPUP:
      return (
        <RenderVolumePopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.MULTI_BOARD_POPUP:
      return (
        <RenderMultiBoardPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.BOOK_APPOINTMENT_POPUP:
    case TEMPLATE_TYPE.MICROSOFT_TEAMS:
    case TEMPLATE_TYPE.WHATSAPP_CHAT:
      return (
        <RenderChatBoxPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.COUNTDOWN_TIMER:
    case TEMPLATE_TYPE.HALLOWEEN_LIMITED_SALE:
    case TEMPLATE_TYPE.BLACK_FRIDAY_LIMITED_TIME:
    case TEMPLATE_TYPE.NEW_YEAR_LIMITED_TIME:
    case TEMPLATE_TYPE.CHRISTMAS_LIMITED_TIME:
    case TEMPLATE_TYPE.DIWALI_LIMITED_TIME:
      return (
        <RenderCountDownPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.SOCIAL_PROOF:
      return (
        <RenderSocialProofPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );
    case TEMPLATE_TYPE.SPIN_WHEEL:
      return (
        <RenderWheelSpinPopUp
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );

    case TEMPLATE_TYPE.MOST_SELLOUT_POPUP:
      return (
        <RenderMostSellOutPopup
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );

    case TEMPLATE_TYPE.UPSELL_CHECKOUT:
      return (
        <UpsellProductDetails
          popupData={popupData}
          popupState={popupState}
          setPopupState={setPopupState}
        />
      );

    default:
      return null;
  }
};

export default RenderPopupComponent;
