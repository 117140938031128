import React from 'react'
import styles from '../Preview/style.module.css';

const NoCouponCode = ({ email }) => {
  return (

    <div style={{
      display: 'flex',
      flexDirection: "column",
      gap: "6px",
      borderRadius: "5px",
      border: "1px solid var(--Gemini-Liner, #9168C0)",
      padding: "15px",
      marginTop: "15px"
    }}>
      <p style={{
        margin: "0",
        color: "var(--Primary-Primary, #5C439F)",
        fontSize: "16px",
        fontWeight: "600"
      }}>Thank you for your email .</p>
      <p style={{
        margin: "0",
        color: "#5E5E5E",
        fontSize: "16px",
        wordBreak: "break-all"
      }}>You will get an latest offer on your registered email on </p>
      <p style={{
        margin: "0",
        color: "#5E5E5E",
        fontSize: "16px",
        wordBreak: "break-all"
      }}><span className={styles.hightlight_email_bg}>{email} </span></p>
    </div>
  )
}

export default NoCouponCode