import { useEffect, useState } from 'react';
import { isValidSelector } from '../helper';
import { useApp } from '../context/AppState';

const useEmbedding = (embeddingData = {}) => {
    const [placeholder, setPlaceholder] = useState(null);
    const { appState } = useApp();

    useEffect(() => {
        const { is_embedded, css_selector, position } = embeddingData;

        if (!is_embedded) return;

        let targetSection = null;

        if (appState?.is_preview_mode) {
            targetSection = document.getElementById('preview-container');
        } else {
            if (!isValidSelector(css_selector)) return;
            targetSection = document.querySelector(css_selector);
        }

        if (targetSection) {
            const existingPlaceholder =
                position === 'beforebegin'
                    ? targetSection.previousElementSibling
                    : position === 'afterend'
                        ? targetSection.nextElementSibling
                        : targetSection.querySelector('[aim-data-placeholder="true"]');

            if (existingPlaceholder && existingPlaceholder.getAttribute('aim-data-placeholder') === 'true') {
                setPlaceholder(existingPlaceholder);
            } else {
                const newPlaceholder = document.createElement('div');
                newPlaceholder.setAttribute('aim-data-placeholder', 'true');
                newPlaceholder.style.width = '100%';
                newPlaceholder.style.height = 'max-content';
                newPlaceholder.style.display = 'block';

                switch (position) {
                    case 'beforebegin':
                        targetSection.insertAdjacentElement('beforebegin', newPlaceholder);
                        break;
                    case 'afterbegin':
                        targetSection.insertAdjacentElement('afterbegin', newPlaceholder);
                        break;
                    case 'beforeend':
                        targetSection.insertAdjacentElement('beforeend', newPlaceholder);
                        break;
                    case 'afterend':
                        targetSection.insertAdjacentElement('afterend', newPlaceholder);
                        break;
                    default:
                        targetSection.insertAdjacentElement('afterend', newPlaceholder);
                        break;
                }

                setPlaceholder(newPlaceholder);
            }
        }

    }, [embeddingData, appState?.is_preview_mode]);

    return {
        placeholder,
    };
};


export default useEmbedding;
