import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Popup from "./pages/popup/popup";
import { useApp } from "./context/AppState";
import "./App.css";
import { useAnalytics } from "./hooks/useAnalytics";

function App() {
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialAnalyticsLoading, setInitialAnalyticsLoading] = useState(true);
  const { getLocationInfo, addAnalytics } = useAnalytics();
  const { dispatch } = useApp();
  const location = useLocation();

  const initializeAnalytics = async () => {
    setInitialAnalyticsLoading(true);
    const localUserId = localStorage.getItem("user-id");
    const pageViews = JSON.parse(
      localStorage.getItem("user-pageViews") || "[]"
    );
    const isPageViewExists = pageViews?.find(
      (pageView) => pageView?.pathname === location?.pathname
    );

    if (!localUserId || !isPageViewExists) {
      const userId = localUserId || `user-${uuidv4()}`;
      const pageViewId = isPageViewExists?.pageViewID || `pageView-${uuidv4()}`;
      const device = {
        width: window.innerWidth,
        height: window.innerHeight
      };

      localStorage.setItem("user-id", userId);
      if (!isPageViewExists) {
        localStorage.setItem(
          "user-pageViews",
          JSON.stringify([
            ...pageViews,
            {
              device,
              pathname: location?.pathname,
              pageViewID: pageViewId,
              timeStamp: Date.now(),
            },
          ])
        );
      }

      const locationData = await getLocationInfo();

      await addAnalytics({
        device,
        location: locationData,
        userId,
        pageViewId,
        pathname: location.pathname,
      });
    }
    setInitialAnalyticsLoading(false);
  };

  const initializeStore = async () => {
    const shop = window?.Shopify?.shop || window.location.hostname;
    dispatch({
      type: "STORE_INIT",
      payload: {
        shop: { shop },
        is_preview_mode: false
      },
    });
    setInitialLoading(false);
  };

  const initializeStoreValue = async (newProps) => {
    const finalSchema = {
      popUpType: newProps?.popUpType,
      activeStep: newProps?.activeStep,
      shop: newProps?.shop,
      popupValue: newProps?.popupValue,
      is_preview_mode: newProps?.is_preview_mode
    }

    await dispatch({
      type: "STORE_INIT",
      payload: {
        shop: finalSchema.shop,
        is_preview_mode: finalSchema?.is_preview_mode
      },
    });

    if (finalSchema?.is_preview_mode) {
      dispatch({
        type: "POPUP_INIT",
        payload: finalSchema
      });
    }

    setInitialAnalyticsLoading(false);
    setInitialLoading(false);

  }

  // Define the function to update the props
  window.updatePropsInReactApp = function (newProps) {
    if (newProps) {
      initializeStoreValue(newProps)
    }
  };

  useEffect(() => {
    // IF SHOPIFY EMBDED THEME LOADED isLiveModeTrigger then the intialize store will be call
    console.log('%csrc/App.js:67 object', 'color: #007acc;', window?.isLiveModeTrigger);
    if (window?.isLiveModeTrigger) {
      initializeStore();
    }
  }, [window?.isLiveModeTrigger]);

  useEffect(() => {
    if (window?.isLiveModeTrigger && location) {
      initializeAnalytics();
    }
  }, [window?.isLiveModeTrigger, location])



  if (initialLoading || initialAnalyticsLoading) return <p>loading ...</p>;

  return (
    <div className="App">
      <Popup />
    </div>
  );
}

export default App;
