import React, { memo, useMemo } from "react";
import PopUpModel from "../../components/PopUpModel";
import useInput from "../../hooks/useInput";
import useActionHandler from "../../hooks/useActionHandler";
import WelcomePreviewCard from "../../components/WelcomePopup/WelcomePreviewCard";

const RenderWelcomePopUp = ({ popupData, popupState, setPopupState }) => {
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const {
    email,
    setEmail,
    birthday,
    setBirthday,
    emailError,
    setEmailError,
    handleOnEmailChange,
  } = useInput();

  const { onGetCouponCode, isEmptyField, isEmailExist } =
    useActionHandler(popupData, setPopupState, { birthday, email }, popupState);

  return (
    <>
      {popupState?.openPopup ? (
        <PopUpModel
          open={popupState?.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          setEmail={setEmail}
          setBirthday={setBirthday}
          popUpData={{
            popupType: popupData?.popUpType,
            popupId: popupData?._id,
            campaignId: popupData?.campaign_Id,
          }}
        >
          <WelcomePreviewCard
            popupData={popupData}
            activeStep={popupState?.activeStep}
            onClick={onGetCouponCode}
            handleOnEmailChange={handleOnEmailChange}
            email={email}
            emailError={emailError}
            setEmailError={setEmailError}
            isEmailExist={isEmailExist}
            birthday={birthday}
            isEmptyField={isEmptyField}
            setBirthday={setBirthday}
          />
        </PopUpModel>
      ) : null}
    </>
  );
};

export default memo(RenderWelcomePopUp);
