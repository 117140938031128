import React from "react";
import { useApp } from "../../context/AppState";
import SellOutVerificationCard from "./SellOutVerificationCard";
import './style.css';
import { POPUP_SECTIONS } from "../../constant/constant";
import { useAction } from "../../hooks/useAction";
import { getBackgroundStyle } from "../../helper";

const MostSellOutPreviewCard = ({ popupValues, activeStep, handleClose, product, handelOnclick }) => {
  const { isMobile } = useApp();
  const { actionHandler } = useAction()
  
  const cardContainer = {
    display: "flex",
    wordBreak: "break-word",
    flexDirection: "row",
    gap: "10px",
    width: '100%',
    height: '100%',
    alignItems: "center",
    ...(isMobile && {
      width: 'auto',
      overflowY: 'auto',
    }),
    position: "relative",
    zIndex: 1
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: "100%",
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0
  };


  const blockType = (type) =>
    popupValues?.schema[0]?.sections?.find(
      (item) => item.type === type
    );


  return (
    <div
      style={{
        ...popupValues?.schema[activeStep]?.popupSettings
          ?.customCSS,
          width: "100%",
        height: "100%",
        overflow: "auto",
      }}
      onClick={() => actionHandler(handelOnclick)}
    >
      
      <div
        style={{
          ...getBackgroundStyle(popupValues?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        <img
          src={product?.src || 'https://www.svgrepo.com/show/508699/landscape-placeholder.svg'}
          alt="preview Image"
          style={{ width: "40%", height: "100%", position: "sticky", top: 0 }}
        />
        <div style={cardContent}>
          <SellOutVerificationCard title={product?.title} section={blockType(POPUP_SECTIONS.STAR)} />
        </div>
      </div> 
    </div>
  );
};

export default MostSellOutPreviewCard;
