import React from "react";
import { POPUP_SECTIONS } from "../../constant/constant";
import SubTitleBlock from "../Preview/SubTitleBlock";
import TitleBlock from "../Preview/TitleBlock";
import CouponCodeBlock from "../Preview/CouponCode";
import NoCouponCode from "../WelcomeVerticalPopup/NoCouponCode";

const SecondComponent = ({ popupData, activeStep, email }) => {
  const renderSections = popupData?.schema[activeStep]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.SUBTITLE:
          return <SubTitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.CODE:
          if (!popupData?.generalSettings?.discount_code || popupData?.generalSettings?.discount_code === "undefined") {
            return (
              <NoCouponCode key="no-coupon" email={email} />
            )
          } else {
            return (
              <CouponCodeBlock
                section={section}
                generalSettings={popupData?.generalSettings}
                key={index}
                type={popupData?.popUpType}
              />
            );
          }
        default:
          return null;
      }
    }
  );

  return <div>{renderSections}</div>;
};

export default SecondComponent;
