import React, { useState } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAction } from '../../hooks/useAction';

const QuantityBox = ({quantity,handleIncrement,handleDecrement}) => {

  const { actionHandler } = useAction();

  return (
    <Box 
      sx={{
        display: 'flex', 
        alignItems: 'center', 
        border: '1px solid rgb(128 126 126)', 
        borderRadius: '4px',
        width: '100%',
        height:"28px",
        marginTop: '5px',
        justifyContent: 'center',
      }}
    >
      <IconButton 
        onClick={() => actionHandler(handleDecrement)} 
        size="small" 
        sx={{ borderRadius: 0 }}
      >
        <RemoveIcon sx={{color:'rgb(128 126 126)', fontSize: '16px'}}/>
      </IconButton>
      <TextField
        value={quantity}
        variant="outlined"
        size="small"
        inputProps={{ 
          style: { 
            textAlign: 'center',
            width: '40px',
            padding: '4px 8px',
            color:'rgb(128 126 126)'
          },
          readOnly: true,
        }}
        sx={{
          '& fieldset': {
            border: 'none',
            color:'#050505'
          },
        }}
      />
      <IconButton 
        onClick={() => actionHandler(handleIncrement)} 
        size="small" 
        sx={{ borderRadius: 0, }}
      >
        <AddIcon sx={{color:'rgb(128 126 126)', fontSize: '16px'}} />
      </IconButton>
    </Box>
  );
};

export default QuantityBox;
