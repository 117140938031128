import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

const CloseBtn = () => {
  return (
    <div>
        <CloseIcon style={{
          height:"25px",
          width:"25px",
          color: "#000",
          backgroundColor: "#fff",
          borderRadius:"100%",
          filter: "drop-shadow(0px 3.205px 3.205px rgba(0, 0, 0, 0.25))",
          padding:"3px",
          }}/>
    </div>
  )
}

export default CloseBtn