import { useEffect, useRef, useState } from "react";

const useFrequencyCondition = (popup) => {
  const callFrequencyRef = useRef(false);
  const [isPopupAllowedForFrequency, setIsPopupAllowedForFrequency] =
    useState(true);

  const getStorageKey = (keyType, type, id) => `${keyType}-${type}-${id}`;

  const sessionKey = getStorageKey("popup-open", popup?.popUpType, popup?._id);
  const sessionItem = sessionStorage.getItem(sessionKey);

  const { frequency } = popup?.generalSettings?.condition || {};

  const checkIntervalLimitation = () => {
    const currentTime = new Date().getTime();

    const lastShownTime = Number(
      sessionStorage.getItem(
        getStorageKey("popup-frequency", popup.popUpType, popup._id)
      ) || currentTime.toString()
    );

    sessionStorage.setItem(
      getStorageKey("popup-frequency", popup.popUpType, popup._id),
      currentTime.toString()
    );

    let timeInterval;

    switch (frequency.interval_unit) {
      case "Days":
        timeInterval = frequency.interval_count * 24 * 60 * 60 * 1000;
        break;
      case "Weeks":
        timeInterval = frequency.interval_count * 7 * 24 * 60 * 60 * 1000;
        break;
      case "Months":
        timeInterval = frequency.interval_count * 30 * 24 * 60 * 60 * 1000;
        break;
      case "PageViews":
        const pageViewsKey = getStorageKey(
          "PageViews",
          popup.popUpType,
          popup._id
        );

        const pageViews = Number(sessionStorage.getItem(pageViewsKey) || 0);

        if (pageViews === Number(frequency.interval_count - 1)) {
          sessionStorage.setItem(pageViewsKey, "0");
          return true;
        }

        sessionStorage.setItem(pageViewsKey, (pageViews + 1).toString());

        return false;

      default:
        return false;
    }

    if (currentTime - lastShownTime >= timeInterval) {
      return true;
    }
    return false;
  };

  const checkAllowedForFrequency = () => {
    switch (frequency?.show_the_popup) {
      case "every_page_view":
        return setIsPopupAllowedForFrequency(true);
      case "every_session":
        if (!sessionItem) {
          sessionStorage.setItem(sessionKey, "true");
          return setIsPopupAllowedForFrequency(true);
        }
        return setIsPopupAllowedForFrequency(false);
      case "interval_limitation":
        return setIsPopupAllowedForFrequency(checkIntervalLimitation());
      default:
        return setIsPopupAllowedForFrequency(false);
    }
  };

  useEffect( () => {
    if (isPopupAllowedForFrequency) {
        localStorage.setItem("is_frequency_triggered", "true")
    } else {
        localStorage.setItem("is_frequency_triggered", "false")
    }
  }, [isPopupAllowedForFrequency])

  useEffect(() => {
      if (!callFrequencyRef.current && popup?._id) {
      checkAllowedForFrequency();
      callFrequencyRef.current = true;
    }
  }, []);


  return { isPopupAllowedForFrequency };
};

export default useFrequencyCondition;
