import React from "react";
import { parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";

const VideoBlock = ({ section }) => {
  const { isMobile, appState } = useApp();
  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };
  const videoSrc = section?.src?.video;

  const isYouTube = videoSrc && videoSrc.includes("youtube.com");
  const isInstagram = videoSrc && videoSrc.includes("instagram.com");

  const cleanInstagramUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      parsedUrl.search = ""; // Remove query parameters
      return parsedUrl.toString();
    } catch (error) {
      console.error("Invalid Instagram URL", error);
      return url;
    }
  };
  const cleanedVideoSrc = isInstagram ? cleanInstagramUrl(videoSrc) : videoSrc;
  return (
    <>
      {isYouTube ? (
        <iframe
          style={{
            ...blockCSS,
            pointerEvents: appState?.is_preview_mode ? "none" : "auto",
            height: "100%",
            width: "100%",
            border: 0,
          }}
          src={videoSrc.replace("watch?v=", "embed/") + "?autoplay=1"}
          title="YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : isInstagram ? (
        <iframe
          style={{
            ...blockCSS,
            pointerEvents: appState?.is_preview_mode ? "none" : "auto",
            border: 0,
          }}
          src={`${cleanedVideoSrc}embed`}
          allowFullScreen
          scrolling={(section?.setting?.isScrolling) ? "yes" : "no"}
          allowTransparency="true"
        ></iframe>
      ) : (
        <video
          style={{
            ...blockCSS,
            pointerEvents: appState?.is_preview_mode ? "none" : "auto",
          }}
          src={videoSrc}
          title="Video"
          controls={section?.setting?.isControls}
          loop={section?.setting?.isLoop}
          autoPlay={section?.setting?.isAutoplay}
          muted={section?.setting?.isMuted}
        ></video>
      )}
    </>
  );
};

export default VideoBlock;
