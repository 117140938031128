import React, { memo, useCallback, useState } from "react";

// propTypes
import PropTypes from "prop-types";

import { parseCssStringToObject, sentPostMessage } from "../../../../helper";
import {
  DEFAULT_BLOCK_CSS,
  POPUP_SECTIONS,
} from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import TextBlock from "./TextBlock";
import ButtonBlock from "./ButtonBlock";
import DraggableWrapper from "../../../Draggable";
import ImageSliderBlock from "./ImageSliderBlock";
import QuantityPickerBlock from "./QuantityPickerBlock";
import VariantPickerBlock from "./VariantPickerBlock";
import PriceBlock from "./PriceBlock";
import RatingBlock from "./RatingBlock";

const ProductBlock = ({
  activeStep,
  popupData,
  popupState,
  setPopupState,
  section,
  index: parentIndex,
}) => {
  const { appState, isMobile } = useApp();

  const [selectedBlock, setSelectedBlock] = useState({});

  const handleBlockClick = (type, index) => {
    if (appState && appState?.is_preview_mode) {
      if (selectedBlock.type !== type || selectedBlock.type !== index) {
        sentPostMessage("select_block", {
          index: parentIndex,
          subIndex: index,
        });

        setSelectedBlock({ type, index });
      }
    }
  };

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const productSectionsSwitch = useCallback(
    (productData, productIndex, productSection, productSectionIndex) => {
      switch (productSection?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TextBlock section={productSection} />;
        case POPUP_SECTIONS.DESCRIPTION:
          return <TextBlock section={productSection} />;

        case POPUP_SECTIONS.COVER_IMAGE:
          return (
            <ImageSliderBlock
              section={productSection}
              productIndex={productIndex}
              productData={productData}
            />
          );
        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              popupData={popupData}
              popupState={popupState}
              setPopupState={setPopupState}
              section={productSection}
              index={productSectionIndex}
              productIndex={productIndex}
              productData={productData}

            />
          );
        case POPUP_SECTIONS.QUANTITY_PICKER:
          return (
            <QuantityPickerBlock
              section={productSection}
              index={productSectionIndex}
              productIndex={productIndex}
              //   productData={productData}
            />
          );
        case POPUP_SECTIONS.VARIANT_PICKER:
          return (
            <VariantPickerBlock
              section={productSection}
              index={productSectionIndex}
              productIndex={productIndex}
              productData={productData}
            />
          );
        case POPUP_SECTIONS.PRICE:
          return (
            <PriceBlock
              productData={productData}
              section={productSection}
              index={productSectionIndex}
              productIndex={productIndex}
            />
          );
        case POPUP_SECTIONS.RATING:
          return <RatingBlock section={productSection} />;
        // case POPUP_SECTIONS.BADGE:
        //   return <BadgeBlock text="New Arrival" section={productSection} />;
        default:
          return null;
      }
    },
    [popupData, popupState, setPopupState, section]
  );

  return (
    <div style={blockCSS}>
      {section?.products?.map((productData, productIndex) => {
        return (
          <>
            {section?.sections?.map((productSection, productSectionIndex) => {
              return (
                <DraggableWrapper
                  key={`${parentIndex}-${productIndex}-${productSectionIndex}`}
                  index={parentIndex}
                  popupSettings={popupData?.schema[activeStep]?.popupSettings}
                  section={productSection}
                  isSelected={
                    productSection?.type === selectedBlock?.type &&
                    productSectionIndex === selectedBlock?.index
                  }
                  onClick={() =>
                    handleBlockClick(productSection?.type, productSectionIndex, productIndex)
                  }
                  isSubSection
                  subIndex={productSectionIndex}
                >
                  {productSectionsSwitch(
                    productData,
                    productIndex,
                    productSection,
                    productSectionIndex
                  )}
                </DraggableWrapper>
              );
            })}
          </>
        );
      })}
    </div>
  );
};

ProductBlock.propTypes = {
  section: PropTypes.object,
};

export default ProductBlock;
