import axios from "axios";

const instance = axios.create({
  baseURL: "https://popup-upsell-ai-dev.aimbrill.com/" 
});

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (res.status === 201 || res.status === 200) {
      console.log("Posted Successfully.!");
    }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const api = instance;
