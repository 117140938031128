import { useState, useEffect, useRef, useContext } from 'react';
import { removePx } from '../helper';
import { useApp } from '../context/AppState';

const getTextTransFormation = (popupSettings, isMobile) => {


    const popupPosition = popupSettings?.dynamicalOptions?.popupPosition?.[isMobile ? "mobile" : "desktop"];

    switch (popupPosition) {
        case "top center":
        case "center":
        case "bottom center":
            return {
                mirrorTransform: {
                    parent: "scaleY(1)",
                    current: "scaleY(1)",
                },
                wordReverse: false,
                rotate: "0deg",
            };

        case "bottom left":
        case "top left":
        case "left center":
            return {
                mirrorTransform: {
                    parent: "scaleY(-1)",
                    current: "scaleY(-1)"
                },
                wordReverse: true,
                rotate: "90deg",
            };

        case "top right":
        case "bottom right":
        case "right center":
            return {
                mirrorTransform: {
                    parent: "scaleY(1)",
                    current: "scaleY(1)"
                },
                wordReverse: false,
                rotate: "90deg",
            };


        default:
            return {
                mirrorTransform: {
                    parent: "scaleY(1)",
                    current: "scaleY(1)"
                },
                wordReverse: false,
                rotate: "0deg",
            };
    }
}

const useTextLayout = (text, css, popupSettings) => {


    const { isMobile } = useApp();

    const transformation = getTextTransFormation(popupSettings, isMobile)

    const [layout, setLayout] = useState('single');
    const containerRef = useRef(null);

    const updateLayout = () => {
        if (containerRef.current) {
            const availableWidth = containerRef.current.offsetWidth - (removePx(css?.padding ?? "0px") * 2) - 20;

            let isWordLayout = true;

            const tempSingleDiv = document.createElement('div');
            tempSingleDiv.style.position = 'absolute';
            tempSingleDiv.style.visibility = 'hidden';
            tempSingleDiv.style.fontSize = css?.fontSize ?? '20px';
            tempSingleDiv.innerText = text;
            document.body.appendChild(tempSingleDiv);

            const textWidth = tempSingleDiv.offsetWidth;
            document.body.removeChild(tempSingleDiv);



            const words = text.split(' ');

            for (let word of words) {
                const tempDiv = document.createElement('div');
                tempDiv.style.position = 'absolute';
                tempDiv.style.visibility = 'hidden';
                tempDiv.style.fontSize = css?.fontSize ?? '20px';
                tempDiv.innerText = word;
                document.body.appendChild(tempDiv);

                const wordWidth = tempDiv.offsetWidth;
                document.body.removeChild(tempDiv);


                if (availableWidth < wordWidth) {
                    isWordLayout = false;
                    break;
                }
            }

            if (textWidth <= availableWidth) {
                setLayout('single');
            } else if (textWidth > availableWidth && isWordLayout) {
                setLayout('word');
            } else {
                setLayout('character');
            }
        }
    };

    useEffect(() => {
        updateLayout();

        const resizeObserver = new ResizeObserver(() => {
            updateLayout();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [text, css]);

    const words = transformation?.wordReverse ? text?.split(" ").reverse() : text?.split(" ");

    const wordCSS = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        height: "100%",
        ...(layout === 'character' && { transform: transformation.mirrorTransform.parent })
    }


    const containerCSS = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
        padding: "10px",
        margin: 0,
        width: "100%",
        height: "100%",
        lineHeight: css?.lineHeight ?? css?.fontSize ?? "20px",
        backgroundColor: "transparent",
        whiteSpace: 'wrap',
    }

    return (
        <div
            ref={containerRef}
            style={containerCSS}
        >
            {layout === 'single' && <div style={wordCSS}>{text}</div>}
            {layout === 'word' && words.map((word, index) => <div key={index} style={wordCSS}>{word}</div>)}

            {layout === 'character' && words.map((word, index) => (
                <div key={index} style={wordCSS}>{word.split('').map((char, index) => (
                    <div key={index} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: transformation.mirrorTransform.parent,
                        minHeight: css?.fontSize ?? "20px",
                        rotate: transformation.rotate,
                    }}>{char}</div>
                ))
                }</div>)
            )}

        </div>
    );
};

export default useTextLayout;
