import React, { memo, useMemo } from "react";
import PopUpModel from "../../components/PopUpModel";
import MultiBoardCard from "../../components/MultiBoardPopup/MultiBoardCard";

const RenderVolumePopup = ({ popupData, popupState, setPopupState }) => {
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);

  return (
    <>
      {popupState.openPopup ? (
        <PopUpModel
          open={popupState.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          popupType={popupType}
        >
          <MultiBoardCard
            popupData={popupData}
            popupState={popupState}
            activeStep={popupState.activeStep}
            setPopupState={setPopupState}
          />
        </PopUpModel>
      ) : null}
    </>
  );
};

export default memo(RenderVolumePopup);
