import { useEffect, useRef, useState } from "react";
import { useConfetti } from "./useConfetti";
import { debounce, isArray } from "lodash";
import { isValidSelector } from "../helper";
import { useApp } from "../context/AppState";
import usePageCondition from "./usePageCondition";
import useDeviceCondition from "./useDeviceCondition";

const exceptionTrigger = ["click_trigger", "hover_trigger", "page_target"];

const useTriggerCondition = (popups, isTriggered, globalPopupStates) => {
  const { appState } = useApp();
  const { isPopupPageTargeted } = usePageCondition();
  const timeoutRefs = useRef({});
  const exitIntentListenerAttached = useRef(false);
  const inactivityTimerRef = useRef(null);
  const globalPopupStatesRef = useRef(globalPopupStates);
  const triggerRef = useRef(localStorage);
  const [isFrequencyTriggered, setIsFrequencyTriggered] = useState(null); 
  // const isFrequencyTriggered = localStorage.getItem("is_frequency_triggered");

  const popupsObject = popups?.reduce((acc, popup) => {
    acc = popup?.popupData;
    return acc;
  }, {});

  const { isDesktopActive, isMobileActive } = useDeviceCondition(popupsObject);

  useEffect(() => {
    // Fetch the value from localStorage when component mounts
    const frequencyTriggered = localStorage.getItem("is_frequency_triggered") || "true";
    setIsFrequencyTriggered(frequencyTriggered); // Set it to state to ensure consistent re-render
  }, []); // Runs only once when the component mounts

  useEffect(() => {
    globalPopupStatesRef.current = globalPopupStates;
  }, [globalPopupStates]);

  useEffect(() => {
    triggerRef.current = localStorage;
  }, [localStorage]);

  const confetti = useConfetti();


  const triggerPopupDisplay = (popUpType, popup, triggerType) => {

    const isTriggered = triggerRef?.current?.getItem(
      `isTriggered-${popUpType}-${triggerType}-${popup?.popupData?._id}`
    );

    const isDevicePopupVisible =
      (isDesktopActive && !isMobileActive) ||
      (!isDesktopActive && isMobileActive) ||
      (isDesktopActive && isMobileActive);

    if (
      !isTriggered &&
      isPopupPageTargeted(popup) &&
      isFrequencyTriggered === "true" &&
      isDevicePopupVisible
    ) {
      if (!exceptionTrigger.includes(triggerType)) {
        localStorage.setItem(
          `isTriggered-${popUpType}-${triggerType}-${popup?.popupData?._id}`,
          "true"
        );
      }
      confetti.fire();
      popup.loadPopup();
    }
  };

  const closePopupAfterTime = (popup, popUpType) => {
    const isOpenedPopup = globalPopupStatesRef?.current?.[`${popUpType}_${popup?.popupData?._id}`]?.openPopup;
    if (isOpenedPopup) {
      popup.closePopup();
    }
  };

  const handleTimeoutTrigger = () => {
    const triggerType = "timeout_trigger";

    const allowedPopups = popups.filter(
      (popup) =>
        popup?.popupData?.generalSettings?.condition?.trigger?.allow_show_after
    );

    allowedPopups.forEach((popup) => {
      const { show_after } =
        popup?.popupData?.generalSettings?.condition?.trigger;
      const popUpType = popup?.popupData?.popUpType;
      if (!isTriggered) {
        const timeoutId = setTimeout(() => {
          triggerPopupDisplay(popUpType, popup, triggerType);
        }, show_after * 1000);

        timeoutRefs.current[popUpType] = timeoutId;
      }
    });
  };

  const handleScrollTrigger = () => {
    const triggerType = "scroll_trigger";

    const handleScroll = debounce(() => {
      const scrollY = window.scrollY;
      const viewportHeight = window.innerHeight;

      const allowedPopups = popups.filter(
        (popup) =>
          popup?.popupData?.generalSettings?.condition?.trigger
            ?.allow_after_scroll
      );
      allowedPopups.forEach((popup) => {
        const { show_after_scroll, show_after_scroll_unit } =
          popup?.popupData?.generalSettings?.condition?.trigger;

        const popUpType = popup?.popupData?.popUpType;

        const isDraggable = popup?.popupData?.popupValues?.generalSettings?.isDraggable;

        const scrollUnit = isDraggable ? `${show_after_scroll}${show_after_scroll_unit}` : show_after_scroll;

        let threshold;

        if (scrollUnit.endsWith("%")) {
          const percentage = parseFloat(scrollUnit) / 100;
          threshold = viewportHeight * percentage;
        } else {
          threshold = parseFloat(scrollUnit);
        }

        if (scrollY >= threshold && !isTriggered) {
          triggerPopupDisplay(popUpType, popup, triggerType);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  };

  const handleExitIntentTrigger = () => {
    const triggerType = "exit_intent_trigger";
    const handleMouseOut = (e) => {
      const allowedPopups = popups.filter(
        (popup) =>
          popup?.popupData?.generalSettings?.condition?.trigger
            ?.allow_Exit_Intent
      );

      allowedPopups.forEach((popup) => {
        const popUpType = popup?.popupData?.popUpType;

        if (e.clientY <= 0 && !isTriggered) {
          triggerPopupDisplay(popUpType, popup, triggerType);
          exitIntentListenerAttached.current = false;
        }
      });
    };

    window.addEventListener("mouseout", handleMouseOut);

    return () => {
      window.removeEventListener("mouseout", handleMouseOut);
    };
  };

  const handleHoverTrigger = () => {
    let events = [];
    const triggerType = "hover_trigger";
    const allowedPopups = popups.filter(
      (popup) =>
        popup?.popupData?.generalSettings?.condition?.trigger?.allow_onhover &&
        isValidSelector(
          popup?.popupData?.generalSettings?.condition?.trigger
            ?.onhover_css_selector
        )
    );

    allowedPopups.forEach((popup) => {
      const { onhover_css_selector } =
        popup?.popupData?.generalSettings?.condition?.trigger;
      let element = document.querySelector(onhover_css_selector);
      const popUpType = popup?.popupData?.popUpType;

      const handleMouseEnter = () => {
        triggerPopupDisplay(popUpType, popup, triggerType);
      };

      const observer = new MutationObserver(() => {
        element = document.querySelector(onhover_css_selector); // Recheck the element
        if (element) {
          // Reattach event listener every time the element is available
          element.addEventListener("mouseenter", handleMouseEnter);
          observer.disconnect(); // Stop observing once the element is found and listener is attached
        }
      });

      if (!element) {
        observer.observe(document.body, { childList: true, subtree: true });
      } else {
        element.addEventListener("mouseenter", handleMouseEnter);
      }

      // Cleanup function to remove event listener when necessary (but not prematurely)
      events.push(() => {
        if (element) {
          element.removeEventListener("mouseenter", handleMouseEnter);
        }
        // observer.disconnect();
      });
    });

    return events;
  };

  const handleClickTrigger = () => {
    let events = [];
    const triggerType = "click_trigger";
    const allowedPopups = popups.filter(
      (popup) =>
        popup?.popupData?.generalSettings?.condition?.trigger?.allow_onclick &&
        isValidSelector(
          popup?.popupData?.generalSettings?.condition?.trigger
            ?.onclick_css_selector
        )
    );

    allowedPopups.forEach((popup) => {
      const { onclick_css_selector } =
        popup?.popupData?.generalSettings?.condition?.trigger;
      let element = document.querySelector(onclick_css_selector);
      const popUpType = popup?.popupData?.popUpType;

      const handleClick = () => {
        triggerPopupDisplay(popUpType, popup, triggerType);
      };

      const observer = new MutationObserver(() => {
        element = document.querySelector(onclick_css_selector); // Recheck the element
        if (element) {
          // Ensure listener executes in the capturing phase
          element.addEventListener("click", handleClick, true);  // Capture phase

          // Re-attach listener after all listeners are attached
          setTimeout(() => {
            element.removeEventListener("click", handleClick);  // Optional: remove previous listener
            element.addEventListener("click", handleClick, false);  // Bubbling phase
          }, 0);
          observer.disconnect();
        }
      });

      if (!element) {
        observer.observe(document.body, { childList: true, subtree: true });
      } else {
        element.addEventListener("click", handleClick, true);  // Capture phase

        // Re-attach listener after all listeners are attached
        setTimeout(() => {
          element.removeEventListener("click", handleClick);  // Optional: remove previous listener
          element.addEventListener("click", handleClick, false);  // Bubbling phase
        }, 0);
      }

      // Cleanup function to remove event listener and disconnect observer
      events.push(() => {
        if (element) {
          element.removeEventListener("click", handleClick);
        }
        observer.disconnect();
      });
    });

    return events;
  };

  const handleCloseAfterTimeTrigger = () => {
    let timeOut;
    const allowedPopups = popups.filter(
      (popup) =>
        popup?.popupData?.generalSettings?.condition?.trigger
          ?.allow_close_after_time
    );
    allowedPopups.forEach((popup) => {
      const { close_after } =
        popup?.popupData?.generalSettings?.condition?.trigger;
      const popUpType = popup?.popupData?.popUpType;
      const closeTimeout = setTimeout(() => {
        closePopupAfterTime(popup, popUpType);
      }, close_after * 1000); // convert seconds to milliseconds

      timeOut = () => {
        clearTimeout(closeTimeout);
      };
    });
    return timeOut;
  };

  const handleInactivityTrigger = () => {
    const triggerType = "inactivity_trigger";
    const activityEvents = ["mousemove", "keydown", "scroll"];

    const resetInactivityTimer = () => {
      const allowedPopups = popups.filter(
        (popup) =>
          popup?.popupData?.generalSettings?.condition?.trigger
            ?.allow_after_inactive
      );
      allowedPopups.forEach((popup) => {
        const { show_after_inactive } =
          popup?.popupData?.generalSettings?.condition?.trigger;
        const popUpType = popup?.popupData?.popUpType;
        clearTimeout(inactivityTimerRef.current);
        inactivityTimerRef.current = setTimeout(() => {
          triggerPopupDisplay(popUpType, popup, triggerType);
        }, show_after_inactive * 1000);
      });
    };

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimerRef.current);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimer)
      );
    };
  };

  useEffect(() => {
    if (appState?.is_preview_mode) {
      return;
    }

    const triggerHandlers = [
      handleTimeoutTrigger,
      handleScrollTrigger,
      handleExitIntentTrigger,
      handleClickTrigger,
      handleHoverTrigger,
      handleCloseAfterTimeTrigger,
      handleInactivityTrigger,
    ];

    let events = [];

    triggerHandlers.forEach((triggerHandler) => {
      const event = triggerHandler();
      if (event) {
        events.push(event);
      }
    });

    return () => {
      events.forEach((event) => {
        if (isArray(event)) {
          event.forEach((subEvents) => {
            subEvents();
          });
        } else {
          event();
        }
      });
    };
  }, [popups]);
};

export default useTriggerCondition;
