import React from "react";
import { TextField, Grid } from "@mui/material";
import { useAction } from "../../hooks/useAction";
import { useApp } from "../../context/AppState";

export default function BirthDayBlock({ section, setBirthday, birthday, isEmptyField }) {
  const customCSS = section?.setting?.customCSS || {};

  const { actionHandler } = useAction();
  const { isSmallMobile } = useApp();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBirthday((prevBirthday) => ({
      ...prevBirthday,
      [name]: Number(value),
    }));
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: "2px" }}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size="small"
          id="day-input"
          name="day"
          sx={{
            borderRadius: '8px',
            backgroundColor: 'transparent',
            "& .MuiOutlinedInput-root": {
              borderRadius: '8px',
            },
            "& .MuiInputBase-root": {
              fontSize: isSmallMobile ? '12px' : '16px',
              height: '40px',
            },
            ...(!Boolean(isEmptyField) && {
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#252f39", // Hover border color
              },
    
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#252f39", // Focus border color, same as hover
                },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)", // Original border color when not focused or hovered
              },
            }),
            ...(Boolean(isEmptyField) && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "red", // Red border color if there's an error
              },
            }),
          }}
          InputLabelProps={{
            sx: {
              fontSize: isSmallMobile ? '10px' : '13px',
            },
          }}
          InputProps={{
            sx: {
              fontSize: isSmallMobile ? '10px' : '13px',
            },
          }}
          placeholder="Day"
          value={birthday?.day || ""}
          style={customCSS}
          onChange={(e) => actionHandler(() => handleChange(e))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size="small"
          id="month-input"
          name="month"
          placeholder="Month"
          sx={{
            borderRadius: '8px',
            backgroundColor: 'transparent',
            "& .MuiOutlinedInput-root": {
              borderRadius: '8px',
            },
            "& .MuiInputBase-root": {
              fontSize: isSmallMobile ? '12px' : '16px',
              height: '40px',
            },
  

            ...(!Boolean(isEmptyField) && {
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#252f39", // Hover border color
              },
    
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#252f39", // Focus border color, same as hover
                },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)", // Original border color when not focused or hovered
              },
            }),
            ...(Boolean(isEmptyField) && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "red", // Red border color if there's an error
              },
            }),
          }}
          InputLabelProps={{
            sx: {
              fontSize: isSmallMobile ? '10px' : '13px',
            },
          }}
          InputProps={{
            sx: {
              fontSize: isSmallMobile ? '10px' : '13px',
            },
          }}
          value={birthday?.month || ""}
          style={customCSS}
          onChange={(e) => actionHandler(() => handleChange(e))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size="small"
          id="year-input"
          name="year"
          placeholder="Year"
          sx={{
            borderRadius: '8px',
            backgroundColor: 'transparent',
            "& .MuiOutlinedInput-root": {
              borderRadius: '8px',
            },
            "& .MuiInputBase-root": {
              fontSize: isSmallMobile ? '12px' : '16px',
              height: '40px',
            },
            ...(!Boolean(isEmptyField) && {
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#252f39", // Hover border color
              },
    
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#252f39", // Focus border color, same as hover
                },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)", // Original border color when not focused or hovered
              },
            }),
            ...(Boolean(isEmptyField) && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "red", // Red border color if there's an error
              },
            }),
          }}
          InputLabelProps={{
            sx: {
              fontSize: isSmallMobile ? '10px' : '13px',
            },
          }}
          InputProps={{
            sx: {
              fontSize: isSmallMobile ? '10px' : '13px',
            },
          }}
          value={birthday?.year || ""}
          style={customCSS}
          onChange={(e) => actionHandler(() => handleChange(e))}
        />
      </Grid>
    </Grid>
  );
}
