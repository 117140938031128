import { useApp } from "../context/AppState";

const usePageCondition = () => {
  const { appState } = useApp();
  const isFrequencyTriggered = localStorage.getItem("is_frequency_triggered")
  const isAllPagesTargeted = (pages) => pages?.page_option === "all_pages";

  const isSpecificPageMatched = (specific_pages) => {
    const currentPath = window.location.pathname.replace(/^\//, '');
    return specific_pages.some(({ type, value }) => {
      if (type === "matches") {
        return currentPath === value;
      } else if (type === "contains") {
        return currentPath.includes(value);
      }
      return false;
    });
  };

  const isPopupPageTargeted = (popup) => {

    if (appState?.is_preview_mode) return true;

    const { condition } = popup?.popupData?.generalSettings || popup?.generalSettings;
    const { pages } = condition || {};

    if (isAllPagesTargeted(pages)) {
      return true;
    }

    if (pages?.page_option === "specific_pages" && isFrequencyTriggered === "true") {
      return isSpecificPageMatched(pages.specific_pages);
    }

    return false;
  };

  return { isPopupPageTargeted };
};

export default usePageCondition;
