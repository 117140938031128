import React from "react";
import { Typography } from "@mui/material";
import { useApp } from "../../context/AppState";



export default function SocialProofTitle({ section, switchValue = false,product }) {
  const customCSS = section?.setting?.customCSS || {};
  const { isSmallMobile } = useApp(); 

  const replacePlaceholders = (text, values) => {
    const placeholderRegex = /{{\s*(\w+)\s*}}/g;
    return text.replace(placeholderRegex, (match, key) => {
      return values[key] || match;
    });
  };
  const values = {
    first_name: product?.first_name || 'Someone',
    city: product?.city || 'city',
    country: product?.country || 'country',
    state: product?.state || 'state' ,
    product_name: product?.title || "Best Sellout Product"
  };

  const text = section?.text ? replacePlaceholders(section.text, values) : "Welcome to the store";

  return (
    <div>
      <div
        style={{
          fontSize: isSmallMobile ? "13px" : "14px",
          ...(customCSS ? customCSS : { margin: 0 }),  
        }}
      >
        {text}
      </div>
    </div>
  );
}
