import React from "react";
import { POPUP_SECTIONS } from "../../constant/constant";
import ButtonBlock from "../Preview/ButtonBlock";
import InputBlock from "../Preview/InputBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import TitleBlock from "../Preview/TitleBlock";
import ConditonBlock from "../Preview/Conditon";

const FirstComponent = ({
  popupData,
  onClick,
  activeStep,
  handleOnEmailChange,
  email,
  disabled,
  emailError,
  isEmailExist
}) => {
  const renderSections = popupData?.schema[activeStep]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.SUBTITLE:
          return <SubTitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.INPUT:
          return (
            <InputBlock
              section={section}
              onChange={handleOnEmailChange}
              email={email}
              key={index}
              emailError={emailError}
              isEmailExist={isEmailExist}
            />
          );
        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              section={section}
              onClick={onClick}
              disabled={disabled}
              key={index}
              emailError={emailError}
            />
          );
        case POPUP_SECTIONS.CONDITION:
          return (
            <ConditonBlock
              section={section}
              value={popupData?.schema[activeStep]}
              key={index}
            />
          );
        default:
          return null;
      }
    }
  );

  return <div>{renderSections}</div>;
};

export default FirstComponent;
