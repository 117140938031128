import { useMemo } from "react";
import { useApp } from "../context/AppState";
import { analyticsEventTypes } from "../helper";
import { useAnalytics } from "./useAnalytics";
import { useSubscribers } from "./useSubscribers";
import { useFormContext } from "react-hook-form";
import { useConfetti } from "./useConfetti";
import { POPUP_SECTIONS } from "../constant/constant";
import cartService from "../services/cart.service";

export const useFormSumbmit = ({ popupData, setPopupState, productIndex, productData }) => {
    const { appState, toast } = useApp();
    const { watch } = useFormContext();
    const popupValue = watch();
    const { addAnalyticsEvent } = useAnalytics();
    const popupType = useMemo(() => popupData?.popUpType, [popupData]);
    const popupId = useMemo(() => popupData?._id, [popupData]);

    const confetti = useConfetti(popupData);

    const { createSubscribers } = useSubscribers(popupData);

    const getCouponCode = async () => {


        const response = await createSubscribers(popupValue?.email, appState?.shop?.shop, popupValue?.birthdate, popupValue?.textarea, popupValue?.rating);


        if (response?.success) {

            if (response?.data?.isExist) {
                return toast.error("Email is already exist!");
            }

            confetti.fire();

            await addAnalyticsEvent({
                eventType: analyticsEventTypes.INTERACTION,
                popupType: popupData?.popUpType,
                popupId: popupData?._id,
                ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
            });

            setPopupState((prev) => ({
                ...prev,
                [`${popupType}_${popupId}`]: { ...prev[`${popupType}_${popupId}`], activeStep: prev[`${popupType}_${popupId}`]?.activeStep + 1 },
            }));
        } else {
            toast.error("Email is already exist!");
        }
    };

    const addCart = async () => {

        let items = [
            {
                id: popupValue?.[`${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`] ?? productData?.variants?.[0]?.id ??
                productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop(),
                quantity: popupValue?.[`${POPUP_SECTIONS.QUANTITY_PICKER}-${productIndex}`] ?? 1,
            },
        ];

        const result = await cartService.addToCart(items);
        if (result) {
            await addAnalyticsEvent({
                eventType: analyticsEventTypes.INTERACTION,
                popupType: popupData?.popUpType,
                popupId: popupData?._id,
                ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
            });
        }

    };

    const checkout = async () => {
        let items = [
            {
                id: popupValue?.[`${POPUP_SECTIONS.VARIANT_PICKER}-${productIndex}`] ?? productData?.variants?.[0]?.id ??
                productData?.variants?.[0]?.admin_graphql_api_id?.split("/")?.pop(),
                quantity: popupValue?.[`${POPUP_SECTIONS.QUANTITY_PICKER}-${productIndex}`] ?? 1,
            },
        ];

        const result = await cartService.getCheckoutUrl(items);
        if (result) {
            window.location.href = result?.data?.data?.cart?.checkoutUrl;
            await addAnalyticsEvent({
                eventType: analyticsEventTypes.INTERACTION,
                popupType: popupData?.popUpType,
                popupId: popupData?._id,
                ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
            });
        }

    };



    return {
        getCouponCode,
        addCart,
        checkout
    };
};