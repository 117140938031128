import { useMemo } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { getBrowser, getOS } from "../helper";
import { api } from "../utils/interceptor";

export const useAnalytics = () => {
  const localUserId = localStorage.getItem("user-id");
  const pageViews = JSON.parse(localStorage.getItem("user-pageViews") || "[]");
  const storeName = window?.Shopify?.shop || window.location.hostname;

  const location = useLocation();

  const currentPageId = useMemo(() => {
    const isPageViewExists = pageViews?.find(
      (pageView) => pageView?.pathname === location?.pathname
    );
    return isPageViewExists?.pageViewID || `pageView-${uuidv4()}`;
  }, [location, localStorage]);

  const getLocationInfo = async () => {
    let locationData = null;
    try {
      locationData = await axios.get("https://ipinfo.io?token=1bd75dc5db6bae");
    } catch (error) {
      console.log("Error in fetch Location", error);
    }

    return {
      ...(locationData?.data ?? {}),
      os: getOS(),
      browser: getBrowser(),
    };
  };

  const addAnalytics = async (payload) => {
    try {
      const response = await api.post(
        `/api/public/analytics/${storeName}`,
        payload
      );

      return response?.data;
    } catch (error) {
      console.log("Error in adding account", error);
    }
  };

  const addAnalyticsEvent = async (payload) => {
    try {
      console.log({
        ...payload,
        pageViewId: currentPageId,
        userId: localUserId,
        pathname: location?.pathname,
      });
      const response = await api.post(
        `/api/public/analytics-event/${storeName}`,
        {
          ...payload,
          pageViewId: currentPageId,
          userId: localUserId,
          pathname: location?.pathname,
        }
      );
      return response?.data;
    } catch (error) {
      console.log("Error in adding account", error);
    }
  };

  return {
    getLocationInfo,
    addAnalytics,
    addAnalyticsEvent,
  };
};
