import { keyframes } from "@emotion/react";
import { POPUP_SECTIONS } from "../constant/constant";

export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = `${(now.getMonth() + 1).toString().padStart(2, "0")}`;
  const day = `${now.getDate().toString().padStart(2, "0")}`;
  const hours = `${now.getHours().toString().padStart(2, "0")}`;
  const minutes = `${now.getMinutes().toString().padStart(2, "0")}`;

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const pickRandomValueFromArray = (itemArray) => {
  return itemArray?.[Math.floor(Math.random() * itemArray?.length)];
};

export const calculatediffrence = (difference) => {
  let timeRemaining = {};

  if (difference > 0) {
    timeRemaining = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeRemaining;
};

export const calculateEventTimeDifference = (
  event_starttime,
  event_endtime,
) => {

  if (!event_starttime || !event_endtime) return false;

  const currentTime = new Date();
  const eventTime = new Date(event_starttime);
  const eventEndTime = new Date(event_endtime);

  if (isNaN(currentTime) || isNaN(eventTime)) {
    return null; // Handle invalid dates if needed
  }

  const eventTimeDiffrence = eventTime - currentTime;
  const eventEndDiffrece = eventEndTime - currentTime;
  return eventTimeDiffrence <= 0 && eventEndDiffrece >= 0;
};

export const isValidEventTime = (eventStartTime, eventEndTime) => {
  const currentDate = new Date();
  const startTime = new Date(eventStartTime);
  const endTime = new Date(eventEndTime);

  const currentDateUTC = currentDate.getTime();
  const startTimeUTC = startTime.getTime();
  const endTimeUTC = endTime.getTime();

  return currentDateUTC >= startTimeUTC && currentDateUTC <= endTimeUTC;
};



export const getDynamicAnimation = (
  positionCss,
  animationType = "Fade",
  reverse = true
) => {
  const positions = ["bottom", "left", "top", "right"];
  const appliedPositions = {};

  positions?.forEach((pos) => {
    if (positionCss?.[pos] !== undefined) {
      appliedPositions[pos] = positionCss[pos];
    }
  });

  // Determine animation direction based on conditions
  const animationDirection = {};

  switch (animationType) {
    case "Slide In Vertical":
      if (positionCss.left !== undefined) {
        animationDirection.from = reverse
          ? { transform: "translateX(0)", opacity: 1 }
          : { transform: "translateX(-100%)", opacity: 0 };
        animationDirection.to = reverse
          ? { transform: "translateX(-100%)", opacity: 0 }
          : { transform: "translateX(0)", opacity: 1 };
      } else if (positionCss.right !== undefined) {
        animationDirection.from = reverse
          ? { transform: "translateX(0)", opacity: 1 }
          : { transform: "translateX(100%)", opacity: 0 };
        animationDirection.to = reverse
          ? { transform: "translateX(100%)", opacity: 0 }
          : { transform: "translateX(0)", opacity: 1 };
      }
      break;

    case "Slide In Horizontal":
      if (positionCss.top !== undefined) {
        animationDirection.from = reverse
          ? { transform: "translateY(0)", opacity: 1 }
          : { transform: "translateY(-100%)", opacity: 0 };
        animationDirection.to = reverse
          ? { transform: "translateY(-100%)", opacity: 0 }
          : { transform: "translateY(0)", opacity: 1 };
      } else if (positionCss.bottom !== undefined) {
        animationDirection.from = reverse
          ? { transform: "translateY(0)", opacity: 1 }
          : { transform: "translateY(100%)", opacity: 0 };
        animationDirection.to = reverse
          ? { transform: "translateY(100%)", opacity: 0 }
          : { transform: "translateY(0)", opacity: 1 };
      }
      break;

    case "Fade":
      animationDirection.from = reverse ? { opacity: 1 } : { opacity: 0 };
      animationDirection.to = reverse ? { opacity: 0 } : { opacity: 1 };
      break;

    default:
      animationDirection.from = reverse ? { opacity: 1 } : { opacity: 0 };
      animationDirection.to = reverse ? { opacity: 0 } : { opacity: 1 };
      break;
  }

  return animationDirection;
};

export const getDynamicPosition = (positionCss) => {
  const positions = ["bottom", "left", "top", "right"];
  const appliedPositions = {};

  positions?.forEach((pos) => {
    if (positionCss?.[pos] !== undefined) {
      appliedPositions[pos] = positionCss[pos];
    }
  });

  // Limit to only two applied positions
  const keys = Object.keys(appliedPositions);
  if (keys.length > 2) {
    const limitedPositions = {};
    limitedPositions[keys[0]] = appliedPositions[keys[0]];
    limitedPositions[keys[1]] = appliedPositions[keys[1]];
    return limitedPositions;
  }
  return appliedPositions;
};


export const parseCssStringToObject = (cssString) => {
  // Find the content inside the first curly braces

  try {
    const cssContentMatch = cssString?.match(/{([^}]*)}/);
    if (!cssContentMatch) {
      return {}; // Return empty object if no content found
    }

    const cssContent = cssContentMatch[1].trim();
    const cssObj = {};

    // Split the content by ';' to get individual rules
    const rules = cssContent.split(";");

    rules.forEach((rule) => {
      if (rule.trim()) {
        const [property, value] = rule.split(":");
        if (property && value) {
          cssObj[property.trim()] = value.trim().replace(/['"]/g, ""); // Removes extra quotes
        }
      }
    });

    return cssObj;
  } catch {
    return {};
  }
};

export const truncateHtml = (htmlString, wordLimit) => {
  const words = htmlString.split(/\s+/); // Split the string into words
  if (words.length > wordLimit) {
    const truncatedString = words.slice(0, wordLimit).join(' ') + '...';
    return truncatedString;
  }
  return htmlString; // Return original if it's within the word limit
};

export const validateEmail = (email) => {
  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex
  return emailRegex.test(email);
};

export const getAnimationStyle = (animation, position) => {
  const animationMappings = {
    "Fade In": { from: { opacity: 0 }, to: { opacity: 1 } },
    "Fade In Down": {
      from: { opacity: 0, transform: "translateY(-20%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "Fade In Down Big": {
      from: { opacity: 0, transform: "translateY(-50%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "Fade In Left": {
      from: { opacity: 0, transform: "translateX(-20%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Left Big": {
      from: { opacity: 0, transform: "translateX(-50%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Right": {
      from: { opacity: 0, transform: "translateX(20%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Right Big": {
      from: { opacity: 0, transform: "translateX(50%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Up": {
      from: { opacity: 0, transform: "translateY(20%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "Fade In Up Big": {
      from: { opacity: 0, transform: "translateY(50%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "No Animation": { from: {}, to: {} },
    "Flip In X": {
      from: { opacity: 0, transform: "rotateX(-180deg)" },
      to: { opacity: 1, transform: "rotateX(0)" },
    },
    "Flip In Y": {
      from: { opacity: 0, transform: "rotateY(-180deg)" },
      to: { opacity: 1, transform: "rotateY(0)" },
    },
    "Zoom In": {
      from: { opacity: 0, transform: "scale(0.5)" },
      to: { opacity: 1, transform: "scale(1)" },
    },
    "Zoom In Down": {
      from: { opacity: 0, transform: "scale(0.5) translateY(-20%)" },
      to: { opacity: 1, transform: "scale(1) translateY(0)" },
    },
    "Zoom In Left": {
      from: { opacity: 0, transform: "scale(0.5) translateX(-20%)" },
      to: { opacity: 1, transform: "scale(1) translateX(0)" },
    },
    "Zoom In Right": {
      from: { opacity: 0, transform: "scale(0.5) translateX(20%)" },
      to: { opacity: 1, transform: "scale(1) translateX(0)" },
    },
    "Zoom In Up": {
      from: { opacity: 0, transform: "scale(0.5) translateY(20%)" },
      to: { opacity: 1, transform: "scale(1) translateY(0)" },
    },
  };

  const { from, to } =
    animationMappings[animation] || animationMappings["No Animation"];

  let translateAdjustment = "";

  switch (position) {
    case "center":
      translateAdjustment = "translate(-50%, -50%)";
      break;
    case "left center":
      translateAdjustment = "translate(0%, -50%)";
      break;
    case "right center":
      translateAdjustment = "translate(0%, -50%)";
      break;
    case "top center":
      translateAdjustment = "translate(-50%, 0%)";
      break;
    case "bottom center":
      translateAdjustment = "translate(-50%, 0%)";
      break;
    default:
      translateAdjustment = "";
      break;
  }

  return keyframes`
    from {
      opacity: ${from?.opacity || 1};
      transform: ${from?.transform} ${translateAdjustment};
    }
    to {
      opacity: ${to?.opacity || 1};
      transform: ${to?.transform} ${translateAdjustment};
    }
  `;
};



export const getDraggablePopupAnimationStyle = (animation) => {
  const animationMappings = {
    "Fade In": { from: { opacity: 0 }, to: { opacity: 1 } },
    "Fade In Down": {
      from: { opacity: 0, transform: "translateY(-20%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "Fade In Down Big": {
      from: { opacity: 0, transform: "translateY(-50%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "Fade In Left": {
      from: { opacity: 0, transform: "translateX(-20%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Left Big": {
      from: { opacity: 0, transform: "translateX(-50%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Right": {
      from: { opacity: 0, transform: "translateX(20%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Right Big": {
      from: { opacity: 0, transform: "translateX(50%)" },
      to: { opacity: 1, transform: "translateX(0)" },
    },
    "Fade In Up": {
      from: { opacity: 0, transform: "translateY(20%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "Fade In Up Big": {
      from: { opacity: 0, transform: "translateY(50%)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    "No Animation": { from: {}, to: {} },
    "Flip In X": {
      from: { opacity: 0, transform: "rotateX(-180deg)" },
      to: { opacity: 1, transform: "rotateX(0)" },
    },
    "Flip In Y": {
      from: { opacity: 0, transform: "rotateY(-180deg)" },
      to: { opacity: 1, transform: "rotateY(0)" },
    },
    "Zoom In": {
      from: { opacity: 0, transform: "scale(0.5)" },
      to: { opacity: 1, transform: "scale(1)" },
    },
    "Zoom In Down": {
      from: { opacity: 0, transform: "scale(0.5) translateY(-20%)" },
      to: { opacity: 1, transform: "scale(1) translateY(0)" },
    },
    "Zoom In Left": {
      from: { opacity: 0, transform: "scale(0.5) translateX(-20%)" },
      to: { opacity: 1, transform: "scale(1) translateX(0)" },
    },
    "Zoom In Right": {
      from: { opacity: 0, transform: "scale(0.5) translateX(20%)" },
      to: { opacity: 1, transform: "scale(1) translateX(0)" },
    },
    "Zoom In Up": {
      from: { opacity: 0, transform: "scale(0.5) translateY(20%)" },
      to: { opacity: 1, transform: "scale(1) translateY(0)" },
    },
  };

  const { from, to } =
    animationMappings[animation] || animationMappings["No Animation"];


  return keyframes`
    from {
      opacity: ${from?.opacity || 1};
      transform: ${from?.transform} ;
    }
    to {
      opacity: ${to?.opacity || 1};
      transform: ${to?.transform};
    }
  `;
};

export const getOverlayStyle = (overlay_style) => {
  switch (overlay_style) {
    case "None":
      return {
        backgroundColor: "rgba(0, 0, 0, 0)",
        backdropFilter: "blur(0px)",
      };
    case "Darken":
      return {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(0px)",
      };
    case "Blur":
      return {
        backgroundColor: "rgba(0, 0, 0, 0)",
        backdropFilter: "blur(5px)",
      };
    case "DarkenBlur":
      return {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(5px)",
      };
    default:
      return {};
  }
};

export const getOuterBorderStyle = (dynamicalOptions) => {
  const border = dynamicalOptions?.isBorder
    ? `${dynamicalOptions?.border}px`
    : "0px";
  const borderStyle = dynamicalOptions?.borderStyle;
  const borderColor = dynamicalOptions?.borderColor;

  return {
    border: `${border} ${borderStyle} ${borderColor}`,
  };
};

export const getBoxShadowStyle = (dynamicalOptions) => {
  if (dynamicalOptions && typeof dynamicalOptions === "object") {
    const {
      isShadow = false,
      shadowX = 0,
      shadowY = 0,
      blur = 0,
      shadowColor = "#000000",
    } = dynamicalOptions;
    if (isShadow) {
      return {
        filter: `drop-shadow(${shadowX}px ${shadowY}px ${blur}px ${shadowColor})`,
      };
    }
  }

  return {};
};



export const getPopupPositionStyles = (position) => {

  switch (position) {
    case "center":
      return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        right: "auto",
        bottom: "auto",
      };
    case "top left":
      return {
        top: 0,
        left: 0,
        transform: "none",
        right: "auto",
        bottom: "auto",
      };
    case "top center":
      return {
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        right: "auto",
        bottom: "auto",
      };
    case "top right":
      return {
        top: '2%',
        right: '2%',
        transform: "none",
        left: "auto",
        bottom: "auto",
      };
    case "left center":
      return {
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
        right: "auto",
        bottom: "auto",
      };
    case "right center":
      return {
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        left: "auto",
        bottom: "auto",
      };
    case "bottom left":
      return {
        bottom: '2%',
        left: '1%',
        transform: "none",
        right: "auto",
        top: "auto",
      };
    case "bottom center":
      return {
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        right: "auto",
        top: "auto",
      };
    case "bottom right":
      return {
        bottom: 0,
        right: 0,
        transform: "none",
        left: "auto",
        top: "auto",
      };
    default:
      return {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        right: "auto",
        bottom: "auto",
      };
  }
};



export const getDraggablePopupPositionStyles = (dynamicalOptions, isMobile) => {


  const position = dynamicalOptions?.popupPosition?.[
    isMobile ? "mobile" : "desktop"
  ]

  switch (position) {
    case "center":
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    case "top left":
      return {
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
      };
    case "top center":
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
      };
    case "top right":
      return {
        display: "flex",
        justifyContent: "end",
        alignItems: "start",
      };
    case "left center":
      return {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      };
    case "right center":
      return {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      };
    case "bottom left":
      return {
        display: "flex",
        justifyContent: "start",
        alignItems: "end",
      };
    case "bottom center":
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
      };
    case "bottom right":
      return {
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
      };
    default:
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
  }
};


export const getOS = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Win") !== -1) return "Windows";
  if (userAgent.indexOf("Mac") !== -1) return "MacOS";
  if (userAgent.indexOf("X11") !== -1) return "UNIX";
  if (userAgent.indexOf("Linux") !== -1) return "Linux";
  if (userAgent.indexOf("Android") !== -1) return "Android";
  if (userAgent.indexOf("iPhone") !== -1 || userAgent.indexOf("iPad") !== -1) return "iOS";

  return "Unknown OS";
};

export const getBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("Edg/") !== -1) {
    return "Microsoft Edge";
  } else if (userAgent.indexOf("OPR/") !== -1 || userAgent.indexOf("Opera") !== -1) {
    return "Opera";
  } else if (userAgent.indexOf("Chrome") !== -1) {
    return "Google Chrome";
  } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1) {
    return "Safari";
  } else if (userAgent.indexOf("Firefox") !== -1) {
    return "Mozilla Firefox";
  } else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
    return "Internet Explorer";
  }

  return 'Unknown Browser';
};

export const analyticsEventTypes = {
  OPEN: "open",
  CLOSE: "close",
  INTERACTION: "interaction"
}



export const getAttentionAnimationStyle = (animation, position) => {
  const translateAdjustment = (() => {
    switch (position) {
      case "center":
        return "translate(-50%, -50%)";
      case "left center":
        return "translate(0%, -50%)";
      case "right center":
        return "translate(0%, -50%)";
      case "top center":
        return "translate(-50%, 0%)";
      case "bottom center":
        return "translate(-50%, 0%)";
      default:
        return "";
    }
  })();

  const attentionAnimationMappings = {
    "Bounce": keyframes`
      0%, 100% { transform:  ${translateAdjustment} translateY(0); }
      20% { transform:  ${translateAdjustment} translateY(-50px); }
      50% { transform:  ${translateAdjustment} translateY(0); }
      70% { transform:  ${translateAdjustment} translateY(-25px); }
      90% { transform:  ${translateAdjustment} translateY(0); }
    `,
    "Pulse": keyframes`
      0% { transform: ${translateAdjustment} scale(1); }
      50% { transform: ${translateAdjustment} scale(1.05); }
      100% { transform: ${translateAdjustment} scale(1); }
    `,
    "Rubber Band": keyframes`
      from { transform: ${translateAdjustment} scale3d(1, 1, 1); }
      30% { transform: ${translateAdjustment} scale3d(1.25, 0.75, 1); }
      40% { transform: ${translateAdjustment} scale3d(0.75, 1.25, 1); }
      50% {  transform: ${translateAdjustment} scale3d(1.15, 0.85, 1); }
      65% { transform: ${translateAdjustment} scale3d(.95, 1.05, 1); }
      75% { transform: ${translateAdjustment} scale3d(1.05, .95, 1); }
      to { transform: ${translateAdjustment} scale3d(1, 1, 1); }
    `,
    "Shake X": keyframes`
      10%, 90% { transform: ${translateAdjustment} translate3d(-4px, 0, 0); }
      20%, 80% { transform: ${translateAdjustment} translate3d(5px, 0, 0); }
      30%, 50%, 70% { transform: ${translateAdjustment} translate3d(-9px, 0, 0); }
      40%, 60% { transform: ${translateAdjustment} translate3d(9px, 0, 0); }
    `,
    "Shake Y": keyframes`
      10%, 90% { transform: ${translateAdjustment} translate3d(0, -4px, 0); }
      20%, 80% { transform: ${translateAdjustment} translate3d(0, 5px, 0); }
      30%, 50%, 70% { transform: ${translateAdjustment} translate3d(0, -9px, 0); }
      40%, 60% { transform: ${translateAdjustment} translate3d(0, 9px, 0); }
    `,
    "Swing": keyframes`
      20% { transform: ${translateAdjustment} rotate3d(0, 0, 1, 15deg); }
      40% { transform: ${translateAdjustment} rotate3d(0, 0, 1, -10deg); }
      60% { transform: ${translateAdjustment} rotate3d(0, 0, 1, 5deg); }
      80% { transform: ${translateAdjustment} rotate3d(0, 0, 1, -5deg); }
      100% { transform: ${translateAdjustment} rotate3d(0, 0, 1, 0); }
    `,
    "Jello": keyframes`
      11.1% { transform: ${translateAdjustment} none }
      22.2% { transform: ${translateAdjustment} skewX(-12.5deg) skewY(-12.5deg) }
      33.3% { transform: ${translateAdjustment} skewX(6.25deg) skewY(6.25deg) }
      44.4% { transform: ${translateAdjustment} skewX(-3.125deg) skewY(-3.125deg) }
      55.5% { transform: ${translateAdjustment} skewX(1.5625deg) skewY(1.5625deg) }
      66.6% { transform: ${translateAdjustment} skewX(-0.78125deg) skewY(-0.78125deg) }
      77.7% { transform: ${translateAdjustment} skewX(0.390625deg) skewY(0.390625deg) }
      88.8% { transform: ${translateAdjustment} skewX(-0.1953125deg) skewY(-0.1953125deg) }
      100% { transform: ${translateAdjustment} none }
    `,
    "Heart Beat": keyframes`
      0%,  { transform: ${translateAdjustment} scale(1); }
      15% { transform: ${translateAdjustment} scale(1.3); }
      30% { transform: ${translateAdjustment} scale(1); }
      45% { transform: ${translateAdjustment} scale(1.3); }
      75% { transform: ${translateAdjustment} scale(1); }
    `,
    "Float Top": keyframes`
      from { transform: ${translateAdjustment} translate(0, 0px); }
      65% { transform: ${translateAdjustment} translate(0, -15px); }
      to { transform: ${translateAdjustment} translate(0, 00px); } 
    `,
    "Float Bottom": keyframes`
      from { transform: ${translateAdjustment} translate(0, 0px); }
      65% { transform: ${translateAdjustment} translate(0, 15px); }
      to { transform: ${translateAdjustment} translate(0, -0px); } 
    `,
    "Head Shake": keyframes`
      0 { transform: ${translateAdjustment} translateX(0) }
      6.5% { transform: ${translateAdjustment} translateX(6px) rotateY(9deg) }
      16.5% { transform: ${translateAdjustment} translateX(-5px) rotateY(-7deg) }
      31.5% { transform: ${translateAdjustment} translateX(3px) rotateY(5deg) }
      43.5% { transform: ${translateAdjustment} translateX(-2px) rotateY(-3deg) }
      50% { transform: ${translateAdjustment} translateX(0) }
      `,
  };

  return (
    attentionAnimationMappings[animation] || attentionAnimationMappings[""]
  );
};


export const getDraggablePopupAttentionAnimationStyle = (animation) => {

  const attentionAnimationMappings = {
    "Bounce": keyframes`
      0%, 100% { transform: translateY(0); }
      20% { transform:   translateY(-50px); }
      50% { transform:  translateY(0); }
      70% { transform:   translateY(-25px); }
      90% { transform:   translateY(0); }
    `,
    "Pulse": keyframes`
      0% { transform:  scale(1); }
      50% { transform:  scale(1.05); }
      100% { transform:  scale(1); }
    `,
    "Rubber Band": keyframes`
      from { transform:  scale3d(1, 1, 1); }
      30% { transform:  scale3d(1.25, 0.75, 1); }
      40% { transform:  scale3d(0.75, 1.25, 1); }
      50% {  transform:  scale3d(1.15, 0.85, 1); }
      65% { transform:  scale3d(.95, 1.05, 1); }
      75% { transform:  scale3d(1.05, .95, 1); }
      to { transform:  scale3d(1, 1, 1); }
    `,
    "Shake X": keyframes`
      10%, 90% { transform:  translate3d(-4px, 0, 0); }
      20%, 80% { transform:  translate3d(5px, 0, 0); }
      30%, 50%, 70% { transform:  translate3d(-9px, 0, 0); }
      40%, 60% { transform:  translate3d(9px, 0, 0); }
    `,
    "Shake Y": keyframes`
      10%, 90% { transform:  translate3d(0, -4px, 0); }
      20%, 80% { transform:  translate3d(0, 5px, 0); }
      30%, 50%, 70% { transform:  translate3d(0, -9px, 0); }
      40%, 60% { transform:  translate3d(0, 9px, 0); }
    `,
    "Swing": keyframes`
      20% { transform:  rotate3d(0, 0, 1, 15deg); }
      40% { transform:  rotate3d(0, 0, 1, -10deg); }
      60% { transform:  rotate3d(0, 0, 1, 5deg); }
      80% { transform:  rotate3d(0, 0, 1, -5deg); }
      100% { transform:  rotate3d(0, 0, 1, 0); }
    `,
    "Jello": keyframes`
      11.1% { transform:  none }
      22.2% { transform:  skewX(-12.5deg) skewY(-12.5deg) }
      33.3% { transform:  skewX(6.25deg) skewY(6.25deg) }
      44.4% { transform:  skewX(-3.125deg) skewY(-3.125deg) }
      55.5% { transform:  skewX(1.5625deg) skewY(1.5625deg) }
      66.6% { transform:  skewX(-0.78125deg) skewY(-0.78125deg) }
      77.7% { transform:  skewX(0.390625deg) skewY(0.390625deg) }
      88.8% { transform:  skewX(-0.1953125deg) skewY(-0.1953125deg) }
      100% { transform:  none }
    `,
    "Heart Beat": keyframes`
      0%,  { transform:  scale(1); }
      15% { transform:  scale(1.3); }
      30% { transform:  scale(1); }
      45% { transform:  scale(1.3); }
      75% { transform:  scale(1); }
    `,
    "Float Top": keyframes`
      from { transform:  translate(0, 0px); }
      65% { transform:  translate(0, -15px); }
      to { transform:  translate(0, 00px); } 
    `,
    "Float Bottom": keyframes`
      from { transform:  translate(0, 0px); }
      65% { transform:  translate(0, 15px); }
      to { transform:  translate(0, -0px); } 
    `,
    "Head Shake": keyframes`
      0 { transform:  translateX(0) }
      6.5% { transform:  translateX(6px) rotateY(9deg) }
      16.5% { transform:  translateX(-5px) rotateY(-7deg) }
      31.5% { transform:  translateX(3px) rotateY(5deg) }
      43.5% { transform:  translateX(-2px) rotateY(-3deg) }
      50% { transform:  translateX(0) }
      `,
  };

  return (
    attentionAnimationMappings[animation] || attentionAnimationMappings[""]
  );
};


export const calculateProductCosts = (
  productPrice,
  discountType = null,
  discountPriceValue = 0,
  quantity = 1,
  shippingType = null,
  shippingRate = 0,
  chargePerUnit = false,
  popupValues = {}
) => {
  if (popupValues === undefined) {
    return;
  }

  // Input Validation (Optional)
  if (isNaN(discountPriceValue) || isNaN(quantity) || isNaN(shippingRate)) {
    console.log("Invalid input types.");
    return;
  }

  if (quantity <= 0) {
    console.log("Quantity must be a positive number.");
    return;
  }

  // Calculate Subtotal
  let discountedPrice = 0;

  if (discountType && discountType === "Percentage") {
    discountedPrice = Number(productPrice) * (Number(discountPriceValue) / 100);
  } else if (discountType) {
    discountedPrice = Number(discountPriceValue);  // Fixed discount
  }

  const subtotal = (Number(productPrice) - discountedPrice) * quantity;

  // Calculate Shipping Cost
  let shippingCost = 0;

  if (shippingType) {
    shippingRate = Number(shippingRate);
    switch (shippingType.toLowerCase()) {
      case "free":
        shippingCost = 0;
        break;
      case "charge for shipping":
        shippingCost = chargePerUnit ? shippingRate * quantity : shippingRate;
        break;
      default:
        console.log(`Invalid shipping value`);
    }
  }

  // Calculate Total Price
  const total = subtotal + shippingCost;
  const withoutDiscountPrice = Number(productPrice) * quantity;

  // Formatter
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Return Result Object
  return {
    productPrice: formatter.format(Number(productPrice)),
    discountedPrice: formatter.format(discountedPrice),
    subtotal: formatter.format(subtotal),
    shippingCost: formatter.format(shippingCost),
    total: formatter.format(total),
    withoutDiscountPrice: formatter.format(withoutDiscountPrice),
    freeProduct: discountType === "Percentage" && discountPriceValue >= 100,
  };
}

export const isValidUrl = (value) => {
  const urlPattern = new RegExp(
    '^(blob:)?(https?:\\/\\/)?' +
    '((([a-zA-Z0-9\\-])+\\.)+[a-zA-Z]{2,}|localhost)' +
    '(\\:[0-9]{1,5})?' +
    '(\\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?'
  );

  return urlPattern.test(value);
};


export const getBackgroundStyle = (customCSS) => {

  if (!customCSS?.backgroundImage) return customCSS;

  let background = customCSS.backgroundImage;
  background = Array.isArray(background) && background?.length >= 1
    ? pickRandomValueFromArray(background) : background

  if (background instanceof File) {
    background = URL.createObjectURL(background);
  }

  return {
    ...customCSS,
    opacity: customCSS?.backgroundOpacity != null ? customCSS.backgroundOpacity : 1,
    ...(isValidUrl(background) ? {
      backgroundColor: 'unset',
      backgroundImage: `url(${background})`
    } : {
      backgroundImage: 'unset',
      backgroundColor: background
    }),
    backgroundSize: customCSS?.objectFit,
    backgroundPosition: customCSS?.objectPosition,
    backgroundRepeat: 'no-repeat'
  }
};
export const isValidSelector = (selector) => {
  try {
    return typeof selector === "string" && selector.trim() !== "" && document.querySelector(selector) !== null;
  } catch {
    return false;
  }
};

export const getBlock = (popupData, activeStep, blockType) => popupData?.schema[activeStep]?.sections?.find((item) => item.type === blockType);

export const getAiBtnImgPosition = (popupData, activeStep) => ({
  position: "fixed",
  bottom: "-15px",
  left: "-15px",
  ...(popupData?.schema[activeStep]?.popupSettings?.btnPositionCss?.bottom && { top: "-15px" })
})

export const sentPostMessage = (type, message) => {
  window.parent.postMessage({ type, message }, "*");
}


export const removePx = (value) => {
  if (typeof value === 'string' && value.endsWith('px')) {
    return parseInt(value, 10);
  }
  return value;
};

export const addPx = (value) => {
  if (typeof value === 'number') {
    return `${value}px`;
  }
  if (typeof value === 'string' && !value.endsWith('px')) {
    return `${value}px`;
  }
  return value;
};


export const getDraggablePopupAnimationAndShadowStyles = (dynamicalOptions) => {

  const { animationDelay, animationSpeed, animationRepeat } = dynamicalOptions || {};

  return {
    ...getBoxShadowStyle(dynamicalOptions),
    animation: `${getDraggablePopupAnimationStyle(dynamicalOptions?.animation)} 0.5s ease-out, ${getDraggablePopupAttentionAnimationStyle(dynamicalOptions?.attentionAnimation)} ${animationSpeed}s ease-out ${animationDelay}s ${animationRepeat}`,
  }
};

export const invertColor = (color) => {
  const rgb = color.match(/\d+/g).map(Number);
  const inverted = rgb.map((value) => 255 - value);
  return `rgb(${inverted.join(", ")})`;
};



export const getDraggablePopupBackgroundStyle = (popupSettings, isMobile) => {

  if (!popupSettings?.src) return {};

  const background = pickRandomValueFromArray(popupSettings?.src?.images);

  if (background instanceof File) {
    background = URL.createObjectURL(background);
  }

  return {
    ...(isValidUrl(background) ? {
      backgroundColor: 'unset',
      backgroundImage: `url(${background})`
    } : {
      backgroundImage: 'unset',
      backgroundColor: background
    }),
    backgroundSize: popupSettings?.customCSS?.[isMobile ? "mobile" : "desktop"]?.objectFit,
    backgroundPosition: popupSettings?.customCSS?.[isMobile ? "mobile" : "desktop"]?.objectPosition,
    backgroundRepeat: 'no-repeat'
  }
};


export const validateBirthdate = (value) => {
  const { day, month, year } = value || {};
  if (!day || !month || !year) return "All fields (day, month, year) are required";

  const date = new Date(year, month - 1, day);
  if (date.getDate() !== day || date.getMonth() !== month - 1 || date.getFullYear() !== year) {
    return "Invalid birth date";  // Specific error message for invalid date
  }
  return true;  // Valid date
};


export const getRandomId = () => `input-${Math.random().toString(36).substr(2, 9)}`;
