import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import useCountdownTimer from "../../hooks/useCountdownTimer";
// import { calculatediffrence } from "../../helper";

const TimerPopUp = ({ section, date }) => {

  const timeLeft = useCountdownTimer(date);
  const boxStyle = {
    ...section?.setting.customCSS,
  };

  // const [timeLeft, setTimeLeft] = useState(null);


  // function calculateRemainingTime() {
  //   const eventDate = new Date(date);
  //   const currentDate = new Date();
  //   const difference = eventDate - currentDate;
  //   return calculatediffrence(difference);
  // }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTimeLeft(calculateRemainingTime());
  //   }, 1000);
  //   return () => clearInterval(timer);
  // }, [timeLeft]);

  return (
    <Grid sx={{ marginTop: "5px", display: "flex", justifyContent: "center" }}>
      {typeof timeLeft === "object" && timeLeft !== null ? (
        Object.keys(timeLeft).length ? (
          Object.keys(timeLeft).map((interVal, index) => (
            <Box sx={boxStyle} key={index}>
              {timeLeft[interVal]}
              {interVal[0]}
            </Box>
          ))
        ) : (
          <Box sx={boxStyle}>Time's Up</Box>
        )
      ) : (
        <Box sx={boxStyle}>Calculating time...</Box>
      )}
    </Grid>
  );
};

export default TimerPopUp;
