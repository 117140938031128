import { useState, useEffect } from 'react';
import { calculatediffrence } from '../helper';

function calculateRemainingTime(endDate) {
  const eventDate = new Date(endDate);
  const currentDate = new Date();
  const difference = eventDate - currentDate;
  return calculatediffrence(difference);
}

function useCountdownTimer(endDate) {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateRemainingTime(endDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  return timeLeft;
}

export default useCountdownTimer;
