import React from "react";
import BadgeImage from "./BadgeImage";

const badgeContainerStyle = { position: 'absolute', top: "-14px", right: "0px", display: 'flex', justifyContent: "center", alignItems: "center", color: "#fff", zIndex: 1, width: "auto" }
const badgeTitleStyle = { backgroundColor: "transparent" }

const BadgeBlock = ({ section }) => {
    const { customCSS } = section?.setting || {};
    const overrideCss = section?.setting?.overrideCss || {};

    return (
        <div style={{ ...badgeContainerStyle, ...overrideCss }}>
            <BadgeImage color={customCSS?.backgroundColor} />
            <p style={{ ...customCSS, ...badgeTitleStyle, ...overrideCss }}>{section?.title || ""}</p>
        </div>
    );
}

export default BadgeBlock
