import React, { useMemo, useState } from "react";
// import ImageBlock from '../ImageBlock/ImageBlock'
import StarIcon from "@mui/icons-material/Star";
import "./style.css";
import { useApp } from "../../context/AppState";
import trending from "../../assets/trending.png";
import vector from "../../assets/Vector.png";
import AiIcon from "../../assets/gimini-icon.png";
import QuantityBox from "../Preview/QuantityBox";
import styles from "../Preview/style.module.css";
import AIImageSlider from "../AICarousel";
import { POPUP_SECTIONS } from "../../constant/constant";
import cartService from "../../services/cart.service";
import PriceBlock from "../Preview/Upsell/PriceBlock";
import TitleBlock from "../Preview/Upsell/TitleBlock";
import { useAction } from "../../hooks/useAction";
import ButtonBlock from "../Preview/ButtonBlock";
import { useAnalytics } from "../../hooks/useAnalytics";
import { analyticsEventTypes, calculateProductCosts, getBackgroundStyle } from "../../helper";
import ProductDescription from "../ProductDescription";

const backgroundStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 0
};

const ProductDetails = ({
  popupData,
  setPopupState,
  activeStep,
  quantity,
  setQuantity,
  addingProduct,
  setAddingProduct,
  productData,
  setPopupData,
  product,
  productImages,
}) => {
  const { isMobile, isSmallMobile } = useApp();
  const { addAnalyticsEvent } = useAnalytics();
  const { actionHandler } = useAction();
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);

  const blockType = (type) =>
    popupData?.schema[activeStep]?.sections?.find(
      (item) => item.type === type
    );

  function getProductDetails(product) {
    const prod = product;
    const {
      selected_variant = null,
      title,
      variants = [],
      images = [],
    } = prod || {};
    // Find the selected variant object
    const selectedVariant = variants.find(
      (variant) => variant.id === selected_variant
    );
    if (selectedVariant) {
      const selectedImage = images.find((image) =>
        image.variant_ids.includes(selectedVariant.id)
      );
      return {
        title: selectedVariant.title || title, // Use variant title if available, otherwise fallback to product title
        price: selectedVariant.price,
        img: selectedImage?.src || productData?.product?.image?.src, // Return image URL if found, otherwise null
      };
    }
    return {
      title,
      price:
        productData?.product?.selected_variant !== undefined
          ? variants?.[0]?.price
          : productData?.product?.variants?.[0]?.price || null, // Use first variant price if available, otherwise null
      img:
        productData?.product?.selected_variant !== undefined
          ? images?.[0]?.src
          : productData?.product?.image?.src || null, // Use first image URL if available, otherwise null
    };
  }

  const getvariantsData = getProductDetails(productData?.product);

  function getVariantImages(data) {
    const images = [];
    const seenImageIds = new Set();

    images.push(data?.image?.src);
    seenImageIds.add(data?.image?.id); // Mark first image ID as seen

    if (data?.images?.length > 1) {
      for (const variant of data?.variants) {
        const matchingImage = data?.images.find(
          (image) =>
            image?.variant_ids?.includes(variant.id) &&
            !seenImageIds.has(image.id)
        );
        if (matchingImage) {
          images.push(matchingImage.src);
          seenImageIds.add(matchingImage.id);
        }
      }
    }

    return images;
  }

  const variantImages = getVariantImages(productImages);

  const productCostsPercentageDiscount = calculateProductCosts(
    getvariantsData?.price,
    blockType(POPUP_SECTIONS.DISCOUNT_TYPE)?.text,
    blockType(POPUP_SECTIONS.DISCOUNT_VALUE)?.value,
    productData?.quantity || blockType(POPUP_SECTIONS.QUANTITY)?.value,
    blockType(POPUP_SECTIONS.SHIPPING)?.text,
    blockType(POPUP_SECTIONS.SHIPPING_RATE)?.value,
    blockType(POPUP_SECTIONS.CHARGE_PER_UNIT)?.text,
    popupData
  );

  const handleClose = (e, backdropClick) => {
    if (!backdropClick) {
      setPopupState((prev) => ({
        ...prev,
        [`${popupType}_${popupId}`]: { activeStep: 0, openPopup: false },
      }));
    }
  };

  /// logic for click on pay noe button
  const clickOnPayNow = async () => {
    try {
      setAddingProduct(true);
      let items = [
        {
          id:
            productData?.product?.selected_variant ||
            productData?.product?.variants[0]?.id,
          quantity: productData?.quantity,
        },
      ];

      const result = await cartService.addToCart(items);

      if (result) {
        await addAnalyticsEvent({
          eventType: analyticsEventTypes.INTERACTION,
          popupType: popupData?.popUpType,
          popupId: popupData?._id,
          ...(popupData?.campaign_Id && {
            campaignId: popupData?.campaign_Id,
          }),
        });

        handleClose();
      }

      setAddingProduct(false);
    } catch (err) {
      setAddingProduct(false);
      console.log(err);
    }
  };

  const filtered_variant = productData?.product?.variants?.filter(
    (item) => item.id === productData?.product?.selected_variant
  );

  const [variantTitle, setVariantTitle] = useState(
    filtered_variant?.[0]?.title || productData?.product?.variants?.[0]?.title
  );

  const handleIncrement = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      setPopupData((prev) => ({
        ...prev,
        quantity: newQuantity,
      }));
      return newQuantity;
    });
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.max(prevQuantity - 1, 1);
      setPopupData((prev) => ({
        ...prev,
        quantity: newQuantity,
      }));
      return newQuantity;
    });
  };

  const handleOnChange = (event) => {
    setQuantity(1);
    setVariantTitle(event.target.value);
    const newPop = { ...productData };
    const filteredProduct = newPop?.product?.variants?.filter(
      (item) => item.title === event.target.value
    );
    setPopupData({
      ...productData,
      product: {
        ...productData?.product,
        selected_variant: filteredProduct[0].id,
      },
      quantity: 1,
    });
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: `calc(-5% - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
          top: `calc(-10% - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
          width: "100px",
          display: "flex",
          zIndex: 999999,
          ...(isMobile && {
            width: "75px",
            left: `calc(-6% - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
            top: `calc(-7% - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
          }),
        }}
      >
        {blockType(POPUP_SECTIONS.BADGE_1)?.text === true && (
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img
              src={vector}
              alt="Trending"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />

            <div
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                textAlign: "center",
              }}
            >
              <img
                src={AiIcon}
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
              <span
                style={{
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  zIndex: 100000,
                  fontStyle: "italic",
                }}
              >
                {blockType(POPUP_SECTIONS.BADGE_1)?.title}
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          right: `calc(35px - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
          top: `calc(-5px - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
          width: "200px",
          display: "flex",
          zIndex: 999999,
          ...(isMobile && {
            right: `calc(20px - ${popupData?.schema[activeStep]?.popupSettings?.dynamicalOptions?.border}px)`,
          }),
        }}
      >
        {blockType(POPUP_SECTIONS.BADGE_2)?.text === true && (
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img
              src={trending}
              alt="Trending"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: "0px",
                right: "30px",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "14px",
                fontWeight: "bold",
                zIndex: 1,
              }}
            >
              {blockType(POPUP_SECTIONS.BADGE_2)?.title}
            </span>
          </div>
        )}
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "3px",
          marginBottom: "4px",
        }}
      >
        <img
          src={AiIcon}
          alt=""
          style={{ paddingTop: "10px", width: "20px", height: "20px" }}
        />
        <div className="byai">Present by AI</div>
      </div> */}
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          ...(isMobile && {
            flexDirection: "column",
            overflowY: "auto",
          }),
          position: "relative",
          zIndex: 1
        }}
      >
        <div
          style={{
            width: "50%",
            ...(isMobile && {
              width: "100%",
            }),
          }}
        >
          <div
            className={
              isMobile
                ? product && variantImages.length <= 1 
                  ? styles.mobile_preview_1
                  : styles.mobile_preview
                : product && variantImages.length <= 1
                ? "preview_left_section"
                : ""
            }
            style={
              variantImages.length > 1
                ? {
                    width: isMobile ? "100%" : "345px",
                    marginTop: isMobile ? "0px" : "20px",
                  }
                : null
            }
          >
            {product && variantImages.length <= 1 && (
              <img
                src={
                  product?.image?.src ||
                  "https://img2.ogaanindia.com/pub/media/catalog/product/cache/3f6619daccdb194398d06464ab49fa6e/s/h/sht03mr279_1.jpg"
                }
                alt="preview Image"
                style={{ width: isMobile ? "fit-content" : "100%", height: isMobile ? "200px" : "100%"}}
              />
            )}

            {variantImages.length > 1 && (
              <AIImageSlider
                img={variantImages}
                activesrc={getvariantsData?.img}
                mobileview={isMobile}
              />
            )}
          </div>
        </div>

        <div
          style={{
            width: "50%",
            padding: "25px 10px",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            ...(isMobile && {
              width: "100%",
              padding: "10px",
            }),
          }}
        >
          <div style={{ flex: "1" }}>
            <div
              style={{
                width: "100%",
              }}
            >
              <div className={`cliamedtext ${isSmallMobile ? 'mobile-cliamedtext' : ''}`}>98% Claimed</div>
              <TitleBlock
                product={product}
                section={blockType(POPUP_SECTIONS.TITLE)}
              />
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {blockType(POPUP_SECTIONS.STAR)?.star?.is_star && (
                  <div style={{ marginLeft: "-2px" }}>
                    {Array.from({
                      length: blockType(POPUP_SECTIONS.STAR).star.text,
                    }).map((_, index) => (
                      <StarIcon
                        key={index}
                        sx={{ color: "#FCD34D", fontSize: "18px" }}
                      />
                    ))}
                  </div>
                )}
              </div>
              <PriceBlock
                savingLabel={blockType(POPUP_SECTIONS.SAVINGLABLE)?.text}
                productCosts={productCostsPercentageDiscount}
                discountType={blockType(POPUP_SECTIONS.DISCOUNT_TYPE)?.text}
                discountvalue={blockType(POPUP_SECTIONS.DISCOUNT_VALUE)?.value}
                isdiscount={blockType(POPUP_SECTIONS.GIVE_DISCOUNT)?.text}
              />
            </div>

            <div className="bottomLine" style={{ marginTop: "8px" }}></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "10px 0px 10px 0px",
                  gap: "10px",
                }}
              >

              <div style={{ width: "30%" }}>
                <div className="select_variant">Quantity</div>
                  <QuantityBox
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    quantity={quantity}
                  />
                </div>

                {productData?.product?.variants?.length > 1 && (
                  <div style={{ width: "100%" }} className="select_variant">
                    select variant
                    <div
                      style={{
                        width: "100%",
                        paddingTop: "5px",
                      }}
                    >
                      <select
                        name="title"
                        size="small"
                        value={
                          productData?.product?.selected_variant !== undefined
                            ? variantTitle
                            : productData?.product?.variants[0]?.title
                        }
                        fullWidth
                        style={{
                          height: "30px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                        onChange={(e) => actionHandler(() => handleOnChange(e))}
                      >
                        {productData?.product?.variants?.map((option) => (
                          <option key={option.title} value={option.title}>
                            {option.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            
              {!isMobile && (
              <ProductDescription
               productData={productData}
              />
            )}


          </div>


          
          
          <div>
            <ButtonBlock
              section={blockType(POPUP_SECTIONS.BUTTON)}
              onClick={() => actionHandler(clickOnPayNow)}
              isLoading={addingProduct}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
