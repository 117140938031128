import React from "react";

import DoneIcon from '@mui/icons-material/Done';
// mui
import StarIcon from '@mui/icons-material/Star';
import './style.css'
import { useApp } from "../../context/AppState";

export default function SellOutVerificationCard({ title, section }) {
  const { isMobile, isSmallMobile } = useApp();
  return (
    <div className="verificationCardContainer" style={{
      ...(
        isMobile && {
          justifyContent: "space-between",
        }
      )
    }}>
      {!isSmallMobile && <div className="satisfied">
        99% customer's are satisfied with
      </div>}
      <div className="product">
        {title}
      </div>
      {section?.star?.is_star && (
        <div>
          {Array.from({ length: section?.star?.text }).map((_, index) => (
            <StarIcon key={index} sx={{ color: "#FCD34D", fontSize: '18px' }} />
          ))}
        </div>
      )}
      <div className="verification">
        <DoneIcon />
        <div className="purchase">Verified Purchase</div>
      </div>
    </div>
  );
}
