import React from "react";
import ButtonBlock from "../Preview/ButtonBlock";
import InputBlock from "../Preview/InputBlock";
import CouponCodeBlock from "../Preview/CouponCode";
import ParagraphBlock from "../Preview/ParagraphBlock";
import { POPUP_SECTIONS } from "../../constant/constant";
import CoverImageBlock from "./CoverImageBlock";
import TitleBlock from "../Preview/TitleBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import NoCouponCode from "./NoCouponCode";
import ProductTitlleBlock from "../Preview/ProductTitle";
import BirthDayBlock from "../Preview/BirthDayBlock";
import CouponCodeSummaryBlock from "../Preview/CouponCodeSummaryBlock";
import { getBackgroundStyle } from "../../helper";
import TimerPopUp from "../CountDownTimerPopup/Timer";
import TimerBlock from "../Preview/Draggable/Block/TimerBlock";

const cardContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  position: "relative",
  zIndex: 1
};

const cardContent = {
  position: 'relative',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: "20px",
}

const backgroundStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 0
};

const WelcomeVerticalCard = ({
  popupData,
  activeStep,
  onClick,
  handleOnEmailChange,
  email,
  emailError,
  isEmailExist,
  setBirthday,
  birthday,
  isEmptyField
}) => {

  if ((!popupData?.generalSettings?.discount_code || popupData?.generalSettings?.discount_code === "undefined") && activeStep) {
    return (
      <div style={{
        display: "flex", height: "100%", width: "100%", flexDirection: "column",
        justifyContent: 'space-between'
      }}>
        {popupData?.schema[activeStep]?.sections?.map((section, index) =>
          section?.type === POPUP_SECTIONS.COVER_IMAGE ? (
            <CoverImageBlock section={section} key={index} />
          ) : null
        )}
        <NoCouponCode key="no-coupon" email={email} />
      </div>
    )
  }

  const blockType = (type) =>
    popupData?.schema[activeStep]?.sections?.find(
      (item) => item.type === type
    );



  const renderSections = popupData?.schema[activeStep]?.sections?.map(
    (section, index) => {
      
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.SUBTITLE:
          return <SubTitleBlock section={section} key={index} />;
        case POPUP_SECTIONS.COVER_IMAGE:
          return <CoverImageBlock section={section} key={index} />;
        case POPUP_SECTIONS.PARAGRAPH:
          return <ParagraphBlock section={section} key={index} />;
        case POPUP_SECTIONS.PRODUCT_TITLE:
          return <ProductTitlleBlock section={section} key={index} />;
        case POPUP_SECTIONS.TIMER:
          return (
            popupData?.generalSettings?.event_endtime && (
              <TimerPopUp section={section} date={popupData?.generalSettings?.event_endtime} key={index} />
            )
          );
        case POPUP_SECTIONS.BIRTHDAY:
          return section?.text && <BirthDayBlock section={section} key={index} birthday={birthday}
            setBirthday={setBirthday}
            isEmptyField={isEmptyField}
          />;
        case POPUP_SECTIONS.CODE:
          return (
            <CouponCodeBlock
              section={section}
              generalSettings={popupData?.generalSettings}
              key={index}
            />
          );
        case POPUP_SECTIONS.CODE_SUMMARY:
          return <CouponCodeSummaryBlock section={section} key={index} />;
        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              section={section}
              onClick={onClick}
              disabled={email === ""}
              key={index}
              emailError={emailError}
            />
          );
        case POPUP_SECTIONS.INPUT:
          return (
            <InputBlock
              section={section}
              onChange={handleOnEmailChange}
              email={email}
              emailError={emailError}
              key={index}
              isEmailExist={isEmailExist}
              isEmptyField={isEmptyField}
            />);
        case POPUP_SECTIONS.CODE:
          return (
            <CouponCodeBlock
              section={section}
              generalSettings={popupData?.generalSettings}
              key={index}
            />
          );
        case POPUP_SECTIONS.CODE_SUMMARY:
          return <CouponCodeSummaryBlock section={section} key={index} />;
        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              section={section}
              onClick={onClick}
              disabled={email === ""}
              key={index}
              emailError={emailError}
            />
          );
        case POPUP_SECTIONS.INPUT:
          return (
            <InputBlock
              section={section}
              onChange={handleOnEmailChange}
              email={email}
              emailError={emailError}
              key={index}
              isEmailExist={isEmailExist}
              isEmptyField={isEmptyField}
            />
          );
        default:
          return null;
      }
    }
  );


  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: 'auto',
      }}
    >
      <div
      className="background"
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer} >
        {activeStep === 0 ?
          <div style={cardContent}>
            <TitleBlock section={blockType(POPUP_SECTIONS.TITLE)} />
            <SubTitleBlock section={blockType(POPUP_SECTIONS.SUBTITLE)} />
            <CoverImageBlock section={blockType(POPUP_SECTIONS.COVER_IMAGE)} />
            <ParagraphBlock section={blockType(POPUP_SECTIONS.PARAGRAPH)} />

            {popupData?.generalSettings?.event_endtime && <TimerPopUp section={blockType(POPUP_SECTIONS.TIMER)} date={popupData?.generalSettings?.event_endtime} />}
            <ProductTitlleBlock section={blockType(POPUP_SECTIONS.PRODUCT_TITLE)} />
            {blockType(POPUP_SECTIONS.BIRTHDAY)?.text && <BirthDayBlock section={blockType(POPUP_SECTIONS.BIRTHDAY)} birthday={birthday}
              setBirthday={setBirthday}
              isEmptyField={isEmptyField}
            />}
            <InputBlock
              section={blockType(POPUP_SECTIONS.INPUT)}
              onChange={handleOnEmailChange}
              email={email}
              emailError={emailError}
              isEmailExist={isEmailExist}
              isEmptyField={isEmptyField}
            />
            <ButtonBlock
              section={blockType(POPUP_SECTIONS.BUTTON)}
              onClick={onClick}
              disabled={email === ""}
              emailError={emailError}
            />
            <TimerBlock/> 
          </div>
          :
          <div style={cardContent}>
            {renderSections}
          </div>
        }
      </div>
    </div>
  );
};
export default WelcomeVerticalCard;