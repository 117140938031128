import React from "react";
import { useApp } from "../../context/AppState";
import { POPUP_SECTIONS } from "../../constant/constant";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SocialProofTitle from "../Preview/SocialProofTitle";
import { useAction } from "../../hooks/useAction";
import { getBackgroundStyle } from "../../helper";

const SocialProofPreviewCard = ({ popupData, activeStep, handleClose, product, handelOnclick }) => {
  const storeName = (window?.Shopify?.shop || window.location.hostname).replace('.myshopify.com', '');
  const { actionHandler } = useAction()
  const { isMobile, isSmallMobile } = useApp();

  const cardContainer = {
    display: "flex",
    wordBreak: "break-word",
    flexDirection: "row",
    gap: "10px",
    width: '100%',
    height: '100%',
    alignItems: "center",
    ...(isMobile && {
      width: 'auto',
      overflowY: 'auto',
    }),
    position: "relative",
    zIndex: 1,
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: "100%",
    ...(isMobile && {
      justifyContent: "space-between",
    })
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0, 
  };


  const replacePlaceholders = (text, values) => {
    const placeholderRegex = /{{\s*(\w+)\s*}}/g;
    return text.replace(placeholderRegex, (match, key) => {
      return values[key] || match;
    });
  };

  const value = {
    shop_name: storeName || 'Aimbrill',
  };

  const sections = popupData?.schema[activeStep]?.sections || [];
  const renderSections = sections.map((section, index) => {
    switch (section?.type) {
      case POPUP_SECTIONS.TITLE:
        return <SocialProofTitle section={section} key={index} product={product} />;
      case POPUP_SECTIONS.SUBTITLE:
        return !isSmallMobile && <SocialProofTitle section={section} key={index} product={product} />;
      case POPUP_SECTIONS.VERIFICATION: {
        const text = isMobile ? "Verified" : section?.verificationText ? replacePlaceholders(section.verificationText, value) : "Verified by, Aimbrill";
        if (section?.text) {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                marginTop: "5px"
              }}
            >
              <CheckCircleRoundedIcon color="primary" sx={{ width: "15px" }} />
              {<span style={{ color: "#0462ed", fontSize: '14px', fontFamily: "Trebuchet MS" }}>
                {text}
              </span>}
            </div>
          );
        }
        break;
      }

      default:
        return null;
    }
  });

  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings
          ?.customCSS,
        width: "100%",
        height: "100%",
      }}
      onClick={() => actionHandler(handelOnclick)}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        <img
          src={product?.src || 'https://www.svgrepo.com/show/508699/landscape-placeholder.svg'}
          alt="product image"
          style={{ maxWidth:"40%", height: "100%", position: "sticky", top: 0, borderRadius: '20px' }}
        />
        <div style={cardContent}>{renderSections}</div>
      </div>
    </div>
  );
};

export default SocialProofPreviewCard;
