import React, { useState } from 'react';
import trendlable from "../../assets/trendlabel.png";
import PriceBlock from '../PriceBlock';
import { useApp } from '../../context/AppState';
import ButtonBlock from '../Preview/ButtonBlock';
import { analyticsEventTypes, getBlock } from '../../helper';
import { POPUP_SECTIONS } from '../../constant/constant';
import VariantSelectBlock from '../VarientBlock';
import QuantitySelectBlock from '../QuantityBlock';
import cartService from '../../services/cart.service';
import { useAnalytics } from '../../hooks/useAnalytics';
import useActionHandler from "../../hooks/useActionHandler";
import BadgeBlock from '../BadgeBlock';

function calculateDiscountedPrice(data, selectedVariant, quantity) {
    const currentVariant = selectedVariant ?? data?.product?.variants[0];

    const variantPrice = parseFloat(currentVariant?.price);

    const discountPercentage = parseFloat(data?.discount_percentage);
    const quantityForDiscount = Number(data?.quantity);

    const originalTotalPrice = variantPrice * quantity;

    const discountedTotalPrice = quantity >= quantityForDiscount ? discountPercentage > 0
        ? originalTotalPrice * (1 - discountPercentage / 100)
        : originalTotalPrice : originalTotalPrice;

    const isFreeProduct = discountedTotalPrice <= 0;

    return {
        withoutDiscountPrice: originalTotalPrice.toFixed(2),
        subtotal: discountedTotalPrice.toFixed(2),
        freeProduct: isFreeProduct,
    };
}

const MultiBoardProduct = ({ popupData, setPopupState, activeStep, data, popupState }) => {
    const { isSmallMobile } = useApp();
    const { addAnalyticsEvent } = useAnalytics();
    const { handleClose } = useActionHandler(popupData, setPopupState, {}, popupState);

    const [selectedVariant, setSelectedVariant] = useState({});
    const [addToCartLoading, setAddToCartLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const product = data?.product;
    const priceDetails = calculateDiscountedPrice(data, selectedVariant, quantity);

    const handleVariantChange = (product, variant) => setSelectedVariant(variant);

    // const handleQuantityIncrement = () => setQuantity(prev => prev + 1);
    // const handleQuantityDecrement = () => setQuantity(prev => (prev > 1 ? prev - 1 : prev));



    const handleSubmitCart = async () => {
        setAddToCartLoading(true)
        try {
            let items = [
                {
                    id: selectedVariant?.id,
                    quantity,
                },
            ];
            
            const response = await cartService.addToCart(items);

            if (response?.success) {
                await addAnalyticsEvent({
                    eventType: analyticsEventTypes.INTERACTION,
                    popupType: popupData?.popUpType,
                    popupId: popupData?._id,
                    ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
                });
                setAddToCartLoading(false)
                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCheckout = async () => {
        setCheckoutLoading(true)

        try {
            let items = [
                {
                    id: selectedVariant?.id,
                    quantity,
                },
            ];

            const response = await cartService.getCheckoutUrl(items);

            if (response?.success) {
                await addAnalyticsEvent({
                    eventType: analyticsEventTypes.INTERACTION,
                    popupType: popupData?.popUpType,
                    popupId: popupData?._id,
                    ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
                });

                window.location.href = response?.data?.data?.cart?.checkoutUrl;

                setCheckoutLoading(false)
                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div key={product?.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between", alignItems: "center", gap: '10px', width: '100%', margin: '10px 0 0 0', position: "relative", background: '#F5F5F5', padding: "50px 0 10px 0" }}>

            {data?.mostTrending && (
               <BadgeBlock section={getBlock(popupData, activeStep, POPUP_SECTIONS.BADGE_1)} />
            )}

            {/* Title section */}
            {data?.title && <div style={{ position: 'absolute', top: "0px", display: 'flex', justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", background: '#000', borderRadius: '7px 7px 0 0', width: "100%" }}>
                <p style={{ color: "#fff", fontWeight: '600', fontSize: '12px', padding: "0px 10px" }}>
                    {data?.title}
                </p>
            </div>}

            {/* Rest of the component */}
            <div style={{ display: 'grid', justifyContent: "center", gap: '10px', justifyItems: 'center', width: '100%' }}>
                <img
                    src={product?.images?.[0]?.src}
                    alt={product?.title}
                    style={{ borderRadius: '5px', height: "170px" }} // Set height to 40%
                />
                <div style={{ display: 'grid', justifyContent: "center", gap: '5px', justifyItems: 'center', width: '100%' }}>
                    <p style={{ margin: '0', fontWeight: '700', fontSize: isSmallMobile ? '12px' : '16px' }}>
                        {data.offerTitle}
                    </p>
                    {priceDetails &&
                        <PriceBlock
                            productCosts={priceDetails}
                            discountType={data.discount_percentage > 0 ? "Percentage" : null}
                            discountvalue={data.discount_percentage}
                            isdiscount={data.discount_percentage > 0}
                            freeProduct={priceDetails.freeProduct}
                        />}
                </div>
                <div style={{ display: 'grid', justifyContent: "center", justifyItems: 'center', width: '100%' }}>
                    <p style={{
                        margin: '0', fontSize: isSmallMobile ? '10px' : '12px',
                        maxWidth: "80%",
                        display: '-webkit-box',
                        textAlign: 'center',
                        WebkitLineClamp: 5,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {data.description}
                    </p>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: "column", gap: '10px', width: '80%' }}>
                {/* <QuantitySelectBlock quantity={quantity} handleQuantityIncrement={handleQuantityIncrement} handleQuantityDecrement={handleQuantityDecrement} /> */}
                <VariantSelectBlock section={getBlock(popupData, activeStep, POPUP_SECTIONS.OFFER_PRODUCT)}  selectedVariant={selectedVariant} product={product} handleChange={handleVariantChange} />
                <ButtonBlock section={getBlock(popupData, activeStep, POPUP_SECTIONS.BUTTON)} onClick={handleSubmitCart} isLoading={addToCartLoading} />
                <ButtonBlock section={getBlock(popupData, activeStep, POPUP_SECTIONS.BUTTON1)} onClick={handleCheckout} isLoading={checkoutLoading} />
            </div>
        </div>

    );
}

export default MultiBoardProduct
