import React, { memo } from "react";

// PropTypes
import PropTypes from "prop-types";

// mui
import { Stack, Typography } from "@mui/material";
import { parseCssStringToObject, pickRandomValueFromArray } from "../../helper";
import { useAction } from "../../hooks/useAction";

const SubTitleBlock = ({ section }) => {
  const customCSS = section?.setting?.customCSS || {};

  const gradient = section?.setting?.overrideCss || '';
  const { actionHandler } = useAction();
  const css = parseCssStringToObject(gradient);

  const winnervalue = localStorage.getItem("Winner");
  return (
    <Typography
      variant="subtitle1"
      color="text.secondary"
      sx={{ lineHeight: "1.35rem", ...customCSS, ...css}}
      
      onClick={()=> actionHandler(()=>{}, section)}
    >
      <Stack>
        {section.type === "CODE"
          ? winnervalue
          : section?.AIText?.length > 0 && Array.isArray(section?.AIText)
          ? pickRandomValueFromArray(section?.AIText)?.text
          : section?.text}
      </Stack>
    </Typography>
  );
};

SubTitleBlock.propTypes = {
  section: PropTypes.object,
};

export default memo(SubTitleBlock);
