import React, { memo, useMemo, useState } from "react";
import PopUpModel from "../../components/PopUpModel";
import cartService from "../../services/cart.service";
import { useAnalytics } from "../../hooks/useAnalytics";
import { analyticsEventTypes } from "../../helper";
import VolumeDiscountCard from "../../components/VolumeDiscountPopup/VolumeDiscountCard";
import useActionHandler from "../../hooks/useActionHandler";

const RenderVolumePopup = ({ popupData, popupState, setPopupState }) => {
  const { addAnalyticsEvent } = useAnalytics();
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { handleClose } = useActionHandler(popupData, setPopupState, {}, popupState);

  const handleProductChange = (_, item) => {
    if (selectedProduct === item) {
      setSelectedProduct(null);
    } else {
      setSelectedProduct(item);
    }
  };

  const handleSubmitCart = async () => {
    try {
      let items = [
        {
          id: selectedProduct?.product?.variants?.[0]?.id,
          quantity: 1,
        },
      ];

      const result = await cartService.addToCart(items);
      if (result) {
        await addAnalyticsEvent({
          eventType: analyticsEventTypes.INTERACTION,
          popupType: popupData?.popUpType,
          popupId: popupData?._id,
          ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
        });

        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {popupState.openPopup ? (
        <PopUpModel
          open={popupState.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
        >
          <VolumeDiscountCard
            popupData={popupData}
            activeStep={popupState.activeStep}
            handleProductChange={handleProductChange}
            selectedProduct={selectedProduct}
            handleSubmitCart={handleSubmitCart}
          />
        </PopUpModel>
      ) : null}
    </>
  );
};

export default memo(RenderVolumePopup);
