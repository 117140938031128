import React, {memo} from "react";

// propTypes
import PropTypes from "prop-types";

// mui
import { Typography } from "@mui/material";
import { parseCssStringToObject, pickRandomValueFromArray } from "../../helper";
import { useAction } from "../../hooks/useAction";

const TitleBlock = ({ section }) => {
  const customCSS = section?.setting?.customCSS || {};

  const gradient = section?.setting?.overrideCss || '';
  const { actionHandler } = useAction();

  const css = parseCssStringToObject(gradient);

  return (
    <Typography
      textAlign="center"
      component="h1"  
      variant="h5" 
      sx={{
        ...customCSS,  
        ...css, 
      }}
    >
      {section?.AIText && Array.isArray(section?.AIText) && section?.AIText?.length > 0
        ? pickRandomValueFromArray(section?.AIText)?.text
        : section?.text || "Welcome to the store"}
    </Typography>
  );
}

TitleBlock.propTypes = {
  section: PropTypes.object,
};


export default memo(TitleBlock);
