import React, { useState } from 'react';
import trendlable from "../../assets/trendlabel.png";
import styles from "./style.module.css";
import { CheckCircle, PanoramaFishEye } from '@mui/icons-material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import PriceBlock from '../PriceBlock';
import { useApp } from '../../context/AppState';


const calculateDiscountedPrice = (item) => {

  const price = parseFloat(item?.product?.variants?.[0].price || 0);
  const discountType = item?.discount_type || '%';
  const discountValue = parseFloat(item?.discount_value || 0);

  let discountedTotalPrice = price;

  if (discountValue >= 0) {
    if (discountType === 'percentage') {
      discountedTotalPrice = discountedTotalPrice - (discountedTotalPrice * discountValue / 100);
    } else if (discountType === 'amount') {
      discountedTotalPrice = discountedTotalPrice - discountValue;
    }
  }

  const isFreeProduct = discountedTotalPrice <= 0;

  return {
    withoutDiscountPrice: price?.toFixed(2),
    subtotal: discountedTotalPrice?.toFixed(2),
    freeProduct: isFreeProduct,
  };
};

const VolumeDiscountProducts = ({ section, handleChange, selectedProduct }) => {
  const { isSmallMobile } = useApp();
  return (
    <div className={`${styles.volume_offer_product}`} style={{ width: "100%" }}>
      {section?.offerProduct?.map((item) => {
        const productId = item?.product?.id;
        const priceDetails = calculateDiscountedPrice(item);
        console.log("priceDetails : ", priceDetails)
        return (
          <div
            key={productId || item.id} // Use `productId` or fallback to `item.id` if `productId` is undefined
            style={{ background: '#F5F5F5', borderRadius: '7px', width: '100%', margin: '20px 0', position: "relative", width: "100%" }}
            onClick={(event) => handleChange(event, item)}
          >
            {/* {item.mostTrending && (
              <div style={{ position: 'absolute', right: "10px", top: "-5px", color: "#fff" }}>
                <img src={trendlable} width={100} />
                <p style={{ margin: "0", position: "absolute", top: "5px", right: "20px", fontSize: "10px" }}>Most Popular</p>
              </div>
            )}*/}

            <div style={{ background: '#91857C', borderRadius: '7px 7px 0 0' }}>
              <p style={{ margin: '0', color: 'white', fontWeight: '600', fontSize: isSmallMobile ? '12px' : '15px', padding: '7px' }}>
                {item?.product?.title}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', alignItems: "center" }}>
              <div style={{ display: 'flex', gap: '14px' }}>
                <img
                  width={50}
                  src={item?.product?.images?.[0]?.src}
                  alt={item?.name}
                  style={{ borderRadius: '5px' }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                  <p style={{ margin: '0', color: '#5B5B5B', fontWeight: '400', fontSize: isSmallMobile ? '12px' : '15px' }}>
                    {item?.discount_message}
                  </p>
                  <PriceBlock
                    productCosts={priceDetails}
                    discountType={
                      item?.discount_type === "percentage" ? "Percentage" : null
                    }
                    discountvalue={item?.discount_value}
                    isdiscount={item?.discount_value > 0}
                    freeProduct={priceDetails.freeProduct}
                    priceStyle={{
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                    defaultDiscountStyle={{
                      fontSize: "10px",
                      color: "#5B5B5B",
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', gap: '5px', alignItems: "center" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={item}
                >
                  <FormControlLabel
                    value={selectedProduct}
                    sx={isSmallMobile && { margin: 0 }}
                    control={
                      <Radio
                        icon={<PanoramaFishEye style={{ color: 'gray' }} />}
                        checkedIcon={<CheckCircle style={{ color: 'green' }} />}
                      />
                    }
                    label=""
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VolumeDiscountProducts;
