import React, { memo, useMemo } from "react";
import PopUpModel from "../../components/PopUpModel";
import WheelSpin from "../../components/WheelSpin";
import useInput from "../../hooks/useInput";
import useActionHandler from "../../hooks/useActionHandler";

const RenderWheelSpinPopUp = ({
  popupData,
  popupState,
  setPopupState,
}) => {

  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const {
    email,
    setEmail,
    emailError,
    setEmailError,
    handleOnEmailChange,
  } = useInput();

  const { onSpinStart, onSpinFinished, isEmailExist, spinning, setSpinning, disableSpin, setDisableSpin } = useActionHandler(popupData, setPopupState, { email }, popupState);

  return (
    <>
      {popupState.openPopup ? (
        <PopUpModel
          open={popupState.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          setEmail={setEmail}
        >
          <WheelSpin
            popupData={popupData}
            activeStep={popupState.activeStep}
            handleOnEmailChange={handleOnEmailChange}
            email={email}
            setEmailError={setEmailError}
            emailError={emailError}
            onSpinStart={onSpinStart}
            onSpinFinished={onSpinFinished}
            isEmailExist={isEmailExist}
            spinning={spinning}
            setSpinning={setSpinning}
            disableSpin={disableSpin}
            setDisableSpin={setDisableSpin}
          />
        </PopUpModel>
      ) : null}
    </>
  );
};

export default memo(RenderWheelSpinPopUp);
