import React, { memo, useEffect, useMemo, useState } from "react";
import PopUpModel from "../../components/PopUpModel";
import BundleDiscountCard from "../../components/BundleDiscountPopup/BundleDiscountCard";
import useActionHandler from "../../hooks/useActionHandler";
import { analyticsEventTypes } from "../../helper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { POPUP_SECTIONS } from "../../constant/constant";
import cartService from "../../services/cart.service";

const RenderBundlePopup = ({
    popupData,
    popupState,
    setPopupState,
}) => {
    const popupType = useMemo(() => popupData?.popUpType, [popupData]);
    const { addAnalyticsEvent } = useAnalytics();
    const { handleClose } = useActionHandler(popupData, setPopupState, {}, popupState)

    const [selectedVariant, setSelectedVariant] = useState([]);

    const blockType = (type) => popupData?.schema?.[popupState?.activeStep]?.sections?.find(
        (item) => item.type === type
    );
    const calculateTotalDiscountPrice = (variants) => {
        const productBlock = blockType(POPUP_SECTIONS.OFFER_PRODUCT);
        let totalPrice = 0;
        let totalDiscountedPrice = 0;

        variants?.forEach((item) => {
            const price = parseFloat(item?.price || 0);
            totalPrice += price;
        });
        const discountValue = parseFloat(productBlock?.discount_value || 0);
        const discountType = productBlock?.discount_type || "percentage";

        if (discountValue >= 0) {
            if (discountType === 'percentage') {
                totalDiscountedPrice = totalPrice - (totalPrice * discountValue / 100);
            } else if (discountType === 'amount') {
                totalDiscountedPrice = totalPrice - discountValue;
            }
        }

        return {
            withoutDiscountPrice: totalPrice.toFixed(2) ?? 0,
            subtotal: totalDiscountedPrice.toFixed(2) ?? 0,
            freeProduct: totalDiscountedPrice <= 0,
        };
    };

    const handleSelectVariant = (productId, variantId, productIndex) => {
        const updatedVariants = [...selectedVariant];

        const product = blockType(POPUP_SECTIONS.OFFER_PRODUCT)?.offerProduct?.[productIndex];


        if (product) {
            const variant = product.product.variants.find((variant) => String(variant.id) === String(variantId));
            if (variant) {
                updatedVariants[productIndex] = variant;
            }
        }

        setSelectedVariant(updatedVariants);
    }


    useEffect(() => {
        const initialVariants = blockType(POPUP_SECTIONS.OFFER_PRODUCT)?.offerProduct?.map((product) => {
            return product.product.variants[0];
        });
        setSelectedVariant(initialVariants);
    }, [popupData]);


    const totalPrice = useMemo(() => calculateTotalDiscountPrice(selectedVariant), [selectedVariant]);



    const handleSubmitCart = async () => {
        try {
            let items = selectedVariant?.map(variant => ({
                id: variant?.id,
                quantity: 1,
            }));

            const result = await cartService.addToCart(items);
            if (result) {
                await addAnalyticsEvent({
                    eventType: analyticsEventTypes.INTERACTION,
                    popupType: popupData?.popUpType,
                    popupId: popupData?._id,
                    ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id }),
                });

                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            {popupState.openPopup ? (
                <PopUpModel
                    open={popupState.openPopup}
                    activeStep={popupState?.activeStep}
                    setActiveState={setPopupState}
                    popupData={popupData}
                >
                    <BundleDiscountCard
                        popupData={popupData}
                        activeStep={popupState?.activeStep}
                        onClick={handleSubmitCart}
                        handleSelectVariant={handleSelectVariant}
                        totalPrice={totalPrice}
                        selectedVariant={selectedVariant}
                    />
                </PopUpModel>
            ) : null}
        </>
    );
};

export default memo(RenderBundlePopup);
