import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useAction } from "../../../../hooks/useAction";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import {
  getRandomId,
  parseCssStringToObject,
  validateBirthdate,
} from "../../../../helper";
import { useApp } from "../../../../context/AppState";
import ErrorTooltip from "../ErrorToolTip";

BirthDayBlock.propTypes = {
  section: PropTypes.object,
  isEmptyField: PropTypes.bool,
};

export default function BirthDayBlock({ section }) {
  const { control } = useFormContext();
  const { isMobile } = useApp();

  const { actionHandler } = useAction();

  const [tooltipPosition, setTooltipPosition] = useState("right");

  const randomId = useMemo(() => getRandomId(), [section]);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    textAlign: "center",
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCSS?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const handleTooltipPosition = () => {
    const inputElement = document.getElementById(randomId);
    if (inputElement) {
      const rect = inputElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (rect.right + 200 < viewportWidth) {
        setTooltipPosition("right");
      } else if (rect.left - 200 > 0) {
        setTooltipPosition("left");
      } else if (rect.top - 50 > 0) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  };

  return (
    <Controller
      name={section?.inputName ?? "birthdate"}
      control={control}
      rules={{
        ...(section?.rules?.required && {
          required: `${section?.inputName ?? "birthdate"}  is required`,
          validate: validateBirthdate,
        }),
      }}
      render={({ field, fieldState }) => (
        <div
          style={{
            display: "flex",
            gap: "15px",
            width: "100%",
            height: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          id={randomId}
        >
          <input
            {...field}
            type="text"
            name="day"
            placeholder="Day"
            value={field.value?.day || ""}
            style={{
              ...blockCSS,
              ...(fieldState?.error && {
                borderColor: "#d41019",
                color: "#d41019",
              }),
            }}
            maxLength="2"
            onChange={(e) =>
              actionHandler(() => {
                handleTooltipPosition();
                field.onChange({ ...field.value, day: Number(e.target.value) });
              })
            }
          />
          <input
            {...field}
            type="text"
            name="month"
            placeholder="Month"
            value={field.value?.month || ""}
            style={{
              ...blockCSS,
              ...(fieldState?.error && {
                borderColor: "#d41019",
                color: "#d41019",
              }),
            }}
            maxLength="2"
            onChange={(e) =>
              actionHandler(() => {
                handleTooltipPosition();
                field.onChange({
                  ...field.value,
                  month: Number(e.target.value),
                });
              })
            }
          />
          <input
            {...field}
            type="text"
            name="year"
            placeholder="Year"
            value={field.value?.year || ""}
            style={{
              ...blockCSS,
              ...(fieldState?.error && {
                borderColor: "#d41019",
                color: "#d41019",
              }),
            }}
            maxLength="4"
            onChange={(e) =>
              actionHandler(() => {
                handleTooltipPosition();
                field.onChange({
                  ...field.value,
                  year: Number(e.target.value),
                });
              })
            }
          />

          {/* {fieldState?.error && (
            <ErrorTooltip
              id={randomId}
              content={fieldState?.error?.message}
              position={tooltipPosition}
            />
          )} */}
        </div>
      )}
    />
  );
}
