import React from 'react';
import MultiBoardProduct from './MultiBoardProduct';

const MultiBoardProducts = ({ section, popupData, popupState, activeStep, setPopupState }) => {

  return (
    <div style={{
      display: 'grid',
      gap: '15px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      overflowX: 'auto',
      width: '100%',
      height: '100%',
    }} >
      {section.offerProduct.map((item) => <MultiBoardProduct data={item} popupData={popupData} activeStep={activeStep} setPopupState={setPopupState} popupState={popupState} />)}
    </div>
  );
};

export default MultiBoardProducts;
