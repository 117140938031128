import React, { useReducer, useContext, useState } from "react";
import AppContext from "./appContext";
import { useMediaQuery } from "@mui/material";
import toast, { Toaster } from 'react-hot-toast';

const intialState = {
  is_preview_mode: true, // It will be false for shopify preview mode  
  activeStep: 0,
  popUpType: null,   
  user: null,
  shop: {},
  popupValue: [],
  spinwheel_code:'',
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "STORE_INIT":
      return { ...state, ...action.payload };
    case "POPUP_INIT":
      return { ...state, ...action.payload };
    case "SPIN_WHEEL":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const AppProvider = ({ children, prevState }) => {
  const [appState, dispatch] = useReducer(appReducer, prevState || intialState);
  const [upsellData,setUpsellData]= useState()
  const isMobile = useMediaQuery("(max-width:768px)");
  const isSmallMobile = useMediaQuery("(max-width:425px)");

  return (
    <AppContext.Provider value={{ appState, dispatch, isMobile, isSmallMobile ,upsellData, setUpsellData, toast}}>
      {children}
      <Toaster />
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default AppContext;