import React, { memo } from "react";

// PropTypes
import PropTypes from "prop-types";

// mui
import { Stack, Typography } from "@mui/material";
import { parseCssStringToObject, pickRandomValueFromArray } from "../../helper";

const CouponCodeSummaryBlock = ({ section }) => {
    const customCSS = section?.setting?.customCSS || {};

    const gradient = section?.setting?.overrideCss || '';

    const css = parseCssStringToObject(gradient);

    const winnervalue = localStorage.getItem("Winner");
    return (
        <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ lineHeight: "1.35rem" }}
        >
            <Stack
                sx={{
                    marginTop: "20px",
                    ...customCSS,
                    ...css,
                    '@media (max-width:425px)': {
                        fontSize: '9px',
                      },
                }}
            >
                {section.type === "CODE"
                    ? winnervalue
                    : section?.AIText?.length > 0 && Array.isArray(section?.AIText)
                        ? pickRandomValueFromArray(section?.AIText)?.text
                        : section?.text}
            </Stack>
        </Typography>
    );
};

CouponCodeSummaryBlock.propTypes = {
    section: PropTypes.object,
};

export default memo(CouponCodeSummaryBlock);
