import React from "react";

// PropTypes
import PropTypes from "prop-types";

ImageBlock.propTypes = {
  src: PropTypes.object,
};

export default function ImageBlock({ src }) {
  return (
    <>
      {src?.file && (
        <img
          src={
            src?.file ||
            src?.image ||
            "https://img2.ogaanindia.com/pub/media/catalog/product/cache/3f6619daccdb194398d06464ab49fa6e/s/h/sht03mr279_1.jpg"
          }
          alt="preview Image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
          }}
          draggable={false}
        />
      )}
    </>
  );
}
