import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useApp } from "../../../../context/AppState";
import { useAction } from "../../../../hooks/useAction";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { addPx, getRandomId, parseCssStringToObject } from "../../../../helper";

RatingInputBlock.propTypes = {
  section: PropTypes.object.isRequired,
};

export default function RatingInputBlock({ section }) {
  const { control } = useFormContext();
  const { isMobile } = useApp();
  const { actionHandler } = useAction();
  const [tooltipPosition, setTooltipPosition] = useState("right");

  const randomId = useMemo(() => getRandomId(), [section]);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    display: "flex",
    justifyContent: "space-between",
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCSS?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const handleTooltipPosition = () => {
    const inputElement = document.getElementById(randomId);
    if (inputElement) {
      const rect = inputElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (rect.right + 200 < viewportWidth) {
        setTooltipPosition("right");
      } else if (rect.left - 200 > 0) {
        setTooltipPosition("left");
      } else if (rect.top - 50 > 0) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  };

  const emojis = ["😟", "🙁", "😐", "☺️", "😍"];
  return (
    <Controller
      name={section?.inputName ?? "rating"}
      control={control}
      rules={{
        ...(section?.rules?.required && {
          required: `${section?.inputName ?? "rating"} is required`,
        }),
      }}
      render={({ field, fieldState }) => (
        <div style={blockCSS} id={randomId}>
          {emojis.map((emoji, index) => (
            <span
              key={index}
              onClick={() => {
                actionHandler(() => {
                  handleTooltipPosition();
                  field.onChange(index + 1);
                });
              }}
              style={{
                cursor: "pointer",
                fontSize:blockCSS?.fontSize,
                border: index === field.value - 1 ? `2px solid ${blockCSS?.color}` : "none",
                ...(fieldState?.error && {
                  border: "2px solid #d41019",
                }),
                borderRadius: "50%",
                padding: "1px",
                transition: "transform 0.2s, border 0.2s",
                transform: index === field.value - 1 ? "scale(1.3)" : "scale(1)"
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "scale(1.2)";
                e.target.style.border = `2px solid ${blockCSS?.color}`;
              }}
              onMouseLeave={(e) => {
                e.target.style.transform =
                  index === field.value -1 ? "scale(1.3)" : "scale(1)"; 
                e.target.style.border =
                  index === field.value - 1 ? `2px solid ${blockCSS?.color}` : "none";
              }}
            >
              {emoji}
            </span>
          ))}
        </div>
      )}
    />
  );
}
