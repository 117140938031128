import React from 'react';

const ConditonBlock = ({ section, value }) => {
    const customCSS = section?.setting?.customCSS || {};
    const listItems = value.sections.filter(item => item.type === "LIST1" || item.type === "LIST2");
    const list1 = listItems[0];
    const list2 = listItems[1]

    const combinedText = `${list1.text}`;

    const renderHTML = (text) => {
        return { __html: text };
    };


    return (
        <div style={customCSS}>
            <div style={{ display: 'flex' }}>
                <p style={list1.setting.customCSS} dangerouslySetInnerHTML={renderHTML(combinedText)} />
            </div>
        </div>
    )
}

export default ConditonBlock
