import React, { useCallback, useState } from "react";
import { api } from "../utils/interceptor";
import { useApp } from "../context/AppState";

export const useAction = () => {
    const { appState } = useApp();

    const actionHandler = useCallback((action, section) => {
        if (appState && !appState?.is_preview_mode) {
            if (section?.action === "OPEN_LINK") {
                if (section?.openInNewTab) {
                    window.open(section?.link, '_blank');
                } else {
                    window.location.href = section.link;
                }
            } else {
                action();
            }
        }
    }, [appState]);

    return {
        actionHandler
    };
};