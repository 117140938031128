import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"; // Optional: Your custom CSS for slider styles
import { useApp } from "../../context/AppState";

function AIImageSlider({ img, activesrc, mobileview }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef(null);
  const thumbSliderRef = useRef(null);
  const { isMobile, isSmallMobile } = useApp();

  useEffect(() => {
    const activeIndex = img.findIndex((src) => src === activesrc);
    if (activeIndex !== -1) {
      setActiveSlide(activeIndex);
      sliderRef.current.slickGoTo(activeIndex);
    }
  }, [img, activesrc]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const thumbnailSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 3 : 4, 
    slidesToScroll: 1,
    swipeToSlide: true,
    vertical: isMobile, 
    verticalSwiping: isMobile,
    focusOnSelect: true,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const goToSlide = (index) => {
    setActiveSlide(index);
    sliderRef.current.slickGoTo(index);
    thumbSliderRef.current.slickGoTo(index);
  };

  return (
    <div
      className={`slider-container ${isMobile ? "mobile-slider-layout" : ""}`}
      style={{ padding: isMobile ? "0px 10px" : "0px" }}
    >
      {/* Main Image Slider */}
      <div className="main-slider">
        <Slider ref={sliderRef} {...settings}>
          {img.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Slide ${index}`}
                style={{ width: "100%", height: isMobile ? "200px" : "370px" }}
                className="img_box_shadow"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Thumbnail Slider */}
      <div className={`thumbnail-container ${isMobile ? "mobile-thumbnail" : ""}`}>
        <Slider ref={thumbSliderRef} {...thumbnailSettings}>
          {img.map((image, index) => (
            <div
              key={index}
              className={`thumbnail-item ${index === activeSlide ? "active" : ""} ${isSmallMobile ? "thumbnail-auto-width" : ""} `}
              onClick={() => goToSlide(index)}
            >
              <img
                src={image}
                alt={`Thumbnail ${index}`}
                style={{
                  width: "100%",
                  height: isSmallMobile ? "" : "80px",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default AIImageSlider;
