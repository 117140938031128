import React, { useState } from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { useApp } from "../../context/AppState";
import { parseCssStringToObject } from "../../helper";
import copy from "../../assets/copy.png";

CouponCodeBlock.propTypes = {
  section: PropTypes.object,
  generalSettings: PropTypes.object,
  type: PropTypes.string,
};

export default function CouponCodeBlock({ section, generalSettings, type }) {
  const customCSS = section?.setting?.customCSS || {};
  const gradient = section?.setting?.overrideCss || "";
  const css = parseCssStringToObject(gradient);
  const { appState } = useApp();

  // State for showing temporary notification
  const [showNotification, setShowNotification] = useState(false);

    console.log("debug code : ", generalSettings);

  // Determine the text to be copied
  const textToCopy =
    type === "Spin Wheel"
      ? appState.spinwheel_code || section?.text
      : generalSettings?.discount_code || section?.text;

  // Function to handle copy action
  const handleCopy = () => {
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          // Show notification
          setShowNotification(true);
          // Hide notification after 2 seconds
          setTimeout(() => {
            setShowNotification(false);
          }, 2000); // 2000 milliseconds = 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  return (
    <Typography
      variant="subtitle1"
      color="text.secondary"
      sx={{ ...customCSS, ...css }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "10px",
          paddingLeft: "10px",
          cursor: "pointer",
          "@media (max-width:425px)": {
            fontSize: "11px",
          },
        }}
      >
        <span>{textToCopy}</span>
        <img
          src={copy}
          alt="Copy"
          style={{ width: "14px", cursor: "pointer" }}
          onClick={handleCopy}
        />
      </Stack>
      {showNotification && (
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            marginTop: "10px",
            color: "#5832BE",
            fontWeight: "bold",
          }}
        >
          Code copied!
        </Typography>
      )}
    </Typography>
  );
}
