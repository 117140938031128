import { useCallback } from 'react';
import confetti from 'canvas-confetti';


const INFO_CARD_COLORS = [
    '#f87739',
    '#e25886',
    '#b658e2',
    '#7b58e2',
    '#589be2',
    '#58e2af',
    '#58e25e',
    '#bfe258',
    '#e2b658',
    '#e25858',
    '#e258b7',
  ];

const count = 250;
const defaults = {
  origin: { y: 0.4 },
  colors: INFO_CARD_COLORS,
  zIndex: 99999999,
};

const fireConfetti = (particleRatio, opts) => {
  confetti({
    ...defaults,
    ...opts,
    ...{
      particleCount: Math.floor(count * particleRatio),
    },
  });
};

export const useConfetti = (popupData) => {
    const fire = useCallback((opts = {}) => {
      if (popupData?.generalSettings?.isConfettiFire) {
      fireConfetti(0.25, {
        spread: 26,
        startVelocity: 55,
        ...opts,
      });
      fireConfetti(0.2, {
        spread: 60,
        ...opts,
      });
      fireConfetti(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        ...opts,
      });
      fireConfetti(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        ...opts,
      });
      fireConfetti(0.1, {
        spread: 120,
        startVelocity: 45,
        ...opts,
      });
    }
    }, [popupData]);
  
    return { fire };
  };
