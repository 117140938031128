import React, { useCallback} from "react";
import WheelComponent from "./WheelComponent";
import "./style.css";
import FirstComponent from "./FirstComponent";
import SecondComponent from "./SecondComponent";
import { useApp } from "../../context/AppState";
import { getBackgroundStyle } from "../../helper";

export default function WheelSpin({
  popupData,
  activeStep,
  handleOnEmailChange,
  email,
  emailError,
  isEmailExist,
  onSpinStart,
  onSpinFinished,
  spinning,
  setSpinning,
  disableSpin,
  setDisableSpin
}) {

  const Wheel = popupData?.generalSettings;

  const { appState, isMobile } = useApp();

  const renderComponent = useCallback(
    (activeStep) => {

      switch (activeStep) {
        case 0:
          return (
            <FirstComponent
              popupData={popupData}
              activeStep={activeStep}
              onClick={onSpinStart}
              handleOnEmailChange={handleOnEmailChange}
              email={email}
              disabled={(disableSpin ? true : false) || email === ""}
              emailError={emailError}
              isEmailExist={isEmailExist}
            />
          );
        case 1:
          return (
            <SecondComponent
              popupData={popupData}
              activeStep={activeStep}
              email={email}
            />
          );
      }
    },
    [popupData, activeStep, email, disableSpin, emailError, isEmailExist]
  );


  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "20px"
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0, // Ensure background is behind the content
  };

  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: 'auto',
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        <div id="wheelCircle">
          <WheelComponent
            segments={Wheel.slices}
            segColors={Wheel.Wheelslicecolor}
            winningSegment=""
            onFinished={(winner) => onSpinFinished(winner)}
            primaryColor={"#FCFFE7"}
            primaryColoraround={Wheel.wheelBorder.color}
            contrastColor={Wheel.wheelBorder.color}
            buttonText=""
            wheelTextColor={Wheel.WheelTextColor.color}
            isOnlyOnce={false}
            is_preview_mode={appState?.is_preview_mode}
            size={220}
            upDuration={50}
            downDuration={1000}
            isSpinning={spinning}
            setIsSpinning={setSpinning}
            setdisable={setDisableSpin}
          />
        </div>
        <div style={cardContent}>{renderComponent(activeStep)}</div>
      </div>
    </div>

  );
}
