import React, { memo } from "react";
import PropTypes from "prop-types";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import { parseCssStringToObject } from "../../../../helper";

// Custom Filled Star Component
const CustomFilledStar = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
      fill={color}
    />
  </svg>
);

// Custom Unfilled Star Component
const CustomUnfilledStar = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
      stroke={color}
      fill="none"
    />
  </svg>
);

// RatingBlock Component
const RatingBlock = ({ section }) => {
  const { isMobile } = useApp();

  // Define the style for the section block, similar to PriceBlock
  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  // Get the color and font size from blockCSS
  const starColor = blockCSS.color || "#FFD700"; // Default gold color if not specified
  const fontSize = blockCSS.fontSize || "16px"; // Fallback to 16px if not specified
  const starSize = parseInt(fontSize, 10); // Convert fontSize to integer for star size

  // Function to render custom star icons based on section rating
  const renderStars = (rating) => {
    const stars = [];
    const filledStars = Math.floor(rating); // Whole stars
    const halfStar = rating % 1 !== 0; // Check for half star
    const totalStars = 5; // Total stars to display

    for (let i = 0; i < totalStars; i++) {
      if (i < filledStars) {
        stars.push(
          <CustomFilledStar key={i} color={starColor} size={starSize} />
        );
      } else {
        stars.push(
          <CustomUnfilledStar key={i} color={starColor} size={starSize} />
        );
      }
    }

    return stars;
  };

  return (
    <div style={blockCSS} className="rating-block">
      <div>{renderStars(section?.rating || 5)}</div>
    </div>
  );
};

RatingBlock.propTypes = {
  section: PropTypes.object, // Optional: For custom CSS and other settings
};

RatingBlock.defaultProps = {
  section: {},
};

export default memo(RatingBlock);
