import React, { useEffect, useRef, useState } from 'react';
import { invertColor } from '../../../../helper';

const Loader = () => {
  const loaderRef = useRef(null);
  const [size, setSize] = useState();
  const [loaderColor, setLoaderColor] = useState("black");
  const [parentBgColor, setParentBgColor] = useState("white");

  useEffect(() => {
    const parent = loaderRef.current?.parentElement;

    if (parent) {
      const parentWidth = parent.offsetWidth;
      const parentHeight = parent.offsetHeight;

      setSize(`${(Math.min(parentWidth, parentHeight) * 0.8) - 5}px`); 

      const parentBgColor = window.getComputedStyle(parent).backgroundColor;

      setParentBgColor(parentBgColor);
      setLoaderColor(invertColor(parentBgColor));
    }
  }, []);



  return (
    <div
      ref={loaderRef}
      style={{
        width: size,
        height: size,
        border: `3px solid ${loaderColor}`,
        borderTop: `3px solid ${parentBgColor}`,
        borderRadius: "100%",
        animation: "spin 1s linear infinite",
        boxSizing: "border-box",
      }}
    >
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Loader;
