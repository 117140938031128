import { useState } from 'react';
import { validateEmail } from '../helper';

const useInput = () => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [birthday, setBirthday] = useState({
    day: null,
    month: null,
    year: null,
  });

  const handleOnEmailChange = (e) => {
    setEmailError(false);
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError(true);
    }
  };

  const handleOnTextChange = (e) => {
    setText(e.target.value);
  };

  return {
    email,
    setEmail,
    text,
    setText,
    birthday,
    setBirthday,
    emailError,
    setEmailError,
    handleOnEmailChange,
    handleOnTextChange,
  };
};

export default useInput;
