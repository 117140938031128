import React, { memo } from "react";
import PropTypes from "prop-types";
import { parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";

const TextBlock = ({ section }) => {
  const { isMobile } = useApp();

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
    flexWrap: "wrap", 
    whiteSpace: "pre-wrap",
  };

  return (
    <p
      style={blockCSS}
      dangerouslySetInnerHTML={{ __html: section?.text || "" }}
    />
  );
};

TextBlock.propTypes = {
  section: PropTypes.object,
};

export default memo(TextBlock);
