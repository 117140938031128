import React, { useState, useEffect } from "react";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";
import {
  calculateEventTimeDifference,
  parseCssStringToObject,
} from "../../../../helper";

const TimerBlock = ({ popupData, section }) => {
  const { isMobile, appState } = useApp();

  const { isTimerPopup } = popupData?.generalSettings?.condition?.timer || {};
  const { isCountDown, isHideDays, isHideseconds } = popupData?.generalSettings?.condition?.timer?.countdown || {};

  const [endTime, setEndTime] = useState(null);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!appState?.is_preview_mode) {
      const { eventEndTime } =
        popupData?.generalSettings?.condition?.timer || {};

      setEndTime(new Date(eventEndTime));
    }
  }, [popupData?.generalSettings?.condition?.timer, appState?.is_preview_mode]);

  const updateTimeLeft = () => {
    const now = Date.now();
    const totalSeconds = Math.floor((endTime - now) / 1000);

    if (totalSeconds <= 0) {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      return;
    }

    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    setTimeLeft({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    if (!appState?.is_preview_mode) {
      const timer = setInterval(() => {
        updateTimeLeft();
      }, 500);
      return () => clearInterval(timer);
    }
  }, [endTime, appState?.is_preview_mode]);

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    ...parseCssStringToObject(
      section?.setting?.overrideCSS?.[isMobile ? "mobile" : "desktop"]
    ),
  };

  const timerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const separatorStyle = {
    margin: "0 5px",
  };

  if (!isTimerPopup) return <></>;

  return (
    <>
      {isCountDown && (
        <div style={blockCSS}>
          {!isHideDays && (
            <>
              <div style={timerStyle}>
                <span>{String(timeLeft.days).padStart(2, "0")}</span>
                <span>
                  {
                    popupData?.generalSettings?.condition?.timer?.countdown
                      ?.dayText
                  }
                </span>
              </div>
              <span style={separatorStyle}>:</span>
            </>
          )}
          <div style={timerStyle}>
            <span>{String(timeLeft.hours).padStart(2, "0")}</span>
            <span>
              {
                popupData?.generalSettings?.condition?.timer?.countdown
                  ?.hourText
              }
            </span>
          </div>
          <span style={separatorStyle}>:</span>
          <div style={timerStyle}>
            <span>{String(timeLeft.minutes).padStart(2, "0")}</span>
            <span>
              {
                popupData?.generalSettings?.condition?.timer?.countdown
                  ?.minuteText
              }
            </span>
          </div>
          {!isHideseconds && (
            <>
              <span style={separatorStyle}>:</span>
              <div style={timerStyle}>
                <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
                <span>
                  {
                    popupData?.generalSettings?.condition?.timer?.countdown
                      ?.secondText
                  }
                </span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TimerBlock;
