import React, { memo, useCallback, useEffect, useMemo } from "react";
import PopUpModel from "../../components/PopUpModel";
import WelcomeVerticalCard from "../../components/WelcomeVerticalPopup/WelcomeVerticalCard";
import useActionHandler from "../../hooks/useActionHandler";
import useInput from "../../hooks/useInput";
import { useApp } from "../../context/AppState";
import { calculateEventTimeDifference } from "../../helper";

const RenderWelcomeVerticalPopup = ({
  popupData,
  popupState,
  setPopupState,
}) => {
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const popupId = useMemo(() => popupData?._id, [popupData]);
  const {
    email,
    setEmail,
    birthday,
    setBirthday,
    emailError,
    setEmailError,
    handleOnEmailChange,
  } = useInput();
  const { appState } = useApp();
  const { onGetCouponCode, isEmptyField, isEmailExist } = useActionHandler(
    popupData,
    setPopupState,
    { birthday, email },
    popupState
  );

  const eventTiming = useMemo(() => {
    const {
      generalSettings: { event_starttime, event_endtime },
    } = popupData;

    return { event_starttime, event_endtime };
  }, [popupData]);

  const checkEventTime = useCallback(() => {
    const { event_starttime, event_endtime } = eventTiming;

    if (!event_starttime || !event_endtime) {
      return;
    }

    if (
      !calculateEventTimeDifference(event_starttime, event_endtime) &&
      !appState.is_preview_mode
    ) {
      setPopupState((prevState) => ({
        ...prevState,
        [`${popupType}_${popupId}`]: { activeStep: 0, openPopup: false },
      }));
    }
  }, [eventTiming, appState.is_preview_mode, popupType, popupId]);

  useEffect(() => {
    checkEventTime();
    const intervalId = setInterval(checkEventTime, 3000);
    return () => clearInterval(intervalId);
  }, [checkEventTime]);

  return (
    <>
      {popupState.openPopup ? (
        <PopUpModel
          open={popupState.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          setEmail={setEmail}
          setBirthday={setBirthday}
          halfOutsideBottom={true}
          hasAiIcon={true}
        >
          <WelcomeVerticalCard
            popupState={popupState}
            popupData={popupData}
            setPopupState={setPopupState}
            activeStep={popupState.activeStep}
            onClick={onGetCouponCode}
            handleOnEmailChange={handleOnEmailChange}
            email={email}
            emailError={emailError}
            setEmailError={setEmailError}
            isEmailExist={isEmailExist}
            birthday={birthday}
            setBirthday={setBirthday}
            isEmptyField={isEmptyField}
          />
        </PopUpModel>
      ) : null}
    </>
  );
};

export default memo(RenderWelcomeVerticalPopup);
