import React from "react";
import PropTypes from "prop-types";
import { addPx, parseCssStringToObject } from "../../../../helper";
import { DEFAULT_BLOCK_CSS } from "../../../../constant/constant";
import { useApp } from "../../../../context/AppState";

CouponCodeBlock.propTypes = {
  section: PropTypes.object,
};

export default function CouponCodeBlock({ section }) {
  const { isMobile, toast } = useApp();

  const blockCSS = {
    ...DEFAULT_BLOCK_CSS,
    ...section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"],
    borderRadius: addPx(
      section?.setting?.customCSS?.[isMobile ? "mobile" : "desktop"]
        ?.borderRadius
    ),
    ...parseCssStringToObject(
      section?.setting?.overrideCss?.[isMobile ? "mobile" : "desktop"]
    ),
    border: "1px dotted #5832BE",
  };

  const textToCopy = section?.discount_code || section?.text;

  const handleCopy = () => {
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      toast.success("Code copied successfully!");
    }
  };

  return (
    <div style={blockCSS}>
      <div>{textToCopy}</div>
      <div
        onClick={handleCopy}
      >
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#a)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 5h7.795c1.115 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926V16a1 1 0 1 0 2 0V8.128c0-1.783-.186-2.43-.534-3.082a3.635 3.635 0 0 0-1.512-1.512C18.302 3.186 17.655 3 15.872 3H8a1 1 0 0 0 0 2zm7.721 2.334C15.314 7.116 14.91 7 13.795 7h-7.59c-1.115 0-1.519.116-1.926.334a2.272 2.272 0 0 0-.945.945C3.116 8.686 3 9.09 3 10.205v7.59c0 1.114.116 1.519.334 1.926.218.407.538.727.945.945.407.218.811.334 1.926.334h7.59c1.114 0 1.519-.116 1.926-.334.407-.218.727-.538.945-.945.218-.407.334-.811.334-1.926v-7.59c0-1.115-.116-1.519-.334-1.926a2.272 2.272 0 0 0-.945-.945z"
            fill={blockCSS?.color || "#000"}  
          />
        </g>

        <defs>
          <clipPath id="a">
            <path fill="#000000" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
      </div>

    </div>
  );
}
