import React from "react";
import TitleBlock from "../Preview/TitleBlock";
import ButtonBlock from "../Preview/ButtonBlock";
import { POPUP_SECTIONS } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import VolumeDiscountProducts from "./VolumeDiscountProducts";
import CoverImageBlock from "../WelcomeVerticalPopup/CoverImageBlock";
import VolumeDiscountImg from "../../assets/volume_discount.png";
import { getBackgroundStyle } from "../../helper";

const VolumeDiscountCard = ({
  popupData,
  activeStep,
  handleProductChange,
  selectedProduct,
  emailError,
  handleSubmitCart
}) => {
  const { isMobile } = useApp()

  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "20px"
  }

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0, 
  };
  
  const blockType = (type) =>
    popupData?.schema[activeStep]?.sections?.find(
      (item) => item.type === type
    );

  const renderSections = popupData?.schema[0]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;

        case POPUP_SECTIONS.OFFER_PRODUCT:
          return <VolumeDiscountProducts section={section} key={index} handleChange={handleProductChange} selectedProduct={selectedProduct} />;

        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              section={section}
              onClick={handleSubmitCart}
              disabled={!selectedProduct}
              key={index}
              emailError={emailError}
            />
          );

        default:
          return null;
      }
    }
  );

  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        height: "100%",
        width: "100%",
        overflow: 'auto',
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        {POPUP_SECTIONS.COVER_IMAGE && !isMobile &&
          <CoverImageBlock section={blockType(POPUP_SECTIONS.COVER_IMAGE)} altImage={VolumeDiscountImg} />
        }

        <div style={cardContent}>
          {/* <div className={styles.volume_discount_ai}>
            <img src={aicon} />
            <p>present by AI</p>
          </div> */}
          {renderSections}
        </div>
      </div>
    </div>
  );
};

export default VolumeDiscountCard;
