import React, { useState } from 'react'
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import './style.css'
import { useApp } from '../../context/AppState';
import { useAnalytics } from '../../hooks/useAnalytics';
import { analyticsEventTypes, getBackgroundStyle } from '../../helper';

const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0
  };

const ProductDetails = ({ popupData, activeStep }) => {
    const [show, setShow] = useState(false);
    const { addAnalyticsEvent } = useAnalytics();
    const hashValue = localStorage.getItem("hash");
    const productArray = popupData?.schema[0]?.sections[1]?.test_orders

    const filteredProducts = productArray?.filter(product => {
        return product?.id === Number(hashValue);
    });

    const product = filteredProducts[0];

    const { isMobile, isSmallMobile } = useApp();
    const date = new Date(product?.date_of_purchase);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear().toString();

    const formattedDate = `${day}/${month}/${year}`;

    const handleMouseEnter = () => {
        setShow(true);
    };

    const handleMouseLeave = () => {
        setShow(false);
    };

    const clickOnViewProduct = async () => {
        window.open(
            `https://${popupData?.storeName}/products/${product?.handle}`
        );
        await addAnalyticsEvent({
            eventType: analyticsEventTypes.INTERACTION,
            popupType: popupData?.popUpType,
            popupId: popupData?._id,
            ...(popupData?.campaign_Id && { campaignId: popupData?.campaign_Id })
        });
    };

    return (
        <>
            <div
                style={{
                    ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
                    ...backgroundStyle,
                }}
            />
            <div style={{
                display: 'flex', height: '100%', width: '100%', borderRadius: '8px', flexDirection: isMobile ? 'column' : 'row', maxHeight: "584px", overflowY: !isMobile ? "" : 'auto',
                position: "relative",
                zIndex: 1
            }}>
                <div style={{ flexBasis: isMobile ? '100%' : '50%' }}>
                    <img
                        src={
                            product?.src ||
                            'https://www.svgrepo.com/show/508699/landscape-placeholder.svg'
                        }
                        alt="preview image"
                        style={{ width: `100%`, height: "100%", borderRadius: '8px', }}
                    />
                </div>
                <div style={{ padding: '20px', width: isMobile ? 'auto' : '100%', flexBasis: isMobile ? '100%' : '43%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        width: '100%'
                    }}>

                        <div style={{
                            display: 'flex',
                            gap: '3px',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                {product?.first_name ?? 'Jessica'}
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0px',
                                position: 'relative'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                                    paddingRight: "8px",
                                    marginRight: "8px",
                                }}>
                                    <CheckCircleIcon sx={{ fontSize: '12px', marginTop: '2.2px' }} />
                                    <div style={{
                                        fontSize: isSmallMobile ? '10px' : '12px',
                                    }}>
                                        Verified purchase
                                    </div>
                                </div>
                                <button onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ zIndex: '1111111', background: 'none', border: 'none', cursor: 'pointer', padding: '0px', marginTop: '5px' }}
                                >
                                    <InfoOutlinedIcon sx={{ fontSize: isSmallMobile ? "11px" : "13px" }} />
                                </button>
                                {show && !isMobile && <div className='infodetails' >
                                    This review was collected from a verified customer who purchased this item.
                                </div>}
                            </div>

                        </div>


                        <div style={{
                            marginTop: "5px",
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{ marginLeft: '-2px' }}>
                                <StarIcon sx={{ color: "#EC6550", fontSize: '18px' }} />
                                <StarIcon sx={{ color: "#EC6550", fontSize: '18px' }} />
                                <StarIcon sx={{ color: "#EC6550", fontSize: '18px' }} />
                                <StarIcon sx={{ color: "#EC6550", fontSize: '18px' }} />
                                <StarIcon sx={{ color: "#EC6550", fontSize: '18px' }} />
                            </div>
                            <div
                                style={{
                                    color: "#767676",
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                    fontWeight: "normal",
                                }}
                            >
                                {product?.date_of_purchase ? formattedDate : '26/07/24'}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: product?.body_html ? '' : '18px', flex: '1' }}>
                        <div dangerouslySetInnerHTML={{ __html: product?.body_html }} style={{ fontSize: isSmallMobile ? "12px" : "13px" }} />
                    </div>
                    <div>
                        <hr />
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <img src={product?.src || 'https://www.svgrepo.com/show/508699/landscape-placeholder.svg'} alt='' style={{ width: '50px', height: '50px' }} />
                            <div>
                                <div className={`prodctText ${isSmallMobile ? 'mobile-prodctText' : ''}`}>
                                    {product?.title ?? 'Most Sellout Product'}
                                </div>
                                <div className='productbutton'>
                                    <ShoppingBagOutlinedIcon />
                                    <div className='viewproduct' onClick={clickOnViewProduct}>
                                        View product
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetails