import React, { useState } from 'react';

const BadgeImage = ({ color = "#FF5733" }) => {

    return (
        <svg width="123" height="42" viewBox="0 0 123 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_179_2569)">
        <path d="M24.9986 14.05L25.05 14.0514V14V9V8.95H25H16.0815H16.0705L16.0604 8.95468C15.3693 9.27672 14.5231 9.78795 13.7055 10.5684L13.7055 10.5684C12.8776 11.3591 12.4388 12.1701 12.2069 12.7843C12.091 13.0913 12.0269 13.3491 11.9917 13.5306C11.9741 13.6213 11.9637 13.693 11.9577 13.7422C11.9547 13.7668 11.9528 13.7858 11.9517 13.7988L11.9505 13.8137L11.9502 13.8176L11.9501 13.8187L11.9501 13.819L11.9501 13.8191L12 13.8221L11.9501 13.8192L11.947 13.8721H12H15.005H18.6181L24.9986 14.05Z" fill={color}/>
        <path d="M24.9986 14.05L25.05 14.0514V14V9V8.95H25H16.0815H16.0705L16.0604 8.95468C15.3693 9.27672 14.5231 9.78795 13.7055 10.5684L13.7055 10.5684C12.8776 11.3591 12.4388 12.1701 12.2069 12.7843C12.091 13.0913 12.0269 13.3491 11.9917 13.5306C11.9741 13.6213 11.9637 13.693 11.9577 13.7422C11.9547 13.7668 11.9528 13.7858 11.9517 13.7988L11.9505 13.8137L11.9502 13.8176L11.9501 13.8187L11.9501 13.819L11.9501 13.8191L12 13.8221L11.9501 13.8192L11.947 13.8721H12H15.005H18.6181L24.9986 14.05Z" fill={color}/>
        <path d="M24.9986 14.05L25.05 14.0514V14V9V8.95H25H16.0815H16.0705L16.0604 8.95468C15.3693 9.27672 14.5231 9.78795 13.7055 10.5684L13.7055 10.5684C12.8776 11.3591 12.4388 12.1701 12.2069 12.7843C12.091 13.0913 12.0269 13.3491 11.9917 13.5306C11.9741 13.6213 11.9637 13.693 11.9577 13.7422C11.9547 13.7668 11.9528 13.7858 11.9517 13.7988L11.9505 13.8137L11.9502 13.8176L11.9501 13.8187L11.9501 13.819L11.9501 13.8191L12 13.8221L11.9501 13.8192L11.947 13.8721H12H15.005H18.6181L24.9986 14.05Z" stroke={color} stroke-width="0.1"/>
        <path d="M24.9986 14.05L25.05 14.0514V14V9V8.95H25H16.0815H16.0705L16.0604 8.95468C15.3693 9.27672 14.5231 9.78795 13.7055 10.5684L13.7055 10.5684C12.8776 11.3591 12.4388 12.1701 12.2069 12.7843C12.091 13.0913 12.0269 13.3491 11.9917 13.5306C11.9741 13.6213 11.9637 13.693 11.9577 13.7422C11.9547 13.7668 11.9528 13.7858 11.9517 13.7988L11.9505 13.8137L11.9502 13.8176L11.9501 13.8187L11.9501 13.819L11.9501 13.8191L12 13.8221L11.9501 13.8192L11.947 13.8721H12H15.005H18.6181L24.9986 14.05Z" stroke={color} stroke-width="0.1"/>
        <path d="M98.5503 14.1569L104.827 13.8936H107.954H109.546H111.129L111.104 13.6304C111.071 13.0526 111.086 11.7736 109.5 10.5C108.716 9.87031 107.767 9.39012 107.104 9.13037L98.5503 9.64981V14.1569Z" fill={color}/>
        <path d="M98.5503 14.1569L104.827 13.8936H107.954H109.546H111.129L111.104 13.6304C111.071 13.0526 111.086 11.7736 109.5 10.5C108.716 9.87031 107.767 9.39012 107.104 9.13037L98.5503 9.64981V14.1569Z" fill={color}/>
        <g filter="url(#filter1_d_179_2569)">
        <path d="M19.7643 15.6072C18.4423 12.6605 17.6457 9.78248 16.5 9C47.9636 8.70657 109.134 9.23383 107.104 9.23388C106.604 9.23389 103.932 13.651 103.315 15.0203C99.2958 26.0533 91.5929 28.0291 88.2439 27.6379H33.2487C27.1675 27.332 23.9947 22.9306 23.4659 22.3437C22.9371 21.7568 21.4598 19.3865 19.7643 15.6072Z" fill={color}/>
        <path d="M19.7643 15.6072C18.4423 12.6605 17.6457 9.78248 16.5 9C47.9636 8.70657 109.134 9.23383 107.104 9.23388C106.604 9.23389 103.932 13.651 103.315 15.0203C99.2958 26.0533 91.5929 28.0291 88.2439 27.6379H33.2487C27.1675 27.332 23.9947 22.9306 23.4659 22.3437C22.9371 21.7568 21.4598 19.3865 19.7643 15.6072Z" fill={color}/>
        <path d="M19.7643 15.6072C18.4423 12.6605 17.6457 9.78248 16.5 9C47.9636 8.70657 109.134 9.23383 107.104 9.23388C106.604 9.23389 103.932 13.651 103.315 15.0203C99.2958 26.0533 91.5929 28.0291 88.2439 27.6379H33.2487C27.1675 27.332 23.9947 22.9306 23.4659 22.3437C22.9371 21.7568 21.4598 19.3865 19.7643 15.6072Z" stroke={color} stroke-width="0.2"/>
        <path d="M19.7643 15.6072C18.4423 12.6605 17.6457 9.78248 16.5 9C47.9636 8.70657 109.134 9.23383 107.104 9.23388C106.604 9.23389 103.932 13.651 103.315 15.0203C99.2958 26.0533 91.5929 28.0291 88.2439 27.6379H33.2487C27.1675 27.332 23.9947 22.9306 23.4659 22.3437C22.9371 21.7568 21.4598 19.3865 19.7643 15.6072Z" stroke={color} stroke-width="0.2"/>
        </g>
        </g>
        </svg>
        
    );
};

export default BadgeImage;
