import React from "react";
import TitleBlock from "../Preview/TitleBlock";
import SubTitleBlock from "../Preview/SubTitleBlock";
import ButtonBlock from "../Preview/ButtonBlock";
import InputBlock from "../Preview/InputBlock";
import CouponCodeBlock from "../Preview/CouponCode";
import ParagraphBlock from "../Preview/ParagraphBlock";
import { POPUP_SECTIONS } from "../../constant/constant";
import { useApp } from "../../context/AppState";
import BirthDayBlock from "../Preview/BirthDayBlock";
import ProductTitlleBlock from "../Preview/ProductTitle";
import NoCouponCode from "../WelcomeVerticalPopup/NoCouponCode";
import CouponCodeSummaryBlock from "../Preview/CouponCodeSummaryBlock";
import CoverImageBlock from "../WelcomeVerticalPopup/CoverImageBlock";
import { getBackgroundStyle } from "../../helper";

const WelcomePreviewCard = ({
  popupData,
  activeStep,
  onClick,
  handleOnEmailChange,
  email,
  emailError,
  isEmailExist,
  birthday,
  setBirthday,
  isEmptyField,
}) => {
  const { isMobile } = useApp();

  const cardContainer = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    ...(isMobile && {
      flexDirection: "column",
      overflowY: "auto",
    }),
    position: "relative",
    zIndex: 1
  };

  const cardContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "20px",
  };

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0, // Ensure background is behind the content
  };

  const renderSections = popupData?.schema[activeStep]?.sections?.map(
    (section, index) => {
      switch (section?.type) {
        case POPUP_SECTIONS.TITLE:
          return <TitleBlock section={section} key={index} />;

        case POPUP_SECTIONS.SUBTITLE:
          return <SubTitleBlock section={section} key={index} />;

        case POPUP_SECTIONS.CODE:
          if (
            !popupData?.generalSettings?.discount_code ||
            popupData?.generalSettings?.discount_code === "undefined"
          ) {
            return <NoCouponCode key="no-coupon" email={email} />;
          } else {
            return (
              <CouponCodeBlock
                section={section}
                generalSettings={popupData?.generalSettings}
                key={index}
              />
            );
          }

        case POPUP_SECTIONS.CODE_SUMMARY:
          if (
            popupData?.generalSettings?.discount_code &&
            popupData?.generalSettings?.discount_code !== "undefined"
          ) {
            return <CouponCodeSummaryBlock section={section} key={index} />;
          }

        case POPUP_SECTIONS.BUTTON:
          return (
            <ButtonBlock
              section={section}
              onClick={onClick}
              disabled={email === ""}
              key={index}
              emailError={emailError}
            />
          );
        case POPUP_SECTIONS.PRODUCT_TITLE:
          return <ProductTitlleBlock section={section} key={index} />;

        case POPUP_SECTIONS.BIRTHDAY:
          return (
            section?.text && (
              <BirthDayBlock
                section={section}
                key={index}
                birthday={birthday}
                setBirthday={setBirthday}
                isEmptyField={isEmptyField}
              />
            )
          );

        case POPUP_SECTIONS.INPUT:
          return (
            <InputBlock
              section={section}
              onChange={handleOnEmailChange}
              email={email}
              emailError={emailError}
              key={index}
              isEmailExist={isEmailExist}
              isEmptyField={isEmptyField}
            />
          );

        case POPUP_SECTIONS.PARAGRAPH:
          return <ParagraphBlock section={section} key={index} />;
        default:
          return null;
      }
    }
  );

  const blockType = (type) =>
    popupData?.schema[activeStep]?.sections?.find((item) => item.type === type);

  return (
    <div
      style={{
        ...popupData?.schema[activeStep]?.popupSettings?.customCSS,
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          ...getBackgroundStyle(popupData?.schema[activeStep]?.popupSettings?.customCSS),
          ...backgroundStyle,
        }}
      />
      <div style={cardContainer}>
        {POPUP_SECTIONS.COVER_IMAGE && !isMobile && (
          <CoverImageBlock section={blockType(POPUP_SECTIONS.COVER_IMAGE)} />
        )}

        <div style={cardContent}>{renderSections}</div>
      </div>
    </div>
  );
};

export default WelcomePreviewCard;
