import React, { useEffect, useState } from 'react'
import ProductDetails from '../ProductDetail';

const MostSellOutProductDetails = ({ popupValue, setMostSellOutPopupDetails, activeStep}) => {
  const hashValue = localStorage.getItem("hash");
  const productArray = popupValue?.product ? [popupValue?.product] : popupValue?.schema[0]?.sections[1]?.test_orders

  const [quantity, setQuantity] = useState(1);
  const [addingProduct, setAddingProduct] = useState(false);
  const [trendingProductdata, setTrendingProductData] = useState()

  const filteredProducts = productArray?.filter(product => {
    return product?.id === Number(hashValue);
  });

  const product = filteredProducts[0]

  useEffect(() => {
    setTrendingProductData({
      product: product,
      quantity: 1,
    });
  }, [product])

  return (
    <>
      <ProductDetails
        popupData={popupValue}
        setPopupState={setMostSellOutPopupDetails}
        activeStep={activeStep}
        quantity={quantity}
        setQuantity={setQuantity}
        addingProduct={addingProduct}
        setAddingProduct={setAddingProduct}
        productData={trendingProductdata}
        setPopupData={setTrendingProductData}
        product={product}
        productImages={product}
      />
    </>
  )
}

export default MostSellOutProductDetails