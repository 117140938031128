import React, { memo, useMemo } from "react";
import PopUpModel from "../../components/PopUpModel";
import useInput from "../../hooks/useInput";
import useActionHandler from "../../hooks/useActionHandler";
import FeedbackReviewCard from "../../components/FeedbackReviewPopup/feedbackReviewCard";

const RenderFeedbackReviewPopup = ({
  popupData,
  popupState,
  setPopupState,
}) => {
  const popupType = useMemo(() => popupData?.popUpType, [popupData]);
  const {
    email,
    setEmail,
    text,
    setText,
    emailError,
    setEmailError,
    handleOnEmailChange,
    handleOnTextChange,
  } = useInput();

  const { onSubmitFeedBack, isEmptyField, isEmailExist } = useActionHandler(
    popupData,
    setPopupState,
    { email, text },
    popupState
  );

  return (
    <>
      {popupState?.openPopup ? (
        <PopUpModel
          open={popupState.openPopup}
          activeStep={popupState?.activeStep}
          setActiveState={setPopupState}
          popupData={popupData}
          setEmail={setEmail}
          setText={setText}
          halfOutsideBottom={false}
          hasAiIcon={false}
        >
          <FeedbackReviewCard
            popupData={popupData}
            activeStep={popupState.activeStep}
            setPopupState={setPopupState}
            onClick={onSubmitFeedBack}
            handleOnEmailChange={handleOnEmailChange}
            handleOnTextChange={handleOnTextChange}
            email={email}
            text={text}
            emailError={emailError}
            setEmailError={setEmailError}
            isEmailExist={isEmailExist}
            isEmptyField={isEmptyField}
          />
        </PopUpModel>
      ) : null}
    </>
  );
};

export default memo(RenderFeedbackReviewPopup);
